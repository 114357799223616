export type LocaleObj = { [key: string]: string };

interface ILocales {
  en: LocaleObj;
  fr: LocaleObj;
}

export const translations: ILocales = {
  en: {
    ADDRESS: 'Address',
    ADDRESS_OR_POSTAL_CODE: 'Address, City or Postal Code',
    ADVICE: 'Advice',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE: 'Advice Received by Other Consultants or Professionals',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_APPLICANT: 'Applicant',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_SPOUSE: 'Spouse/Partner',
    TITLE_APPLICANT: 'Applicant',
    TITLE_SPOUSE: 'Spouse/Partner',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE:
      'In the last six(6) months, have you received professional advice regarding your financial situation other than from the Licensed Insolvency Trustee?',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE:
      'Name Any and All Providers of Professional Financial Advice:',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME: 'Surname',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME: 'Given Names',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME:
      'Name the Firm/Organization of the Individual Provider of Professional Financial Advice',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS: 'Address of the Firm/Organization',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT: 'Address Unit',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE: 'Total Amount Paid to Date',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID:
      'Total Remaining Amount to be Paid',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT: '"Add Provider"',
    AFTERNOON: 'Afternoon',
    AMOUNT: 'Amount',
    APPLICATION_FORM: 'Application Form',
    APPOINTMENT_TYPE: 'Appointment Type',
    APPOINTMENTS: 'Appointments',
    APPOINTMENT_SUCCESSFULLY_CANCELLED: 'Appointment Cancelled',
    APPOINTMENT_SUCCESSFULLY_CREATED: 'Appointment Booked',
    BACK_TO_APPOINTMENTS: 'Back to Appointments',
    BACK_TO_HOMEPAGE: 'Back to Homepage',
    BANKRUPTCY: 'Bankruptcy',
    BDO_CANADA_LIMITED_IS_AN_AFFILIATE_OF_BDO_CANADA:
      'BDO Canada Limited is an affiliate of BDO Canada LLP. BDO Canada LLP, a Canadian limited liability partnership, is a member of BDO International Limited, a UK company limited by guarantee, and forms part of the international BDO network of independent member firms. BDO is the brand name for the BDO network and for each of the BDO Member Firms.',
    BOOK_APPOINTMENT: 'Book Appointment',
    BOOK_INITIAL_CONSULTATION_TITLE: 'Book your initial consultation',
    BOOK_NOW: 'Book Now',
    BOOK_YOUR_APPOINTMENT: 'Book your appointment',
    BOOK_YOUR_FREE_NOOBLIGATION_CONSULTATION_BELOW_OR_CALL: 'Book your free, no-obligation consultation below or call',
    BUSINESS_DEBT_SOLUTIONS: 'Business Debt Solutions',
    BUT_YOU_CAN_ALWAYS_COME_BACK_LATER_TO_BOOK_ANOTHER_APPOINTMENT_WHEN_YOU_ARE_READY:
      'But you can always come back later to book another appointment when you are ready.',
    CANCEL: 'Cancel',
    CANCEL_APPOINTMENT: 'Cancel Appointment',
    CHANGE_OFFICE: 'Change Office',
    CHANGE_PASSWORD: 'Change Your Password',
    CHANGE_TIME_SLOT: 'Change Time Slot',
    CONSUMER_PROPOSAL: 'Consumer Proposal',
    CONTACT_INFORMATION: 'Contact Information',
    CONTACT_STAFF: 'Contact Staff',
    CONTINUE: 'Continue',
    DASHBOARD: 'Dashboard',
    DATE_AND_TIME: 'Date and Time',
    DATE_OF_BIRTH: 'Date of Birth',
    DEBT_HUB: 'Debt Hub',
    DETAILS: 'Details',
    DOCUMENTS: 'Documents',
    DOWNLOAD: 'Download',
    EDIT: 'Edit',
    EMAIL: 'Email',
    EMAIL_NOTIFICATIONS: 'Email Notifications',
    EMAIL_NOTIFICATIONS_TEXT: 'Allow BDO to send you email notifications',
    ESTATE_NUMBER: 'Estate Number',
    EVENING: 'Evening',
    FAQ: 'FAQ',
    FINANCIAL_WELLNESS_PROGRAM: 'Financial Wellness Program',
    FIRST_NAME: 'First Name',
    FRENCH: 'French',
    GO_BACK: 'Go Back',
    GOOGLE_RECAPTCHA_TERMS_AND_PRIVACY: 'This site is protected by reCAPTCHA and the Google {01} and {02} apply.',
    HOME: 'Home',
    HOW_CAN_WE_REACH_YOU: 'How can we reach you?',
    HOW_DID_YOU_HEAR_ABOUT_US: 'How did you hear about us?',
    HOW_WE_HELP: 'How We Help',
    INSOLVENCY_TYPE: 'Type',
    LAST_NAME: 'Last Name',
    LEGAL_AND_PRIVACY: 'Legal and Privacy',
    LICENSED_INSOLVENCY_TRUSTEES: 'Licensed Insolvency Trustees',
    LIVING_DEBTFREE_STARTS_HERE: 'Living debt-free starts here',
    LOCATION: 'Location',
    LOCATIONS: 'Locations',
    MOBILE: 'Mobile',
    MORNING: 'Morning',
    MY_DOCUMENTS: 'My Documents',
    MY_PROFILE: 'My Profile',
    NEXT: 'Next',
    NO_UPCOMING_APPOINTMENTS: 'No Upcoming Appointments',
    NOT_STARTED: 'Not Started',
    ONLINE_APP_FORM: 'Online Application Form',
    OTHER: 'Other',
    OUR_PEOPLE: 'Our People',
    OUTSTANDING: 'Outstanding',
    PHONE_NUMBER: 'Phone Number',
    PLEASE_SELECT_ANOTHER_TIME_SLOT: 'Please select another time slot.',
    PRESS_AND_MEDIA: 'Press and Media',
    PREVIOUS: 'Previous',
    PRIVACY_POLICY: 'privacy policy',
    PROFILE: 'My Profile',
    PROFILE_ADDRESS: 'Address',
    PROFILE_DATE_OF_BIRTH: 'Date of Birth',
    PROFILE_EMAIL: 'Email',
    PROFILE_FIRST_NAME: 'First Name',
    PROFILE_LAST_NAME: 'Last Name',
    PROFILE_NAME: 'Name',
    PROFILE_PHONE: 'Phone',
    PROFILE_PHONE_TYPE: 'Type',
    PUBLIC_APPOINTMENTSCHEDULING_EMAIL_MANDATORY: "'Email' field is invalid!",
    PUBLIC_APPOINTMENTSCHEDULING_FIRSTNAME_MANDATORY: "'First Name' field is mandatory!",
    PUBLIC_APPOINTMENTSCHEDULING_LASTNAME_MANDATORY: "'Last Name' field is mandatory!",
    PUBLIC_APPOINTMENTSCHEDULING_PHONENUMBER_MANDATORY: "'Phone Number' field is mandatory!",
    REGISTER: 'Register',
    REQUEST_FOLLOWUP: 'Request Follow-Up',
    RESCHEDULE: 'Reschedule',
    RESCHEDULE_YOUR_APPOINTMENT: 'Reschedule your appointment',
    SAVE: 'Save',
    SEARCH_FOR_OFFICES: 'Search For Offices',
    SECURITY: 'Security',
    SELECT_AN_OFFICE_LOCATION: 'Select an office location',
    SELECT_ANOTHER_TIME_SLOT: 'Select Another Time Slot',
    SELECT_DATE: 'Select Date',
    SETTINGS: 'Settings',
    SIGN_IN: 'Sign In',
    SIGN_OUT: 'Sign Out',
    SMS_NOTIFICATIONS: 'SMS Notifications',
    SMS_NOTIFICATIONS_TEXT: 'Allow BDO to send you SMS notifications',
    SOLUTIONS: 'Solutions',
    START_NOW: 'Start Now',
    STARTING_BALANCE: 'Starting Balance',
    SUBMIT: 'Submit',
    TESTIMONIALS: 'Testimonials',
    THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE: 'The selected time slot is no longer available',
    TIME_HAS_RUN_OUT:
      'Time has run out and the appointment has not been booked. Other users will have a chance to book this time slot. However, you can still book this time slot later, if its available.',
    TIME_REMAINING: 'Time Remaining',
    TIMER_TOOLTIP:
      "This timer shows how long we'll hold this time slot for you while you enter your information. If time runs out, other users will have a chance to book this time slot. However, you can still book this time slot later, if its available.",
    TIMES_UP: 'Time’s up',
    TOOLS: 'Tools',
    TO_SPEAK_WITH_AN_AGENT: ' to speak with an agent.',
    TYPE: 'Type',
    UNABLE_TO_BOOK_APPOINTMENT: 'Unable to Book Appointment',
    UPCOMING: 'Upcoming',
    UPCOMING_APPOINTMENTS: 'Upcoming Appointments',
    UPLOAD: 'Upload',
    VIEW_ALL: 'View All',
    WEVE_SENT_YOU_AN_EMAIL_CONFIRMATION: "We've sent you an email confirmation.",
    WHERE_ARE_YOU_LOCATED: 'Where are you located?',
    WHO_WE_ARE: 'Who We Are',
    WE_ARE_UNABLE_TO_BOOK_THE_APPOINTMENT_AT_THIS_TIME:
      'We are unable to book the appointment at this time based on your information entered. Please call ',
    YES_ALLOW_BDO_TO_CONTACT_YOU: 'Yes, allow BDO to contact you by email or phone in accordance with our ',
    YOU_CAN_REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST_USING_THE_BUTTON_BELOW:
      'You can request a follow-up meeting with your debt relief specialist using the button below.',
    YOURE_ABOUT_TO_CANCEL_YOUR_APPOINTMENT: 'You’re about to cancel your appointment',
    YOURE_APPOINTMENT_HAS_BEEN_RESCHEDULED: 'Your appointment has been rescheduled!',
    YOURE_BOOKED_WITH_ONE_OF_OUR_DEBT_SOLUTION_ADVISORS: "You're booked with one of our debt solution advisors!",
    PHONE_OR_VIDEO_MEETINGS_ARE_AVAILABLE_FOR_YOUR_CONVENIENCE:
      'Phone or video meetings are available for your convenience - no need to visit our office.',
    THERE_ARE_NO_AVAILABLE_TIMESLOTS_FOR_THIS_OFFICE: 'There are no available timeslots for this office',
    CLICK_ON_CHANGE_OFFICE_TO_SELECT_ANOTHER_NEARBY_OFFICE:
      'Click on “Change Office” to select another nearby office. Or you can select “Phone/Video” to book a virtual appointment. If you need further assistance, please call ',
    CLOSE: 'Close',
    EMAIL_ADDRESS_IS_INVALID: 'Email Address is invalid',
    THIS_IS_A_REQUIRED_FIELD: 'This is a required field',
    NO_TIMESLOTS_AVAILABLE_FOR_THE_SELECTED_DATE: 'No timeslots available for the selected date',
    BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART1:
      'We are unable to book the appointment at this time based on your information entered. Please call ',
    BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART2:
      ' to speak with an agent. If you have already booked an appointment, login to your Debt Hub account by clicking here',
    TO_BOOK_AN_APPOINTMENT: ' to book an appointment',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Spousal Support Payment is required.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Child Support Payment is required.',

    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Pension/Annuities is required.',
    DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM: 'Do you have any debts arising from:',
    RECOGNIZANCE_OF_BAIL_BOND: 'Recognizance of Bail Bond',
    ALIMONY: 'Alimony',
    MAINTENANCE_OF_AFFILIATION_ORDER: 'Maintenance of Affiliation Order',
    MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY: 'Maintenance of Support of Separated Family',
    FRAUD: 'Fraud',
    EMBEZZLEMENT: 'Embezzlement',
    MISAPPROPRIATION: 'Misappropriation',
    DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY: 'Defalcation While Acting in a Fiduciary Capacity',
    PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD: 'Property or Services Obtained by False Means/Fraud',
    STUDENT_LOANS_OUTSTANDING: 'Student Loans Outstanding',
    DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT: 'Date You Ceased being Full-time/Part-time Student',
    ANY_COSIGNED_OR_GUARANTEED_LOANS: 'Any Co-Signed or Guaranteed Loans?',
    ADD_LOAN: 'Add Loan',
    LENDERS_ADDRESS: 'Lenders Address',
    LENDERS_ADRS_UNIT: "Lender's Address Unit",
    LENDERS_NAME: "Lender's Name",
    BORROWERS_NAME: 'Borrowers Name',
    BORROWERS_ADDRESS: 'Borrowers Address',
    BORROWERS_ADDRESS_UNIT: 'Borrowers Address Unit',
    BY: 'By',
    HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS:
      'Have you made payments in excess of the regular amount to creditors in the past 12 months?',
    HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS:
      'Have you had any assets seized or garnished by a creditor(s) in the past 12 months?',
    HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS:
      'In the past five (5) years have you sold, disposed or transferred any assets, cashed RRSPs or changed the named beneficiary on a life insurance policy?',
    INSOLVENT_AT_THE_TIME: 'Insolvent at the Time?',
    HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS:
      'Within the past five (5) years prior to the date of the initial bankruptcy event, have you made any gifts to relatives or others in excess of $1,000? ',
    HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY:
      'Have you been or are you involved in civil litigation from which you may receive monies or property?',
    HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING:
      'Have you made arrangements to continue to pay any creditors after filing?',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_INCOME_TAX_INFORMATION_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Income Tax Information is required.',
    HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS: 'Have You Owned A Business within the last 5 Years?',
    BUSINESS_NAME: 'Business Name',
    BUSINESS_NUMBER_OF_EMPLOYEES: 'Maximum number of employees in the past twelve (12) months',
    BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED: 'Maximum number of employees in the past twelve (12) months',
    BUSINESS_ADDRESS: 'Business Address',
    BUSINESS_ADDRESS_UNIT: 'Business Address Unit',
    TYPE_OF_OWNERSHIP: 'Type of Ownership',
    TYPE_OF_BUSINESS: 'Type of Business',
    ARE_YOU_A_DIRECTOR: 'Are you a Director?',
    DATE_OPERATIONS_CEASED: 'Date Operations Ceased',
    IS_THE_CORPORATION_BANKRUPT: 'Is the Corporation Bankrupt?',
    DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS: 'Does the Business have Employees or Sub-Contractors?',
    DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES: 'Does the Business Owe any Wages to Employees?',
    DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES: 'Does the Business Owe any Source Deductions on Wages?',
    ADD_BUSINESS: 'Add Business',
    OTHER_DETAILS: 'Other Details',
    NAME_OF_PARTNERS__DIRECTORS: 'Name of Partners / Directors',
    UNSAVED_CHANGED_TITLE: 'Unsaved changes',
    UNSAVED_CHANGED_MESSAGE: 'Any unsaved changes in the form will be lost. Do you want to leave anyways?',
    SAVE_AND_LEAVE: 'Save and Leave',
    LEAVE: 'Leave',
    ONE_ID_REQUIRED_FOR_APPLICANT: '1 ID required for Applicant!',
    NAME: 'Name',
    DEBTOR_PROFILE_IS_UPDATED: 'Debtor Profile is updated',
    CONFIRMATION: 'Confirmation',
    STAY: 'Stay',
    DOCUMENT_NAME: 'Document Name',
    ACTION: 'Action',
    SIZE: 'Size',
    DOCUMENTS_INVALID_TYPE_MESSAGE:
      'Invalid file format. Valid file formats are pdf, doc, docx, xls, xsls, csv, ppt, msg, jpg, jpeg, bmp, png, gif, heic, avif, tiff and webp. Please check and try again.',
    ACCOUNT_NAME: 'Account Name',
    ACCOUNT_NUMBER: 'Account Number',
    BANK_NAME: 'Bank name',
    BANK_ADDRESS: 'Bank address',
    TRANSIT_NUMBER: 'Transit number',
    BRANCH_NUMBER: 'Branch number',
    ESTATE_ADMIN: 'Estate Admin',
    TRUSTEE: 'Trustee',
    SERVICE_LOCATION: 'Service Location',
    ABOUT_US: 'About Us',
    ADD: 'Add',
    ADD_DEPENDENT: 'Add Dependent',
    ADD_EI_PERIOD: 'Add EI Period',
    ADD_EMPLOYMENT: 'Add Employment',
    ADD_INSOLVENCY: 'Add Insolvency',
    ADD_PREVIOUS_ADDRESS: 'Add Previous Address',
    ADDITIONAL_INFORMATION: 'Additional Information',
    ADDITIONAL_NOTES: 'Additional Notes',
    ADDRESS_REQUIRED: 'Address is required',
    ADDRESS_SELECTED_REQUIRED: 'Select address from dropdown',
    ALSO_KNOWN_AS: 'Also Known As',
    ANY_DEPENDANT_CHILDREN: 'Any dependant children and/or other dependants?',
    APPLICANT: 'Applicant',
    APPLICANT_OWNERSHIP_PERCENTAGE: 'Applicant Ownership Percentage',
    ASSET_OUTSIDE_CANADA: 'Is the asset located outside Canada?',
    ASSETS: 'Assets',
    ASSETS_CURRENT_MARKET_VALUE: 'Current market value',
    ASSETS_DEBTS: 'Assets & Debts',
    ASSETS_ESTIMATED_RESALE_VALUE: 'Estimated re-sale value',
    ASSOCIATE_DEGREE: 'Associate degree',
    AT_THIS_ADDRESS_SINCE: 'At This Address Since',
    BACHELOR_DEGREE: "Bachelor's (Baccalaureate) degree",
    BANKING_INFORMATION: 'Banking Information',
    BANKRUPTCY_DATE: 'Bankruptcy Date',
    BANKRUPTCY_DATE_REQUIRED: 'Bankruptcy date required',
    BANKRUPTCY_DISCHARGE_DATE: 'Bankruptcy Discharge Date',
    BANKRUPTCY_DISCHARGE_DATE_REQUIRED: 'Bankruptcy discharge date required',
    BASIC: 'Basic',
    BASIS_OF_VALUE: 'Basis of Value',
    BOOK_INITIAL_CONSULTATION_TEXT:
      'If you’re looking for the best debt relief professional to resolve your debt, have a free, no-obligation meeting with a BDO Licensed Insolvency Trustee as the first step towards financial peace of mind. We have offices and debt professionals across the country and in your community.',
    BUSINESS_DEBT: 'Business Debt',
    CHILD: 'Child',
    CITY: 'City',
    CLICK_ON_THE_ADD_BUTTON_ABOVE_TO_ADD_YOUR_ASSETS: 'Click on the Add button above to add your Assets',
    CLICK_ON_THE_ADD_BUTTON_ABOVE_TO_ADD_YOUR_DEBTS: 'Click on the Add button above to add your Debts',
    CLIENT_PORTAL: 'Client Portal',
    COMMENTS: 'Comments',
    COMMON_LAW: 'Common-Law',
    COMPARE_SOLUTIONS: 'Compare Solutions',
    COMPARE_SOLUTIONS_TEXT:
      'From repaying debt on your own to filing a consumer proposal, your monthly payment can vary widely. Find out how much you can expect to pay.',
    COMPLETE: 'Complete',
    CONSUMER_PROPOSAL_TOOLTIP:
      'If you’re no longer able to pay back all of your debts, a Licensed Insolvency Trustee (LIT) can reduce your debt load by 30-80%. Based on a 70% reduction, your monthly payment will be only $250.00',
    CONTRACT: 'Contract',
    CONTRIBUTIONS_IN_LAST_12_MONTHS: 'Contributions in last 12 months',
    COSIGNOR_INSOLVENCY: 'Insolvency of Co-signor',
    COUNTRY: 'Country',
    CREDIT_CARD: 'Credit Card',
    CREDIT_COUNSELLING: 'Credit Counselling',
    CREDIT_COUNSELLING_TOOLTIP:
      'If your credit rating isn’t strong, your bank might not offer you a debt consolidation loan. You can explore credit counselling, where interest charges are frozen over the course of the repayment period. With this option, your monthly payment over five years will be',
    CREDITOR_ADDRESS: 'Creditor Address',
    CREDITOR_NAME: 'Creditor Name',
    CREDITOR_UNIT: 'Creditor Unit',
    CURRENT_EMPLOYMENT_REQUIRED: 'Current employment must be specified',
    CURRENTLY_EMPLOYED: 'Current Employer',
    CURRENTLY_RECIEVING_EI: 'Currently Receiving EI',
    CURRENTLY_RECIEVING_REQUIRED: 'Current employment insurance must be specified',
    DATE_OF_BIRTH_REQUIRED: 'Date of Birth is required',
    DATE_OF_MARRIAGE: 'Date of Marriage',
    DATE_OF_MARRIAGE_REQUIRED: 'Date of Marriage is required',
    DEBT_CONSOLIDATION_LOAN: 'Debt Consolidation Loan',
    DEBT_CONSOLIDATION_LOAN_TOOLTIP:
      'If you have a good credit score and are able to qualify for a debt consolidation loan from your bank, you could repay your debt in 5 years with a monthly payment of',
    DEBT_TYPE: 'Type of Debt',
    DEBTORS_OWNERSHIP_INTEREST: 'Debtors ownership interest',
    DEBTS: 'Debts',
    DEPENDANT_EXISTANCE_REQUIRED: 'Existance of dependants is required',
    DEPENDANT_FIRST_NAME: 'Dependant First Name',
    DEPENDANT_LAST_NAME: 'Dependant Last Name',
    DESCRIBE_DIFFICULTY: 'Describe in your own words why you need financial difficulty',
    DESCRIBE_REASONS_REQUIRED: 'Description of financial difficulties is required',
    DESCRIPTION: 'Description',
    DIVORCED: 'Divorced',
    DOCTORATE_DEGREE: 'Doctorate degree',
    DOCUMENT: 'Document',
    DRIVERS_LICENSE_ID: 'ID - Drivers License',
    EDIT_ASSET: 'Edit Asset',
    EDIT_DEBT: 'Edit Debt',
    EMAIL_REQUIRED: 'Email address is required',
    EMPLOYER: 'Employer',
    EMPLOYER_ADDRESS: 'Employer Address',
    EMPLOYER_ADDRESS_UNIT: 'Employer Address Unit',
    EMPLOYER_INFO_REQUIRED: 'At least one previous employer is required',
    EMPLOYER_NAME: 'Employer Name',
    EMPLOYER_NAME_REQUIRED: 'Employer name is required',
    EMPLOYMENT: 'Employment',
    EMPLOYMENT_INSURANCE_REQUIRED: 'At least one EI period is required',
    EMPLOYMENT_STATUS: 'Employment Status',
    EMPLOYMENT_STATUS_REQUIRED: 'Employment status is required',
    END_DATE: 'End Date',
    END_DATE_REQUIRED: 'End date is required',
    ENGLISH: 'English',
    ESTATE_NUMBER_REQUIRED: 'Estate number required',
    ESTIMATED_TAX_ON_DISPOSITION: 'Estimated tax on disposition',
    EXEMPT: 'Exempt',
    FAMILY_MEMBER: 'Family Member',
    FILE_NAME_REQUIRED: 'File name required',
    FINANCIAL_DIFFICULTY: 'Financial Difficulty',
    FIRST_NAME_REQUIRED: 'First Name is required',
    FRIEND_AQUAINTANCE: 'Friend/Aquaintance',
    FULL_TIME: 'Full Time',
    GAMBLING: 'Gambling',
    GO_TO_TIMESLOTS: 'Go to timeslots',
    GRANT_TO_BE_REPAID: 'Grant To Be Repaid',
    HAS_BEEN_EMPLOYED_REQURIED: 'Past employment must be specified',
    HAS_FILED_REQURIED: 'Previous file status must be specified',
    HAS_RECIEVED_EI_REQUIRED: 'Past employment insurance must be specified',
    HAVE_BEEN_EMPLOYED: 'Have you been employed in the last two years?',
    HAVE_FILED_INSOLVENCY: 'Have you previously filed for insolvency in Canada or elsewhere?',
    HAVE_RECIEVED_EI: 'Have you received EI in the last two years?',
    HEALTH_CARD_ID: 'ID - Health Card',
    HIGHEST_EDUCATION_LEVEL: 'Highest Education Level Completed',
    HIGHEST_EDUCATION_REQUIRED: 'Highest Education Level Completed is required',
    HOUR: 'hour',
    HOURS: 'hour(s)',
    IF_YOU_HAVE_YOUR_DOCUMENTS_READY_CLICK_ON_THE_UPLOAD_BUTTON:
      'If you have your documents ready, click on the “Upload” button to upload a document or to take a picture. If not, skip this step and upload later.',
    IN_TOTAL_YOU_WILL_PAY: 'In total, you will pay',
    IN_TOTAL_YOU_WILL_SPEND: 'In total, you will spend',
    INCOME_EXPENSE: 'Income & Expense',
    INCOME_REDUCTION: 'Reduction in Income',
    INCOME_TAX: 'Income Tax',
    INCOMPLETE: 'Incomplete',
    INCOMPLETE_REVIEW: 'Incomplete / Review',
    INCONSISTENT_EMPLOYMENT: 'Inconsistent Employment',
    INSOLVENCY_INFO_REQUIRED: 'At leasst one insolvency is required',
    INSOLVENCY_TYPE_REQUIRED: 'Insolvency type required',
    INTERNSHIP: 'Internship',
    INVALID_ALIAS: 'Invalid Alias',
    INVALID_BANKRUPTCY_DATE: 'Invalid bankruptcy date',
    INVALID_BANKRUPTCY_DISCHARGE_DATE: 'Invalid bankruptcy discharge date',
    INVALID_DATE_OF_BIRTH: 'Invalid Date of Birth',
    INVALID_DATE_OF_MARRIAGE: 'Invalid Date of Marriage',
    INVALID_EMAIL: 'Invalid email format',
    INVALID_END_DATE: 'Invalid end date',
    INVALID_FIRST_NAME: 'Invalid First Name',
    INVALID_LAST_NAME: 'Invalid Last Name',
    INVALID_MIDDLE_NAME: 'Invalid Middle Name',
    INVALID_NUMBER_OF_PEOPLE: 'Invalid number of persons',
    INVALID_SIN_NUMBER: 'Invalid SIN Number',
    INVALID_START_DATE: 'Invalid start date',
    JOB_LOSS: 'Job Loss',
    JOINT_FILE: 'Joint File',
    KMS: 'KMs',
    LAST_NAME_REQUIRED: 'Last Name is required',
    LESS_THAN_SECONDARY: 'Less than secondary school graduation',
    LINE_OF_CREDIT_PERSONAL_LOAN: 'Line of Credit / Personal Loan',
    LOADING: 'Loading...',
    MAKE: 'Make',
    MARITAL_SEPERATION: 'Marital Seperation/Relationship Breakdown',
    MARITAL_STATUS: 'Marital Status',
    MARITAL_STATUS_REQUIRED: 'Marital Status is required',
    MARRIED: 'Married',
    MASTER_DEGREE: "Master's degree",
    MEDICAL_RELATED_ISSUE: 'Medical Related Issue',
    MIDDLE_NAME: 'Middle Name',
    MODEL: 'Model',
    MONTHLY_PAYMENT: 'Monthly Payment',
    MY_APPOINTMENTS: 'My Appointments',
    NAME_OF_INSTITUTION: 'Name Of Institution',
    INSTITUTION: 'Institution',
    NEW_ASSET: 'New Asset',
    NEW_DEBT: 'New Debt',
    NO: 'No',
    NO_DATA: 'No data',
    NO_DOCUMENTS_FOUND: 'No documents found',
    NO_OFFICES_FOUND: 'No offices found nearby. Try again with a different address or postal code.',
    NO_TIMESLOTS_AVAILABLE_FOR_THE_SELECTED_MEETING_TYPE: 'No timeslots available for the selected meeting type',
    NUMBER_OF_PEOPLE_REQUIRED: 'Number of persons in household unit is required',
    OCCUPATION: 'Occupation',
    INDUSTRY: 'Which industry do you work in?',
    INDUSTRY_REQUIRED: 'Which industry do you work in? is required',
    OCCUPATION_REQUIRED: 'Occupation is required',
    OF: 'of',
    OK2: 'Ok',
    ONLINE_APP_FORM_TEXT:
      'The information entered on this form will be important for your first consultation meeting with one of our financial recovery professionals.',
    OTHER_ID: 'ID - Other',
    OTHER_OWNERSHIP_PERCENTAGE: 'Other Ownership Percentage',
    OTHER_SPECIFY: 'Other - Specify',
    OVER_EXTENSION: 'Over Extension of Credit',
    OWNERSHIP: 'Ownership',
    OWNERSHIP_HELD_IN_JOINT_TENANCY: 'Ownership Held In Joint Tenancy',
    PAGE: 'Page',
    PAGE_NOT_FOUND: 'Page Not Found',
    PARENT: 'Parent',
    PART_TIME: 'Part Time',
    PASSPORT_ID: 'ID - Passport',
    PAYDAY_LOAN: 'Payday Loan',
    PERSONAL_DETAILS: 'Personal Details',
    PERSONAL_INFORMATION: 'Personal Information',
    PERSONS_IN_HOUSEHOLD: 'Persons in the household unit (including applicant)',
    PHONE_NUMBER_REQUIRED: 'Phone number is required',
    PHONE_TYPE_REQUIRED: 'Phone Type is required',
    PLACE_FILED: 'Place Filed',
    PLACE_FILED_REQUIRED: 'Place filed required',
    POLICY_NUMBER: 'Policy Number',
    POSTAL_CODE: 'Postal Code',
    PREVIOUS_ADDRESS: 'Previous Address',
    PREVIOUS_ADDRESS_UNIT: 'Previous Address Unit',
    PRINCIPAL_RESIDENCE: 'Principal Residence',
    PROFILE_MIDDLE_NAME: 'Middle Name',
    PROPOSAL: 'Proposal',
    PROVINCE: 'Province',
    QUESTIONNAIRE: 'Questionnaire',
    READY_FOR_REVIEW: 'Ready For Review',
    REASONS_FOR_DIFFICULTY: 'Reasons for Financial Difficulty',
    REASONS_REQUIRED: 'Reasons for financial difficulties is required',
    RELATIONSHIP: 'Relationship',
    RELATIONSHIP_REQUIRED: 'Relationship to dependant is required',
    REPAY_DEBT_ON_YOUR_OWN: 'Repay Debt On Your Own',
    REPAY_DEBT_ON_YOUR_OWN_TOOLTIP:
      "Are you able to repay your debt on your own? If you're looking to pay it off in 5 years, your monthly payment will need to be",
    REQUIRED: 'Required',
    REVIEWED: 'Reviewed',
    SAME_AS_APPLICANTS_CONTACT_INFORMATION: 'Same as Applicant’s Contact Information',
    SECONDARY_OR_EQUIVALENT: 'Secondary school diploma or equivalent',
    SECURED_TO: 'Secured To',
    SELECT_ONE_HOUR_TIMESLOT: 'Select a 1-hour time slot',
    SIBLING: 'Sibling',
    SIN: 'SIN',
    SIN_NUMBER_REQUIRED: 'SIN number is required',
    SINGLE: 'Single',
    SOLUTIONS_TEXT:
      'Based on estimated interest charges, here are four possible debt solutions with their respective monthly payments and total costs over the course of 5 years. Please note that these numbers will vary from person to person.',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    SPECIFY: 'Specify',
    SPECIFY_REASONS_REQUIRED: 'Specification of financial difficulties is required',
    SPOUSE: 'Spouse',
    SPOUSE_OWNERSHIP_PERCENTAGE: 'Spouse Ownership Percentage',
    SPOUSE_PARTNER: 'Spouse / Partner',
    START_DATE: 'Start Date',
    START_DATE_REQUIRED: 'Start date is required',
    STYLE: 'Style',
    SUPPORTING_DOCUMENTS: 'Supporting Documents',
    SUPPORTING_DOCUMENTS_NEEDED: 'Supporting documents needed:',
    SUPPORTING_DOCUMENTS_TOOLTIP:
      'Only 1 of the 4 supporting documents listed for Personal Identification is required.',
    THE_SELECTED_TIMESLOT_IS_NOW_AVAILABLE_TO_OTHER_USERS:
      'The selected timeslot is now available to other users for selection. You still can book this timeslot, if its available.',
    THE_TIME_RUN_OUT: 'The time run out',
    TOTAL_ASSETS: 'Total Assets',
    TOTAL_COST_OF_REPAYMENT: 'Total Cost of Repayment',
    TOTAL_DEBT_AMOUNT_YOU_PAY: 'But you will have to repay the total amount of your debt',
    TOTAL_DEBTS: 'Total Debts',
    TOTAL_NUMBER_OF_OWNERS: 'Total Number Of Owners',
    TOTAL_REPAYMENT_AMOUNT: 'And your total repayment amount will be',
    TOTAL_SECURED: 'Total Secured',
    TOTAL_UNSECURED: 'Total Unsecured',
    TRIM: 'Trim',
    TRUSTEE_NAME: "Trustee's Name",
    TRUSTEE_NAME_REQUIRED: 'Trustee name required',
    UNDER_WHAT_NAME: 'Under what name did you file?',
    UNIT: 'Unit',
    UPLOADED: 'Uploaded',
    VALUE: 'Value',
    VEHICLE_NEEDED_FOR_EMPLOYMENT: 'Vehicle Needed for Employment',
    VIN: 'VIN',
    VOLUNTEER: 'Volunteer',
    WELCOME: 'Welcome',
    WHAT_ARE_REASONS_FOR_DIFFICULTY:
      'Give Reasons for your financial difficulties (Select all that apply and provide details)',
    WITH: 'with',
    WORK: 'Work',
    YEAR: 'Year',
    YES: 'Yes',
    YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS: 'You must accept the terms and conditions',
    RELATIONSHIP_BREAKDOWN: 'Relationship Breakdown',
    BUSINESS_FAILURE: 'Business failure',
    PHYSICAL_OR_MENTAL_HEALTH_ISSUES_DISABILITY_UNABLE_TO_WORK:
      'Physical or mental health issues; disability (unable to work)',
    MISMANAGEMENT_OF_FINANCES: 'Mismanagement of finances',
    REDUCTION_OF_HOUSEHOLD_INCOME: 'Reduction of household income',

    LEGAL_MATTERS: 'Legal Matters',
    LOSS_OF_INCOME: 'Loss of Income',
    MEDICAL_REASONS: 'Medical Reasons',
    TAX_LIABILITIES: 'Tax Liabilities',
    FINANCIAL_SUPPORT_OF_OTHERS: 'Financial Support of Others',
    STUDENT_DEBT: 'Student Debt',
    MOVING_RELOCATION_EXPENSES: 'Moving/Relocation Expenses',
    FAILED_PROPOSAL: 'Failed Proposal',
    FINANCIAL_MISMANAGEMENT: 'Financial Mismanagement',
    LEGAL_MATTERS_PLEASE_SPECIFY: 'Legal Matters (Please Specify)',
    OTHER_PLEASE_SPECIFY: 'Other (Please Specify)',
    ADDICTION_OTHER_THAN_GAMBLING: 'Addiction other than gambling',
    DESCRIBE_IN_YOUR_OWN_WORDS_WHY_YOU_NEED_FINANCIAL_HELP: 'Describe in your own words why you need financial help',
    SELECT_AT_LEAST_ONE_REASON: 'Select at least one reason',
    PROPOSAL_DATE: 'Proposal Date',
    DEPENDANTS: 'Dependants',
    PREVIOUS_INSOLVENCIES: 'Previous Insolvencies',
    RESULT_OF_PROPOSAL: 'Result of Proposal',
    Fax: 'Fax',
    Mobile: 'Mobile',
    Home: 'Home',
    Work: 'Work',
    inperson: 'In Person',
    phone: 'Phone/Video',
    unknown: 'Unknown',
    Unknown: 'Unknown',
    InitialConsultation: 'Initial Consultation',
    FollowUpConsultation: 'Follow-Up Consultation',
    Counselling_1st: '1st Counselling',
    Counselling_2nd: '2nd Counselling',
    Counselling_3rd: '3rd Counselling',
    MeetingWithCreditors: 'Meeting With Creditors',
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    NT: 'Northwest Territories',
    NL: 'Newfoundland and Labrador',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    YT: 'Yukon',
    AK: 'Alaska',
    AZ: 'Arizona',
    SK: 'Saskatchewan',
    AL: 'Alabama',
    CA: 'California',
    AR: 'Arkansas',
    CO: 'Colorado',
    CT: 'Connecticut',
    FL: 'Florida',
    GA: 'Georgia',
    DE: 'Delaware',
    IN: 'Indiana',
    IL: 'Illinois',
    HI: 'Hawaii',
    ID: 'Idaho',
    KS: 'Kansas',
    KY: 'Kentucky',
    IA: 'Iowa',
    LA: 'Louisiana',
    MD: 'Maryland',
    ME: 'Maine',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MS: 'Mississippi',
    MN: 'Minnesota',
    MO: 'Missouri',
    NE: 'Nebraska',
    MT: 'Montana',
    NJ: 'New Jersey',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NM: 'New Mexico',
    NC: 'North Carolina',
    NY: 'New York',
    OK: 'Oklahoma',
    OR: 'Oregon',
    OH: 'Ohio',
    ND: 'North Dakota',
    PA: 'Pennsylvania',
    SC: 'South Carolina',
    RI: 'Rhode Island',
    TX: 'Texas',
    TN: 'Tennessee',
    SD: 'South Dakota',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WY: 'Wyoming',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    CANADA: 'Canada',
    UNITED_STATES: 'United States',
    AFGHANISTAN: 'Afghanistan',
    ALBANIA: 'Albania',
    ANGOLA: 'Angola',
    AMERICAN_SAMOA: 'American Samoa',
    ALGERIA: 'Algeria',
    ANDORRA: 'Andorra',
    ANGUILLA: 'Anguilla',
    ARGENTINA: 'Argentina',
    ARMENIA: 'Armenia',
    ANTARCTICA: 'Antarctica',
    ANTIGUA_BARBUDA: 'Antigua & Barbuda',
    AUSTRALIA: 'Australia',
    ARUBA: 'Aruba',
    ASCENSION_ISLAND: 'Ascension Island',
    BAHRAIN: 'Bahrain',
    AZERBAIJAN: 'Azerbaijan',
    AUSTRIA: 'Austria',
    BANGLADESH: 'Bangladesh',
    BAHAMAS: 'Bahamas',
    BENIN: 'Benin',
    BELARUS: 'Belarus',
    BELGIUM: 'Belgium',
    BARBADOS: 'Barbados',
    BERMUDA: 'Bermuda',
    BHUTAN: 'Bhutan',
    BELIZE: 'Belize',
    BOSNIA_HERZEGOVINA: 'Bosnia & Herzegovina',
    BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
    BOLIVIA: 'Bolivia',
    BRITISH_VIRGIN_ISLANDS: 'British Virgin Islands',
    BRAZIL: 'Brazil',
    BRUNEI: 'Brunei',
    BOUVET_ISLAND: 'Bouvet Island',
    BOTSWANA: 'Botswana',
    CAMEROON: 'Cameroon',
    CAPE_VERDE: 'Cape Verde',
    CANARY_ISLANDS: 'Canary Islands',
    BULGARIA: 'Bulgaria',
    BURKINA_FASO: 'Burkina Faso',
    CAMBODIA: 'Cambodia',
    BURUNDI: 'Burundi',
    CHAD: 'Chad',
    CEUTA_MELILLA: 'Ceuta & Melilla',
    CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
    CHINA: 'China',
    CARIBBEAN_NETHERLANDS: 'Caribbean Netherlands',
    CHILE: 'Chile',
    CAYMAN_ISLANDS: 'Cayman Islands',
    COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
    CHRISTMAS_ISLAND: 'Christmas Island',
    CLIPPERTON_ISLAND: 'Clipperton Island',
    COLOMBIA: 'Colombia',
    COSTA_RICA: 'Costa Rica',
    CONGO_KINSHASA: 'Congo - Kinshasa',
    COMOROS: 'Comoros',
    CONGO_BRAZZAVILLE: 'Congo - Brazzaville',
    COOK_ISLANDS: 'Cook Islands',
    CUBA: 'Cuba',
    CZECHIA: 'Czechia',
    DENMARK: 'Denmark',
    DIEGO_GARCIA: 'Diego Garcia',
    CÔTE_DIVOIRE: 'Côte d’Ivoire',
    CYPRUS: 'Cyprus',
    CROATIA: 'Croatia',
    CURAÇAO: 'Curaçao',
    DOMINICAN_REPUBLIC: 'Dominican Republic',
    DOMINICA: 'Dominica',
    ECUADOR: 'Ecuador',
    DJIBOUTI: 'Djibouti',
    EL_SALVADOR: 'El Salvador',
    EGYPT: 'Egypt',
    ESWATINI: 'Eswatini',
    EQUATORIAL_GUINEA: 'Equatorial Guinea',
    ESTONIA: 'Estonia',
    ERITREA: 'Eritrea',
    ETHIOPIA: 'Ethiopia',
    FAROE_ISLANDS: 'Faroe Islands',
    FINLAND: 'Finland',
    FALKLAND_ISLANDS_ISLAS_MALVINAS: 'Falkland Islands (Islas Malvinas)',
    FRANCE: 'France',
    FIJI: 'Fiji',
    FRENCH_POLYNESIA: 'French Polynesia',
    FRENCH_GUIANA: 'French Guiana',
    GEORGIA: 'Georgia',
    GAMBIA: 'Gambia',
    GABON: 'Gabon',
    FRENCH_SOUTHERN_TERRITORIES: 'French Southern Territories',
    GERMANY: 'Germany',
    GREECE: 'Greece',
    GRENADA: 'Grenada',
    GHANA: 'Ghana',
    GREENLAND: 'Greenland',
    GIBRALTAR: 'Gibraltar',
    GUAM: 'Guam',
    GUADELOUPE: 'Guadeloupe',
    GUATEMALA: 'Guatemala',
    GUERNSEY: 'Guernsey',
    GUYANA: 'Guyana',
    GUINEA: 'Guinea',
    GUINEA_BISSAU: 'Guinea-Bissau',
    HAITI: 'Haiti',
    HONDURAS: 'Honduras',
    HONG_KONG: 'Hong Kong',
    HEARD_MCDONALD_ISLANDS: 'Heard & McDonald Islands',
    HUNGARY: 'Hungary',
    ICELAND: 'Iceland',
    INDIA: 'India',
    IRAQ: 'Iraq',
    IRAN: 'Iran',
    INDONESIA: 'Indonesia',
    ITALY: 'Italy',
    ISRAEL: 'Israel',
    ISLE_OF_MAN: 'Isle of Man',
    IRELAND: 'Ireland',
    JERSEY: 'Jersey',
    JAPAN: 'Japan',
    JAMAICA: 'Jamaica',
    JORDAN: 'Jordan',
    KAZAKHSTAN: 'Kazakhstan',
    KIRIBATI: 'Kiribati',
    KOSOVO: 'Kosovo',
    KENYA: 'Kenya',
    KUWAIT: 'Kuwait',
    LAOS: 'Laos',
    KYRGYZSTAN: 'Kyrgyzstan',
    LEBANON: 'Lebanon',
    LATVIA: 'Latvia',
    LESOTHO: 'Lesotho',
    LIBYA: 'Libya',
    LIBERIA: 'Liberia',
    LIECHTENSTEIN: 'Liechtenstein',
    LUXEMBOURG: 'Luxembourg',
    LITHUANIA: 'Lithuania',
    MACAO: 'Macao',
    MALAWI: 'Malawi',
    MALAYSIA: 'Malaysia',
    MADAGASCAR: 'Madagascar',
    MALI: 'Mali',
    MARSHALL_ISLANDS: 'Marshall Islands',
    MALTA: 'Malta',
    MALDIVES: 'Maldives',
    MARTINIQUE: 'Martinique',
    MAURITIUS: 'Mauritius',
    MAURITANIA: 'Mauritania',
    MICRONESIA: 'Micronesia',
    MEXICO: 'Mexico',
    MAYOTTE: 'Mayotte',
    MONGOLIA: 'Mongolia',
    MOLDOVA: 'Moldova',
    MONACO: 'Monaco',
    MONTENEGRO: 'Montenegro',
    MONTSERRAT: 'Montserrat',
    MOROCCO: 'Morocco',
    MOZAMBIQUE: 'Mozambique',
    MYANMAR_BURMA: 'Myanmar (Burma)',
    NAMIBIA: 'Namibia',
    NAURU: 'Nauru',
    NEPAL: 'Nepal',
    NETHERLANDS: 'Netherlands',
    NICARAGUA: 'Nicaragua',
    NEW_ZEALAND: 'New Zealand',
    NEW_CALEDONIA: 'New Caledonia',
    NIGER: 'Niger',
    NIUE: 'Niue',
    NORFOLK_ISLAND: 'Norfolk Island',
    NIGERIA: 'Nigeria',
    NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
    NORTH_MACEDONIA: 'North Macedonia',
    NORTH_KOREA: 'North Korea',
    PAKISTAN: 'Pakistan',
    OMAN: 'Oman',
    NORWAY: 'Norway',
    PALESTINE: 'Palestine',
    PALAU: 'Palau',
    PANAMA: 'Panama',
    PARAGUAY: 'Paraguay',
    PAPUA_NEW_GUINEA: 'Papua New Guinea',
    PHILIPPINES: 'Philippines',
    PERU: 'Peru',
    POLAND: 'Poland',
    PITCAIRN_ISLANDS: 'Pitcairn Islands',
    PUERTO_RICO: 'Puerto Rico',
    PORTUGAL: 'Portugal',
    ROMANIA: 'Romania',
    QATAR: 'Qatar',
    RÉUNION: 'Réunion',
    RWANDA: 'Rwanda',
    RUSSIA: 'Russia',
    SAN_MARINO: 'San Marino',
    SAMOA: 'Samoa',
    SERBIA: 'Serbia',
    SAUDI_ARABIA: 'Saudi Arabia',
    SENEGAL: 'Senegal',
    SEYCHELLES: 'Seychelles',
    SIERRA_LEONE: 'Sierra Leone',
    SINT_MAARTEN: 'Sint Maarten',
    SINGAPORE: 'Singapore',
    SLOVAKIA: 'Slovakia',
    SLOVENIA: 'Slovenia',
    SOLOMON_ISLANDS: 'Solomon Islands',
    SOMALIA: 'Somalia',
    SOUTH_AFRICA: 'South Africa',
    SOUTH_GEORGIA_SOUTH_SANDWICH_ISLANDS: 'South Georgia & South Sandwich Islands',
    SOUTH_KOREA: 'South Korea',
    SPAIN: 'Spain',
    SOUTH_SUDAN: 'South Sudan',
    ST_HELENA: 'St. Helena',
    ST_BARTHÉLEMY: 'St. Barthélemy',
    SRI_LANKA: 'Sri Lanka',
    ST_VINCENT_GRENADINES: 'St. Vincent & Grenadines',
    ST_PIERRE_MIQUELON: 'St. Pierre & Miquelon',
    ST_LUCIA: 'St. Lucia',
    ST_KITTS_NEVIS: 'St. Kitts & Nevis',
    ST_MARTIN: 'St. Martin',
    SVALBARD_JAN_MAYEN: 'Svalbard & Jan Mayen',
    SURINAME: 'Suriname',
    SUDAN: 'Sudan',
    SWEDEN: 'Sweden',
    SYRIA: 'Syria',
    SÃO_TOMÉ_PRÍNCIPE: 'São Tomé & Príncipe',
    SWITZERLAND: 'Switzerland',
    TAIWAN: 'Taiwan',
    TANZANIA: 'Tanzania',
    THAILAND: 'Thailand',
    TAJIKISTAN: 'Tajikistan',
    TIMOR_LESTE: 'Timor-Leste',
    TOKELAU: 'Tokelau',
    TRINIDAD_TOBAGO: 'Trinidad & Tobago',
    TOGO: 'Togo',
    TONGA: 'Tonga',
    TRISTAN_DA_CUNHA: 'Tristan da Cunha',
    TUNISIA: 'Tunisia',
    TURKMENISTAN: 'Turkmenistan',
    TURKEY: 'Turkey',
    TURKS_CAICOS_ISLANDS: 'Turks & Caicos Islands',
    TUVALU: 'Tuvalu',
    US_OUTLYING_ISLANDS: 'U.S. Outlying Islands',
    US_VIRGIN_ISLANDS: 'U.S. Virgin Islands',
    UKRAINE: 'Ukraine',
    UGANDA: 'Uganda',
    UNITED_ARAB_EMIRATES: 'United Arab Emirates',
    UNITED_KINGDOM: 'United Kingdom',
    URUGUAY: 'Uruguay',
    UZBEKISTAN: 'Uzbekistan',
    VANUATU: 'Vanuatu',
    VATICAN_CITY: 'Vatican City',
    VENEZUELA: 'Venezuela',
    WALLIS_FUTUNA: 'Wallis & Futuna',
    VIETNAM: 'Vietnam',
    WESTERN_SAHARA: 'Western Sahara',
    ZAMBIA: 'Zambia',
    YEMEN: 'Yemen',
    ÅLAND_ISLANDS: 'Åland Islands',
    ZIMBABWE: 'Zimbabwe',
    DEBT_CONSULTANT_CREDIT_COUNSELLING: 'Debt Consultant / Credit Counselling',
    PROFESSIONAL: 'Professional',
    INTERNET: 'Internet',
    WORD_OF_MOUTH: 'Word of mouth',
    OUT_OF_HOME: 'Out of home',
    PREVIOUS_DEBTOR: 'Previous Debtor',
    PRINT: 'Print',
    YELLOW_PAGES: 'Yellow Pages',
    BROADCAST: 'Broadcast',
    TELEVISION_RADIO: 'Television/Radio',
    DEBT_CREDIT_CONSULTANT: 'Debt/Credit Consultant',
    BILLBOARD_FLYER_ADVERTISEMENT: 'Billboard/Flyer/Advertisement',
    ONLINE: 'Online',
    PREVIOUS_CLIENT: 'Previous Client',
    NEWS: 'News',
    PROFESSIONAL_REFERRAL: 'Professional Referral',
    MONTHLY_INCOME: 'Monthly Income',
    MONTHLY_EXPENSE: 'Monthly Expense',
    BUDGET: 'Budget',
    INCOME: 'Income',
    CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_INCOME: 'Click on the Edit button above to add your income',
    EXPENSES: 'Expenses',
    CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_EXPENSES: 'Click on the Edit button above to add your expenses',
    FIELD_IS_REQUIRED: '"{0}" is required',
    SPOUSE_FIRST_NAME: 'Spouse First Name',
    SPOUSE_LAST_NAME: 'Spouse Last Name',
    SPOUSE_DATE_OF_BIRTH: 'Spouse Date of Birth',
    SPOUSE_SIN: 'Spouse SIN',
    GO_TO_ERROR: 'Go to error',
    GO_TO_WARNING: 'Go to warning',
    ONLY_1_OF_THE_4_SUPPORTING_DOCUMENTS_LISTED_FOR_PERSONAL_IDENTIFICATION_IS_REQUIRED:
      'Only 1 of the 4 supporting documents listed for Personal Identification is required.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_BANKING_INFORMATION_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Banking Information is required.',
    OVERDRAFT_OWED: 'Overdraft Owed',
    JOINT_ACCOUNT: 'Joint Account',
    ADD_BANKING_INFORMATION: 'Add Banking Information',
    SIN_NOT_VALID: 'SIN not valid',
    PHONE_NOT_VALID: 'Phone not valid',
    FIELD_IS_RECOMMENDED: '"{0}" is recommended!',
    ADDRESS_UNIT: 'Address Unit',
    SHOULD_BE_GREATER_THAN: '"{0}" should be greater than {1}',
    YEAR_LAST_RETURN_FILED: 'Year Last Return Filed',
    REFUND_AMOUNT: 'Refund Amount',
    AMOUNT_OWING: 'Amount Owing',
    AT_LEAST_ONE_FIELD_MUST_HAVE_A_VALUE: 'At least one field must have a value',
    IS_RECOMMENDED: 'is recommended',
    THIS_FIELD_IS_RECOMMENDED: 'This field is recommended',
    EMAIL_NOT_VALID: 'Email not valid',
    DEBT_AREAS_APPLICANT: 'Debt Areas - Applicant',
    DEBT_AREAS_SPOUSEPARTNER: 'Debt Areas - Spouse/Partner',
    COSIGNS_OR_GUARANTEED_LOANS: 'Co-Signs or Guaranteed Loans',
    TRANSACTIONS_APPLICANT: 'Transactions - Applicant',
    TRANSACTIONS_SPOUSEPARTNER: 'Transactions - Spouse/Partner',
    BUSINESS: 'Business',
    CREDIT_BUREAU_CONSENT: 'Credit Bureau Consent',
    FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT: 'Fine or Penalty Imposed by Court (Including Assault)',
    DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE:
      'Do you expect to receive any sums of money, which are not related to your normal income, or any other property within the next 12 months (including inheritance)?',
    ANNUITIES_BANK_STATEMENT: 'Annuities – Bank Statement',
    ATV_INSURANCE: 'ATV – Insurance',
    ATV_LOAN_BALANCE: 'ATV - Loan Balance',
    ATV_REGISTRATION: 'ATV – Registration',
    BOAT_INSURANCE: 'Boat – Insurance',
    BOAT_LOAN_BALANCE: 'Boat - Loan Balance',
    BOAT_REGISTRATION: 'Boat – Registration',
    BONDS_STATEMENT: 'Bonds – Statement',
    BONDS_ACCOUNT_ACTIVITY: 'Bonds – Account Activity',
    CAMPER_INSURANCE: 'Camper – Insurance',
    CAMPER_LOAN_BALANCE: 'Camper - Loan Balance',
    CAMPER_REGISTRATION: 'Camper – Registration',
    CANADA_CHILD_BENEFIT_BANK_STATEMENT: 'Canada Child Benefit - Bank Statement',
    CHILD_SUPPORT_PAYMENT_AGREEMENT: 'Child Support Payment – Agreement',
    CHILD_SUPPORT_PAYMENT_PROOF_OF_PAYMENT: 'Child Support Payment – Proof of Payment',
    CONDO_INSURANCE: 'Condo - Insurance',
    CONDO_MORTGAGE_BALANCE: 'Condo - Mortgage Balance',
    CONDO_OWNERSHIP: 'Condo – Ownership',
    CONDO_PROPERTY_VALUE: 'Condo - Property Value',
    COOP_SHARES_VALUE: 'Co-Op Shares – Value',
    COOP_SHARES_STATEMENT: 'Co-Op Shares – Statement',
    COTTAGE_INSURANCE: 'Cottage - Insurance',
    COTTAGE_MORTGAGE_BALANCE: 'Cottage - Mortgage Balance',
    COTTAGE_OWNERSHIP: 'Cottage – Ownership',
    COTTAGE_PROPERTY_VALUE: 'Cottage - Property Value',
    CREDIT_UNION_SHARES_STATEMENT: 'Credit Union Shares – Statement',
    CREDIT_UNION_SHARES_VALUE: 'Credit Union Shares – Value',
    EI_BANK_STATEMENT: 'EI - Bank Statement',
    HOUSE_INSURANCE: 'House - Insurance',
    HOUSE_MORTGAGE_BALANCE: 'House - Mortgage Balance',
    HOUSE_OWNERSHIP: 'House – Ownership',
    HOUSE_PROPERTY_VALUE: 'House - Property Value',
    ID_DRIVERS_LICENSE: 'ID - Drivers License',
    ID_HEALTH_CARD: 'ID - Health Card',
    ID_OTHER: 'ID – Other',
    ID_PASSPORT: 'ID - Passport',
    INVESTMENTS_ACCOUNT_ACTIVITY: 'Investments – Account Activity',
    INVESTMENTS_STATEMENT: 'Investments – Statement',
    LAND_INSURANCE: 'Land - Insurance',
    LAND_MORTGAGE_BALANCE: 'Land - Mortgage Balance',
    LAND_OWNERSHIP: 'Land – Ownership',
    LAND_PROPERTY_VALUE: 'Land - Property Value',
    LIFE_INSURANCE_STATEMENT: 'Life Insurance – Statement',
    LIRA_ACCOUNT_ACTIVITY: 'LIRA – Account Activity',
    LIRA_STATEMENT: 'LIRA – Statement',
    MOTOR_VEHICLE_INSURANCE: 'Motor Vehicle – Insurance',
    MOTOR_VEHICLE_LOAN_BALANCE: 'Motor Vehicle – Loan Balance',
    MOTOR_VEHICLE_REGISTRATION: 'Motor Vehicle – Registration',
    MOTORCYCLE_INSURANCE: 'Motorcycle – Insurance',
    MOTORCYCLE_LOAN_BALANCE: 'Motorcycle - Loan Balance',
    MOTORCYCLE_REGISTRATION: 'Motorcycle – Registration',
    OTHER_INVESTMENT_STATEMENT: 'Other Investment – Statement',
    OTHER_INVESTMENT_VALUE: 'Other Investment – Value',
    OTHER_RECREATIONAL_EQUIPMENT_INSURANCE: 'Other Recreational Equipment – Insurance',
    OTHER_RECREATIONAL_EQUIPMENT_LOAN_BALANCE: 'Other Recreational Equipment - Loan Balance',
    OTHER_RECREATIONAL_EQUIPMENT_REGISTRATION: 'Other Recreational Equipment – Registration',
    PAY_STUB: 'Pay Stub',
    PENSION_ACCOUNT_ACTIVITY: 'Pension – Account Activity',
    PENSION_BANK_STATEMENT: 'Pension - Bank Statement',
    PENSION_STATEMENT: 'Pension – Statement',
    PREAUTHORIZATION_FORM: 'Pre-Authorization Form',
    RDSP_ACCOUNT_ACTIVITY: 'RDSP – Account Activity',
    RDSP_STATEMENT: 'RDSP – Statement',
    REAL_ESTATE_INSURANCE: 'Real Estate - Insurance',
    REAL_ESTATE_MORTGAGE_BALANCE: 'Real Estate - Mortgage Balance',
    REAL_ESTATE_OWNERSHIP: 'Real Estate – Ownership',
    REAL_ESTATE_PROPERTY_VALUE: 'Real Estate - Property Value',
    RENTAL_INCOME_BUSINESS_BANK_STATEMENT: 'Rental Income – Business Bank Statement',
    RENTAL_INCOME_INVOICES: 'Rental Income – Invoices',
    RENTAL_INCOME_T2125_YEAR_TO_DATE: 'Rental Income – T2125 (year to date)',
    RESP_ACCOUNT_ACTIVITY: 'RESP – Account Activity',
    RESP_STATEMENT: 'RESP – Statement',
    RRIF_ACCOUNT_ACTIVITY: 'RRIF – Account Activity',
    RRIF_STATEMENT: 'RRIF – Statement',
    RRSP_ACCOUNT_ACTIVITY: 'RRSP – Account Activity',
    RRSP_STATEMENT: 'RRSP – Statement',
    SELF_EMPLOYMENT_T2125: 'Self Employment – T2125',
    SELFEMPLOYMENT_INCOME_BUSINESS_BANK_STATEMENT: 'Self-Employment Income – Business Bank Statement',
    SELFEMPLOYMENT_INCOME_INVOICES: 'Self-Employment Income – Invoices',
    SELFEMPLOYMENT_INCOME_T2125_YEAR_TO_DATE: 'Self-Employment Income – T2125 (year to date)',
    SHARES_ACCOUNT_ACTIVITY: 'Shares – Account Activity',
    SHARES_STATEMENT: 'Shares – Statement',
    SNOWMOBILE_INSURANCE: 'Snowmobile – Insurance',
    SNOWMOBILE_LOAN_BALANCE: 'Snowmobile - Loan Balance',
    SNOWMOBILE_REGISTRATION: 'Snowmobile – Registration',
    SOCIAL_ASSISTANCE_BANK_STATEMENT: 'Social Assistance - Bank Statement',
    SPOUSAL_SUPPORT_PAYMENT_AGREEMENT: 'Spousal Support Payment – Agreement',
    SPOUSAL_SUPPORT_PAYMENT_PROOF_OF_PAYMENT: 'Spousal Support Payment – Proof of Payment',
    TAX_ASSESSMENT: 'Tax Assessment',
    TAX_RETURN: 'Tax Return',
    TFSA_ACCOUNT_ACTIVITY: 'TFSA - Account Activity',
    TFSA_STATEMENT: 'TFSA - Statement',
    TRAILER_INSURANCE: 'Trailer – Insurance',
    TRAILER_LOAN_BALANCE: 'Trailer - Loan Balance',
    TRAILER_REGISTRATION: 'Trailer – Registration',
    VOID_CHEQUE: 'Void Cheque',
    RECEIPT: 'Receipt',
    CLAIM_FILE: 'Claim File',
    SECURED_CHECKLIST: 'Secured Checklist',
    FORM_56_NOTICE_OF_STATUS: 'Form 56 Notice of Status',
    FORM_93_NOTICE_TO_CREDITORS_OR_AUTOMATIC_REVIVAL_: 'Form 93 Notice to Creditors or Automatic Revival ',
    FORM_95_NOTICE_OF_NO_AUTOMATIC_REVIVAL: 'Form 95 Notice of no automatic revival',
    FORM_96_NOTICE_TO_CREDITORS_FOR_AUTOREVIVAL: 'Form 96 Notice to Creditors for Auto-revival',
    D_45_DAY_PROPOSAL_CHECKLIST: '45 Day - Proposal Checklist',
    COURT_REVIVAL: 'Court Revival',
    PAYMENT_SUMMARY: 'Payment Summary',
    D_170_CHECKLIST: '170 Checklist',
    D_170_REPORT: '170 Report',
    DISCHARGE_CERTIFICATE: 'Discharge Certificate',
    OPPOSITION_LETTER: 'Opposition Letter',
    FORM_80: 'Form 80',
    FORM_63: 'Form 63',
    SIGNED_AGREEMENT: 'Signed Agreement',
    CHEQUE_REQUISITION: 'Cheque Requisition',
    RECOMMENDATION_LETTER: 'Recommendation Letter',
    CONDITIONAL_ORDER: 'Conditional Order',
    CERTIFICATE_OF_FULL_PERFORMANCE: 'Certificate of Full Performance ',
    SRD_REPORT: 'SRD Report',
    FORM_58_NOTICE_OF_TAXATION_OF_ADMINISTRATORS_ACCOUNTS_AND_DISCHARGE_OF_ADMINISTRATOR:
      "Form 58 Notice of Taxation of Administrator's Accounts and Discharge of Administrator",
    DIVIDEND_CHECKLIST: 'Dividend Checklist',
    SRD_CHECKLIST: 'SRD Checklist',
    FORM_16_CERTIFICATE_OF_COMPLIANCE_AND_DEEMED_DISCHARGE_OF_TRUSTEE_OR_ADMINISTRATOR:
      'Form 16 Certificate of Compliance and Deemed Discharge of Trustee or Administrator',
    FORM_15_NOTICE_OF_DEEMED_TAXATION_OF_TRUSTEES_ACCOUNTS_AND_DEEMED_DISCHARGE_OF_TRUSTEE:
      "Form 15 Notice of Deemed Taxation of Trustee's Accounts and Deemed Discharge of Trustee",
    LETTER_TO_DEBTOR_RE_OPPOSITION: 'Letter to debtor re opposition',
    CONSENT_CB: 'Consent CB',
    BANK_STATEMENT_IF_SOURCE_OF_INCOME_OTHER_THAN_PAY_STUB: 'Bank Statement if source of income other than pay stub',
    COPY_OF_INSURANCE: 'Copy of Insurance',
    PROOF_OF_CURRENT_LOAN_BALANCE: 'Proof of current loan balance',
    COPY_OF_REGISTRATION: 'Copy of Registration',
    RECENT_STATEMENT_SHOWING_VALUE_AND_BENEFICIARY: 'Recent Statement showing value and beneficiary',
    STATEMENT_OF_ACCOUNT_ACTIVITY_FOR_PREVIOUS_12_MONTHS: 'Statement of Account Activity for previous 12 months',
    COPY_OF_SUPPORT_AGREEMENT: 'Copy of Support Agreement',
    IF_NO_WRITTEN_AGREEMENT_PROOF_OF_PAYMENT_ETRANSFER_LETTER_FROM_COPARENT_RECEIPT:
      'If no written agreement, Proof of Payment (E-transfer, letter from co-parent, receipt)',
    PROOF_OF_INSURANCE: 'Proof of insurance',
    PROOF_OF_CURRENT_MORTGAGE_BALANCE: 'Proof of current mortgage balance',
    PROOF_OF_OWNERSHIP_ONE_OF_EITHER_PURVIEWMUNICIPAL_SEARCHSAMALAND_TITLE_SEARCHCADO:
      'Proof of ownership (one of either Purview/Municipal Search/SAMA/Land Title Search/CADO)',
    PROOF_OF_CURRENT_PROPERTY_VALUE: 'Proof of current property value',
    ALL_PERTINENT_DOCUMENTS_TO_SUPPORT_VALUE: 'All pertinent documents to support value ',
    RECENT_STATEMENT_SHOWING_OWNER_BENEFICIARY_AND_CASH_SURRENDER_VALUE:
      'Recent statement showing owner, beneficiary and cash surrender value',
    CREDITOR_EMAIL_ADDRESS: 'Creditor Email Address',
    THE_SIZE_OF_ATTACHMENTS_SHOULD_NOT_EXCEED_100MB: 'The size of all attachments should not be greater than 100MB',
    PREAUTHORIZATION_FORM_REQUIRED_FOR_ACCOUNT_IN_WHICH_YOU_WILL_BE_DRAWING_MONEY_FROM:
      'Pre-Authorization Form required for account in which you will be drawing money from',
    CONSENT_TEXT:
      'I consent to my personal information being provided to the Trustee for the purposes of the Trustee making a credit report request to Transunion and the Trustee validating and/or discovering my debts/liabilities. This personal information may include: my name, date of birth, marital status, phone numbers, address, and information on my: current lines of credit or accounts, banking, bankruptcy events, legal proceedings and collection events, if applicable.',
    I_UNDERSTAND: 'I Understand',
    ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_FOR_RENTAL_INCOME_IS_REQUIRED:
      'Only 1 of the 3 supporting documents for Rental Income is required',
    SUPPORTING_DOCUMENTS_ARE_REQUIRED: 'Supporting documents are required',
    INVALID_DATE: 'Invalid date',
    FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE:
      'For Joint Files - 1 ID required for Applicant, 1 ID required for Spouse!',
    DATE_CANNOT_BE_IN_THE_FUTURE: 'Date cannot be in the future',
    DATE_CANNOT_BE_IN_THE_PAST: 'Date cannot be in the past',
    PHONE: 'Phone',
    EDIT_PROFILE: 'Edit Profile',
    FOR_SPOUSE: 'For Spouse',
    DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'You have not selected a prefix for the Document(s) that have been uploaded. Select "Stay" to stay on the page and select prefixes. Select "Leave" to leave the page and the uploaded documents will be lost',
    QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'You have not selected a prefix or a business for the Document(s) that have been uploaded. Select "Stay" to stay on the page and select prefixes/businesses. Select "Leave" to leave the page and the uploaded documents will be lost.',
    SETTINGS_ARE_UPDATED: 'Settings are updated',
    MODULE_SAVED_SUCCESSFULLY: '{0} saved successfully',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    ONE_LOWERCASE_CHARACTER: 'One lowercase character',
    ONE_UPPERCASE_CHARACTER: 'One uppercase character',
    ONE_NUMBER: 'One number',
    ONE_SPECIAL_CHARACTER: 'One special character',
    EIGHT_CHARACTERS_MINIMUM: '8 characters minimum',
    PASSWORDS_MATCH: 'Passwords match',
    CHANGE_YOUR_PASSWORD: 'Change Your Password',
    ONLINE_APPLICATION_FORM: 'Online Application Form',
    THE_INFORMATION_ENTERED_ON_THIS_FORM_WILL_BE_IMPORTANT_FOR_YOUR_FIRST_CONSULTATION_MEETING:
      'The information entered on this form will be important for your first consultation meeting with one of our debt relief specialists.',
    THE_APPLICATION_TAKES_TIME_TO_COMPLETE_AND_CAN_BE_REVISITED_ANY_TIME:
      "The application takes 10-20 minutes to complete and can be revisited any time before your meeting to adjust. If you're unsure of how to respond to any of the questions, simply leave it blank and our friendly staff will help fill it in with you.",
    N_OUT_OF_TOTAL_COMPLETE: '{0} out of {1} complete',
    NO_UPLOADS_OUTSTANDING: 'No Uploads Outstanding',
    N_UPLOADS_OUTSTANDING: '{0} Uploads Outstanding',
    UPLOAD_DOCUMENTS: 'Upload Documents',
    BACK_TO_DASHBOARD: 'Back to Dashboard',
    REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST:
      'Request a Follow-Up Meeting With Your Debt Relief Specialist',
    OUR_TEAM_WILL_CONTACT_YOU_TO_CONFIRM_THE_DATE_AND_TIME: 'Our team will contact you to confirm the date and time.',
    NEW_PASSWORD_MISSING: 'New password is missing',
    CONFIRM_PASSWORD_MISSING: 'Confirm password is missing',
    PASSWORD_MISMATCH: 'Password mismatch',
    OLD_PASSWORD_MISSING: 'Old password missing',
    INVALID_OLD_PASSWORD: 'Invalid old password',
    PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
    DOCUMENT_UPLOADED_SUCCESSFULLY: 'Document uploaded successfully',
    DOCUMENT_UPLOAD_FAILED: 'Document upload failed',
    PROGRESS_STATUS_INITIALSTEP_DESCRIPTION:
      'If you’re looking for the best debt relief professional to resolve your debt, have a free, no-obligation meeting with a BDO Licensed Insolvency Trustee as the first step towards financial peace of mind. We have offices and debt professionals across the country and in your community.',
    GATHER_DOCUMENTS: 'Gather Documents',
    SIGN_UP: 'Sign-Up',
    FILE_IN_PROGRESS: 'File In Progress',
    COMPLETE_YOUR_APPLICATION_FORM_AND_ATTENT_YOUR_INITIAL_CONSULTATION:
      'Complete your Application Form and attend your Initial Consultation',
    COMPLETE_YOUR_APPLICATION_FORM_AND_UPLOAD_ALL_SUPPORTING_DOCUMENTS:
      'Complete your Application Form and upload all supporting documents',
    COMPLETE_YOUR_SIGNUP_PROCESS: 'Complete the Sign-Up process with your Debt Relief Professional',
    YOUR_DEBT_RELIEF_SPECIALIST_WILL_CONTACT_YOU: 'Your debt relief specialist will contact you with the next steps',
    YOU_HAVE_SIGNED_UP: 'You Have Signed Up!',
    PAYMENTS_AND_BANKING: 'Payments and Banking',
    REMAINING_BALANCE: 'Remaining Balance',
    BALANCE_PAID: 'Balance Paid',
    PREAUTHORIZED_DEBIT_INFORMATION: 'Pre-Authorized Debit Information',
    REQUEST_CHANGE: 'Request Change',
    SHOW_MORE: 'Show more',
    SHOW_LESS: 'Show less',
    PREAUTHORIZED_PAYMENT: 'Pre-authorized payment',
    PAST: 'Past',
    PAYMENT_DETAILS: 'Payment Details',
    PAID: 'Paid',
    RECEIPT_AVAILABLE: 'Receipt Available',
    AMOUNT_DUE: 'Amount Due',
    REQUEST_HOLD: 'Request Hold',
    DOWNLOAD_RECEIPT: 'Download Receipt',
    HOLD_UNTIL: 'Hold Until',
    HOLD_REASON: 'Hold Reason',
    HOLD_REQUESTED_UNTIL: 'Hold requested until {0}',
    ON_HOLD_UNTIL: 'On hold until {0}',
    CANCEL_HOLD: 'Cancel Hold',
    HOLD_CANCELLED: 'Hold cancelled',
    HOLD_REQUESTED: 'Hold requested',
    SUBJECT: 'Subject',
    MESSAGE: 'Message',
    SUBJECT_IS_REQUIRED: 'Subject is required.',
    MESSAGE_IS_REQUIRED: 'Message is required.',
    SEND_MESSAGE_TO_DEBT_RELIEF_SPCETIALIST_INFO:
      'Send a message to the Debt Relief Specialist that is currently assigned to your file. We usually respond in 1-2 business days.',
    MESSAGE_SENT: 'Message Sent!',
    YOU_WILL_RECEIVE_A_REPLY_WITHIN_2_BUSINESS_DAYS:
      'You will receive a reply within 2 business days to your email address on file.',
    CLAIMS: 'Claims',
    CORRESPONDENCE: 'Correspondence',
    DISCHARGE: 'Discharge',
    LEAD: 'Lead',
    SURPLUS_IE: 'Surplus I/E',
    BANKING: 'Banking',
    TAXES: 'Taxes',
    CLIENT_UPLOAD: 'Client Upload',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    UNKNOWN: 'Unknown',
    COMMONLAW: 'Common-Law',
    WIDOWED: 'Widowed',
    SEPARATED: 'Separated',
    SOME_HIGH_SCHOOL: 'Some High School',
    HIGH_SCHOOL_GRADUATE: 'High School Graduate',
    SOME_POST_SECONDARY: 'Some Post Secondary',
    POST_SECONDARY_CERTIFICATE_OR_DIPLOMA: 'Post Secondary Certificate Or Diploma',
    UNIVERSITY_DEGREE: 'University Degree',
    CHILD_STEPCHILD_OR_FOSTER_CHILD: 'Child, Stepchild, or Foster Child',
    BROTHER: 'Brother',
    SISTER: 'Sister',
    HALF_BROTHER: 'Half Brother',
    HALF_SISTER: 'Half Sister',
    STEPBROTHER: 'Stepbrother',
    STEPSISTER: 'Stepsister',
    FATHER: 'Father',
    MOTHER: 'Mother',
    GRANDPARENT: 'Grandparent',
    UNCLE: 'Uncle',
    AUNT: 'Aunt',
    NEPHEW: 'Nephew',
    NIECE: 'Niece',
    SONINLAW: 'Son-In-Law',
    DAUGHTERINLAW: 'Daughter-In-Law',
    FATHERINLAW: 'Father-In-Law',
    MOTHERINLAW: 'Mother-In-Law',
    BROTHERINLAW: 'Brother-In-Law',
    SISTERINLAW: 'Sister-In-Law',
    JOINT: 'Joint',
    CASH_SAVINGS: 'Cash / Savings',
    FURNITURE: 'Furniture',
    PERSONAL_EFFECTS: 'Personal Effects',
    INVESTMENTS_POLICIES: 'Investments / Policies',
    REAL_ESTATE: 'Real Estate',
    MOTOR_VEHICLES: 'Motor Vehicles',
    RECREATIONAL_EQUIPMENT: 'Recreational Equipment',
    TAX_REFUND: 'Tax Refund',
    CASH: 'Cash',
    JEWELRY: 'Jewelry',
    LIFE_INSURANCE: 'Life Insurance',
    HOUSEHOLD_FURNITURE_EFFECTS: 'Household Furniture & Effects',
    SNOWMOBILE: 'Snowmobile',
    HOUSE: 'House',
    AUTOMOBILE: 'Automobile',
    TOOLS_OF_TRADE: 'Tools Of Trade',
    GIC: 'GIC',
    RRSP: 'RRSP',
    CONDO: 'Condo',
    MOTORCYCLE: 'Motorcycle',
    HUNTING_TOOLS: 'Hunting Tools',
    SAVINGS: 'Savings',
    RRIF: 'RRIF',
    LAND: 'Land',
    BOAT: 'Boat',
    FISHING_TOOLS: 'Fishing Tools',
    TFSA: 'TFSA',
    LIRA: 'LIRA',
    COTTAGE: 'Cottage',
    TRAILER: 'Trailer',
    FARMING_TOOLS: 'Farming Tools',
    RESP: 'RESP',
    CAMPER: 'Camper',
    SHARES: 'Shares',
    ATV: 'ATV',
    BONDS: 'Bonds',
    INVESTMENTS: 'Investments',
    COOP_SHARES: 'Co-Op Shares',
    CREDIT_UNION_SHARES: 'Credit Union Shares',
    RDSP: 'RDSP',
    PENSION: 'Pension',
    ACCOUNTING_INSURANCE_AND_RELATED_BUSINESS_ADMINISTRATIVE_OCCUPATIONS:
      'Accounting, Insurance and Related Business Administrative Occupations',
    ADMINISTRATIVE_AND_FINANCIAL_SUPERVISORS: 'Administrative and Financial Supervisors',
    ADMINISTRATIVE_OCCUPATIONS: 'Administrative Occupations',
    AIRCRAFT_ASSEMBLERS_AND_AIRCRAFT_ASSEMBLY_INSPECTORS: 'Aircraft Assemblers and Aircraft Assembly Inspectors',
    ASSEMBLERS_AND_INSPECTORS_IN_MANUFACTURING: 'Assemblers and Inspectors in Manufacturing',
    ASSISTING_OCCUPATIONS_IN_EDUCATION: 'Assisting Occupations in Education',
    ASSISTING_OCCUPATIONS_IN_LEGAL_AND_PUBLIC_PROTECTION: 'Assisting Occupations in Legal and Public Protection',
    ASSISTING_OCCUPATIONS_IN_SUPPORT_OF_HEALTH_SERVICES: 'Assisting Occupations in Support of Health Services',
    BUILDING_MAINTENANCE_INSTALLERS_SERVICERS_AND_REPAIRERS: 'Building Maintenance Installers, Servicers and Repairers',
    CENTRAL_CONTROL_AND_PROCESS_OPERATORS_IN_PROCESSING_AND_MANUFACTURING:
      'Central Control and Process Operators in Processing and Manufacturing',
    FINANCIAL_INSURANCE_AND_RELATED_ADMINISTRATIVE_SUPPORT_WORKERS:
      'Financial, Insurance and Related Administrative Support Workers',
    GENERAL_CONSTRUCTION_TRADES: 'General Construction Trades',
    HARVESTING_LANDSCAPING_AND_NATURAL_RESOURCES_LABOURERS: 'Harvesting, Landscaping and Natural Resources Labourers',
    HEALTH_TREATING_AND_CONSULTATION_SERVICES_PROFESSIONALS: 'Health Treating and Consultation Services Professionals',
    HELPERS_AND_LABOURERS: 'Helpers and Labourers',
    HOME_CARE_PROVIDER_OCCUPATIONS: 'Home Care Provider Occupations',
    LABOURERS_IN_PROCESSING_MANUFACTURING_AND_UTILITIES: 'Labourers in Processing, Manufacturing and Utilities',
    LEGISLATIVE_AND_SENIOR_MANAGERS: 'Legislative and Senior Managers',
    LIBRARY_CORRESPONDENCE_AND_OTHER_CLERKS: 'Library, Correspondence and Other Clerks',
    MAIL_AND_MESSAGE_DISTRIBUTION_OCCUPATIONS: 'Mail and Message Distribution Occupations',
    MANAGERS_IN_PUBLIC_ADMINISTRATION_IN_EDUCATION_AND_SOCIAL_AND_COMMUNITY_SERVICES_AND_IN_PUBLIC_PROTECTION_SERVICES:
      'Managers in Public Administration, in Education and Social and Community Services and in Public Protection Services',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_MANUFACTURING_AND_UTILITIES:
      'Middle Management Occupations in Manufacturing and Utilities',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_PRODUCTION_AND_AGRICULTURE:
      'Middle Management Occupations in Production and Agriculture',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_RETAIL_AND_WHOLESALE_TRADE_AND_CUSTOMER_SERVICES:
      'Middle Management Occupations in Retail and Wholesale Trade and Customer Services',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_TRADES_AND_TRANSPORTATION:
      'Middle Management Occupations in Trades and Transportation',
    NURSING_AND_ALLIED_HEALTH_PROFESSIONALS: 'Nursing and Allied Health Professionals',
    OCCUPATIONS_IN_ART_AND_CULTURE: 'Occupations in Art and Culture',
    OCCUPATIONS_IN_CUSTOMER_INFORMATION_AND_PROTECTIVE_SERVICES:
      'Occupations in Customer, Information and Protective Services',
    OCCUPATIONS_IN_FOOD_ACCOMMODATION_AND_TOURISM: 'Occupations in Food, Accommodation and Tourism',
    OCCUPATIONS_IN_FRONTLINE_PUBLIC_PROTECTION_SERVICES: 'Occupations in Front-Line Public Protection Services',
    OCCUPATIONS_IN_NATURAL_RESOURCES_AND_FISHERIES: 'Occupations in Natural Resources and Fisheries',
    OCCUPATIONS_IN_PERSONAL_SERVICE: 'Occupations in Personal Service',
    OCCUPATIONS_IN_SALES: 'Occupations in Sales',
    OCCUPATIONS_IN_SERVICES: 'Occupations in Services',
    OCCUPATIONS_IN_SPORTS: 'Occupations in Sports',
    OFFICE_COURT_AND_DATA_SUPPORT_OCCUPATIONS: 'Office, Court, and Data Support Occupations',
    OPERATORS_DRILLERS_AND_BLASTERS: 'Operators, Drillers and Blasters',
    OTHERS_TECHNICAL_TRADES: 'Others Technical Trades',
    PARAPROFESSIONAL_OCCUPATIONS_IN_LEGAL_SOCIAL_COMMUNITY_AND_EDUCATION_SERVICES:
      'Paraprofessional Occupations in Legal, Social, Community and Education Services',
    PRACTITIONERS_OF_NATURAL_HEALING: 'Practitioners of Natural Healing',
    PRIMARY_COMBAT_MEMBERS_OF_THE_CANADIAN_ARMED_FORCES: 'Primary Combat Members of the Canadian Armed Forces',
    PROCESSING_AND_MANUFACTURING_MACHINE_OPERATORS_AND_RELATED_PRODUCTION_WORKERS:
      'Processing and Manufacturing Machine Operators and Related Production Workers',
    PROFESSIONAL_OCCUPATIONS_IN_APPLIED_SCIENCES_EXCEPT_ENGINEERING:
      'Professional Occupations in Applied Sciences (Except Engineering)',
    PROFESSIONAL_OCCUPATIONS_IN_ART_AND_CULTURE: 'Professional Occupations in Art and Culture',
    PROFESSIONAL_OCCUPATIONS_IN_BUSINESS: 'Professional Occupations in Business',
    PROFESSIONAL_OCCUPATIONS_IN_EDUCATION_SERVICES: 'Professional Occupations in Education Services',
    PROFESSIONAL_OCCUPATIONS_IN_ENGINEERING: 'Professional Occupations in Engineering',
    PROFESSIONAL_OCCUPATIONS_IN_FINANCE: 'Professional Occupations in Finance',
    PROFESSIONAL_OCCUPATIONS_IN_GOVERNMENT_SERVICES: 'Professional Occupations in Government Services',
    PROFESSIONAL_OCCUPATIONS_IN_LAW: 'Professional Occupations in Law',
    PROFESSIONAL_OCCUPATIONS_IN_NATURAL_SCIENCES: 'Professional Occupations in Natural Sciences',
    PROFESSIONAL_OCCUPATIONS_IN_SOCIAL_AND_COMMUNITY_SERVICES:
      'Professional Occupations in Social and Community Services',
    RETAIL_SALES_AND_SERVICE_SUPERVISORS: 'Retail Sales and Service Supervisors',
    RETAIL_SALESPERSONS_AND_NONTECHNICAL_WHOLESALE_TRADE_SALES_AND_ACCOUNT_REPRESENTATIVES:
      'Retail Salespersons and Non-Technical Wholesale Trade Sales and Account Representatives',
    SALES_SUPPORT_OCCUPATIONS: 'Sales Support Occupations',
    SPECIALIZED_ADMINISTRATIVE_OCCUPATIONS: 'Specialized Administrative Occupations',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_ADMINISTRATIVE_SERVICES_FINANCIAL_AND_BUSINESS_SERVICES_AND_COMMUNICATION_EXCEPT_BROADCASTING:
      'Specialized Middle Management Occupations in Administrative Services, Financial and Business Services and Communication (Except Broadcasting)',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_ART_CULTURE_RECREATION_AND_SPORT:
      'Specialized Middle Management Occupations in Art, Culture, Recreation and Sport',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_ENGINEERING_ARCHITECTURE_SCIENCE_AND_INFORMATION_SYSTEMS:
      'Specialized Middle Management Occupations in Engineering, Architecture, Science and Information Systems',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_HEALTH_CARE:
      'Specialized Middle Management Occupations in Health Care',
    SPECIALIZED_SALES_OCCUPATIONS: 'Specialized Sales Occupations',
    SPECIALIZED_SERVICES_OCCUPATIONS: 'Specialized Services Occupations',
    STUDENT_MONITORS_CROSSING_GUARDS_AND_RELATED_OCCUPATIONS:
      'Student Monitors, Crossing Guards and Related Occupations',
    SUPERVISORS_IN_NATURAL_RESOURCES_AGRICULTURE_AND_RELATED_PRODUCTION:
      'Supervisors in Natural Resources, Agriculture and Related Production',
    SUPERVISORS_PROCESSING_MANUFACTURING_ASSEMBLY_AND_FABRICATION_OCCUPATIONS:
      'Supervisors, Processing, Manufacturing, Assembly and Fabrication Occupations',
    SUPPLY_CHAIN_LOGISTICS_TRACKING_AND_SCHEDULING_COORDINATION_OCCUPATIONS:
      'Supply Chain Logistics, Tracking and Scheduling Coordination Occupations',
    SUPPORT_OCCUPATIONS_IN_ART_AND_CULTURE: 'Support Occupations in Art and Culture',
    SUPPORT_OCCUPATIONS_IN_CLEANING_AND_RELATED_SERVICES: 'Support Occupations in Cleaning and Related Services',
    SUPPORT_OCCUPATIONS_IN_FOOD_ACCOMMODATION_AND_TOURISM: 'Support Occupations in Food, Accommodation and Tourism',
    SUPPORT_OCCUPATIONS_IN_SPORT: 'Support Occupations in Sport',
    TECHNICAL_CONSTRUCTION_TRADES: 'Technical Construction Trades',
    TECHNICAL_ELECTRICAL_TRADES: 'Technical Electrical Trades',
    TECHNICAL_EQUIPMENT_OPERATION_TRADES: 'Technical Equipment Operation Trades',
    TECHNICAL_INDUSTRIAL_TRADES: 'Technical Industrial Trades',
    TECHNICAL_MAINTENANCE_TRADES: 'Technical Maintenance Trades',
    TECHNICAL_OCCUPATIONS_IN_ART_AND_CULTURE: 'Technical Occupations in Art and Culture',
    TECHNICAL_OCCUPATIONS_IN_HEALTH_EXCEPT_PRACTITIONERS_OF_NATURAL_HEALING:
      'Technical Occupations in Health (Except Practitioners of Natural Healing)',
    TECHNICAL_OCCUPATIONS_RELATED_TO_APPLIED_SCIENCES_EXCEPT_ENGINEERING:
      'Technical Occupations Related to Applied Sciences (Except Engineering)',
    TECHNICAL_OCCUPATIONS_RELATED_TO_ENGINEERING: 'Technical Occupations Related to Engineering',
    TECHNICAL_OCCUPATIONS_RELATED_TO_NATURAL_SCIENCES: 'Technical Occupations Related to Natural Sciences',
    TECHNICAL_TRADES_CONTRACTORS_AND_SUPERVISORS: 'Technical Trades Contractors and Supervisors',
    THERAPY_AND_ASSESSMENT_PROFESSIONALS: 'Therapy and Assessment Professionals',
    TRANSPORT_DRIVERS_OPERATORS_AND_LABOURERS: 'Transport Drivers, Operators and Labourers',
    TRANSPORT_EQUIPMENT_OPERATORS_UTILITY_MAINTENANCE_AND_RELATED_WORKERS:
      'Transport Equipment Operators, Utility Maintenance and Related Workers',
    TRANSPORTATION_AND_PRODUCTION_LOGISTICS_COORDINATORS_AND_CUSTOMS_AND_RELATED_BROKER_OCCUPATIONS:
      'Transportation and Production Logistics Coordinators and Customs and Related Broker Occupations',
    TRANSPORTATION_OCCUPATIONS: 'Transportation Occupations',
    TRANSPORTATION_OFFICERS_AND_CONTROLLERS: 'Transportation Officers and Controllers',
    UTILITIES_EQUIPMENT_OPERATORS_AND_CONTROLLERS: 'Utilities Equipment Operators and Controllers',
    WORKERS_IN_NATURAL_RESOURCES_AND_FISHERIES: 'Workers in Natural Resources and Fisheries',
    AUTO_LOAN: 'Auto Loan',
    LINE_OF_CREDIT: 'Line Of Credit',
    MORTGAGE: 'Mortgage',
    OVERDRAFT: 'Overdraft',
    PERSONAL_LOAN: 'Personal Loan',
    STUDENT_LOAN: 'Student Loan',
    CREDIT_REPORT_COLLECTION: 'Credit Report - Collection',
    CREDIT_REPORT_TRADE: 'Credit Report - Trade',
    CREDIT_REPORT_REGISTERED_ITEM: 'Credit Report - Registered Item',
    ANNULLED_BY_COURT: 'Annulled By Court',
    COURT_APPROVAL_REFUSED: 'Court Approval Refused',
    CREDITOR_ACCEPTANCE_REJECTED: 'Creditor Acceptance Rejected',
    DEEMED_ANNULLED: 'Deemed Annulled',
    FULL_PERFORMANCE: 'Full Performance',
    ONGOING: 'Ongoing',
    UNSURE: 'Unsure',
    WITHDRAWN: 'Withdrawn',
    EMPLOYMENT_INCOME: 'Employment Income',
    PENSION_ANNUITIES: 'Pension / Annuities',
    CHILD_SUPPORT: 'Child Support',
    SPOUSAL_SUPPORT: 'Spousal Support',
    EMPLOYMENT_INSURANCE: 'Employment Insurance',
    SOCIAL_ASSISTANCE: 'Social Assistance',
    SELFEMPLOYMENT_INCOME: 'Self-Employment Income',
    CANADA_CHILD_BENEFIT: 'Canada Child Benefit',
    RENTAL_INCOME: 'Rental Income',
    NONDISCRETIONARY: 'Non-Discretionary',
    HOUSING: 'Housing',
    LIVING: 'Living',
    TRANSPORTATION: 'Transportation',
    PERSONAL: 'Personal',
    INSURANCE: 'Insurance',
    PAYMENTS: 'Payments',
    MEDICAL: 'Medical',
    CHILD_SUPPORT_PAYMENTS: 'Child Support Payments',
    RENT_BOARD_MORTGAGE_PAYMENT: 'Rent / Board / Mortgage Payment',
    RENT_MORTGAGE_HYPOTHEC: 'Rent / Mortgage / Hypothec',
    TELEPHONE_CELL_PHONE: 'Telephone / Cell Phone',
    CABLE_STREAMING_SERVICES: 'Cable / Streaming Services',
    CAR_LEASE_FINANCE_PAYMENTS: 'Car Lease / Finance Payments',
    FOOD_GROCERY: 'Food / Grocery',
    SMOKING: 'Smoking',
    TOBACCO_VAPING_CANNABIS: 'Tobacco / Vaping / Cannabis',
    MEALS_RESTAURANTS: 'Meals / Restaurants',
    VEHICLE: 'Vehicle',
    VEHICLE_INSURANCE_REGISTRATION: 'Vehicle Insurance / Registration',
    PROPOSAL_PAYMENTS: 'Proposal Payments',
    PRESCRIPTIONS: 'Prescriptions',
    SPOUSAL_SUPPORT_PAYMENTS: 'Spousal Support Payments',
    PROPERTY_TAXES_CONDO_FEES: 'Property Taxes / Condo Fees',
    LAUNDRY_DRY_CLEANING: 'Laundry / Dry Cleaning',
    ALCOHOL: 'Alcohol',
    REPAIR_MAINTENANCE_GAS: 'Repair / Maintenance / Gas',
    BANKRUPTCY_PAYMENTS: 'Bankruptcy Payments',
    DENTAL: 'Dental',
    CHILD_CARE: 'Child Care',
    HEAT_GAS_OIL: 'Heat / Gas / Oil',
    DINING_LUNCHES_RESTAURANTS: 'Dining / Lunches / Restaurants',
    GROOMING_TOILETRIES: 'Grooming / Toiletries',
    PUBLIC_TRANSPORTATION: 'Public Transportation',
    TO_SECURED_CREDITORS: 'To Secured Creditor(s)',
    FURNITURE_CONTENTS: 'Furniture / Contents',
    MEDICAL_DENTAL_PRESCRIPTION: 'Medical / Dental / Prescription',
    TELEPHONE: 'Telephone',
    ENTERTAINMENT_SPORTS: 'Entertainment / Sports',
    CLOTHING: 'Clothing',
    FINES_PENALTIES_IMPOSED_BY_COURT: 'Fines / Penalties Imposed By Court',
    CABLE_INTERNET: 'Cable / Internet',
    GIFTS_CHARITABLE_DONATIONS: 'Gifts / Charitable Donations',
    AS_A_CONDITION_OF_EMPLOYMENT: 'As a Condition of Employment',
    ELECTRICITY: 'Electricity',
    ALLOWANCES: 'Allowances',
    DEBTS_WHERE_STAY_HAS_BEEN_LIFTED: 'Debts Where Stay Has Been Lifted',
    WATER: 'Water',
    COOPERATIVE: 'Cooperative',
    CORPORATION: 'Corporation',
    PARTNERSHIP: 'Partnership',
    SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
    LIMITED_LIABILITY_COMPANY: 'Limited Liability Company',
    ACCOMMODATION_AND_FOOD_SERVICES: 'Accommodation and Food Services',
    ADMINISTRATIVE_AND_SUPPORT_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES:
      'Administrative and Support, Waste Management and Remediation Services',
    AGRICULTURE_FORESTRY_FISHING_AND_HUNTING: 'Agriculture, Forestry, Fishing and Hunting',
    ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, Entertainment and Recreation',
    CONSTRUCTION: 'Construction',
    EDUCATIONAL_SERVICES: 'Educational Services',
    FINANCE_AND_INSURANCE: 'Finance and Insurance',
    HEALTH_CARE_AND_SOCIAL_ASSISTANCE: 'Health Care and Social Assistance',
    INFORMATION_AND_CULTURAL_INDUSTRIES: 'Information and Cultural Industries',
    MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES: 'Management of Companies and Enterprises',
    MANUFACTURING: 'Manufacturing',
    MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION: 'Mining, Quarrying, and Oil and Gas Extraction',
    OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION: 'Other Services (Except Public Administration)',
    PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES: 'Professional, Scientific and Technical Services',
    PUBLIC_ADMINISTRATION: 'Public Administration',
    REAL_ESTATE_AND_RENTAL_AND_LEASING: 'Real Estate and Rental and Leasing',
    RETAIL_TRADE: 'Retail Trade',
    TRANSPORTATION_AND_WAREHOUSING: 'Transportation and Warehousing',
    UTILITIES: 'Utilities',
    WHOLESALE_TRADE: 'Wholesale Trade',
    CHANGE_PREAUTHORIZED_DEPOSIT: 'Change Pre-Authorized Deposit',
    CHANGE_REQUESTED: 'Change Requested',
    WE_HAVE_MADE_A_REQUEST_TO_UPDATE_YOUR_PREAUTHORIZED_DEBIT_INFORMATION:
      'We have made a request to update your pre-authorized debit information. A debt relief specialist will review this.',
    N_FILES: '{0} Files',
    IN_PROGRESS: 'In Progress',
    CLOSED: 'Closed',
    ANNULLED: 'Annulled',
    TRANSFERRED: 'Transferred',
    STATUS_CHANGED: 'Status Changed',
    WERE_IN_THE_PROCESS_OF_SETTING_UP_YOUR_ACCOUNT:
      "We're in the process of setting up your account, arranging payments, and working with creditors. Stay tuned for updates on your progress.",
    THANK_YOU_FOR_CHOOSING_BDO: 'Thank you for choosing BDO!',
    CONTACT_US: 'Contact Us',
    N_PAYMENTS_OUTSTANDING: '{0} Payments Outstanding',
    RECEIPTS: 'Receipts',
    YOU_HAVE_REQUESTED_A_FOLLOWUP_MEETING_YOUR_DEBT_RELIEF_PROFESSIONAL_WILL_CONTACT_YOU:
      'You have requested a Follow-Up meeting. Your Debt Relief Professional will contact you.',
    REQUEST_SENT_SUCCESSFULLY: 'Request sent successfully',
    BOOK_YOUR_INITIAL_CONSULTATION: 'Book your initial consultation',
    BOOK_A_FREE_NOOBLIGATION_CONSULTATION_WITH_A_BDO_LICENSED_INSOLVENCY_TRUSTEE:
      'Knowing all your debt relief options will help alleviate your financial stress. Book a free, no-obligation consultation with a BDO Licensed Insolvency Trustee. We have offices and debt relief specialists in your community that can help.',
    PERIOD: 'Period',
    DUE_DATE: 'Due Date',
    MISSING_INFORMATION: 'Missing Information',
    DISCREPANCY_NOTICE: 'Discrepancy Notice',
    AMENDMENT_REQUIRED: 'Amendment Required',
    ESTIMATED_SURPLUS: 'Estimated Surplus',
    ESTIMATED_DEFICIT: 'Estimated Deficit',
    INCOME_AND_EXPENSE_REPORTS: 'Income and Expense Reports',
    RECEIVED: 'Received',
    STATUS: 'Status',
    INCOME_AND_EXPENSE: 'Income and Expense',
    BACK: 'Back',
    VIEW: 'View',
    N_MONTHLY_REPORTS_PENDING: '{0} Monthly Reports Pending',
    SUBMIT_REPORT: 'Submit Report',
    COPY_PREVIOUS_MONTH: 'Copy Previous Month',
    PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT: 'Persons in the household unit (including applicant)',
    INITIAL_CONSULTATION: 'Initial Consultation',
    FOLLOWUP_CONSULTATION: 'Follow-Up Consultation',
    D_1ST_COUNSELLING: '1st Counselling',
    D_2ND_COUNSELLING: '2nd Counselling',
    D_3RD_COUNSELLING: '3rd Counselling',
    MEETING_WITH_CREDITORS: 'Meeting With Creditors',
    SECURED: 'Secured',
    UNSECURED: 'Unsecured',
    DEEMED_TRUST: 'Deemed Trust',
    DEFERRED: 'Deferred',
    CONTINGENT: 'Contingent',
    PREFERRED: 'Preferred',
    PROPERTY: 'Property',
    BANK_LOANS_EXCEPT_REAL_PROPERTY_MORTGAGE: 'Bank Loans Except Real Property Mortgage',
    CREDIT_CARDS_BANK_TRUST_COMPANIES_ISSUERS: 'Credit Cards Bank / Trust Companies Issuers',
    CREDIT_CARD_OTHER_ISSUERS: 'Credit Card Other Issuers',
    FINANCE_COMPANY_LOANS: 'Finance Company Loans',
    LOANS_FROM_INDIVIDUALS: 'Loans from Individuals',
    REAL_PROPERTY_MORTGAGE: 'Real Property Mortgage',
    STUDENT_LOANS: 'Student Loans',
    TAXES_FEDERAL_PROVINCIAL_MUNICIPAL: 'Taxes Federal / Provincial / Municipal',
    HOUSEHOLD: 'Household',
    INDIVIDUAL: 'Individual',
    ALWAYS: 'Always',
    LIMITED: 'Limited',
    GST: 'GST',
    HST: 'HST',
    GSTQST: 'GST/QST',
    ORDINARY: 'Ordinary',
    DIVISION_II: 'Division II',
    DIVISION_I: 'Division I',
    SUMMARY: 'Summary',
    IN_PERSON: 'In Person',
    PHONEVIDEO: 'Phone/Video',
    ScheduleUpdatedNotification_ClientPortal_Body:
      'There are some changes to your Income and Expense submission scheduled. Please click here to review.',
    IncomeExpenseXXDaysReminder_ClientPortal_Body:
      'Your Income and Expense report submission is due in {NumberOfDays} days  ‘{DueDate}’. Please click here to review.',
    IncomeExpenseDueDateReminder_ClientPortal_Body:
      'Your Income and Expense report submission is due today ‘{DueDate}’. Please click here to review.',
    MissedIESubmissionAlert_ClientPortal_Body:
      'You missed your Income and Expense report submission deadline which was due ‘{DueDate}’. Please click here to review.',
    TaxReturnReminderNotification_ClientPortal_Body:
      'Your submission of “Tax Returns” for {Year} is due on ’30 April’. Please click here to review.',
    TaxReturnMissedNotification_ClientPortal_Body:
      'You missed your Tax Return submission deadline which was 30th April’. Please click here to review.',
    AdminInfo_Banking_PADInfoChangeRequest_ApprovedClientPortal:
      'The request to update your PAD information has been approved.',
    AdminInfo_Banking_PADInfoChangeRequest_RejectedClientPortal:
      'The requested changes to your PAD information has been rejected for the following reason: {RejectionReason}',
    AdminInfo_Banking_PadInformation_UpdatedClientPortal: 'The changes to your PAD information was updated.',
    AdminInfo_Banking_PADScheduleHoldRequest_OnHoldClientPortal:
      'Your request for a Cheque/PAD payment hold has been approved.',
    AdminInfo_Banking_PADScheduleHoldRequest_RejectedClientPortal:
      'Your request for a Cheque/PAD payment hold has been rejected for the following reason: {RejectionReason}',
    SignupNotification_ClientPortal:
      'Your {FilingType} application and is sent for further processing. Kindly reach out to your local administrator for any questions or concerns.',
    AdminInfo_Proposal_ProposalStateAccepted_ClientPortal:
      'Congratulations! Your proposal has been accepted by the creditors. Your estate administrator will reach out to you with more information.',
    AdminInfo_Proposal_ProposalStateRejected_ClientPortal:
      'Your proposal was rejected by the creditors. Your estate administrator will reach out to you with more information.',
    AdminInfo_Proposal_ProposalStateWithdrawn_ClientPortal:
      'Your proposal has been withdrawn. Your estate administrator will reach out to you with more information.',
    AdminInfo_Proposal_ProposalStatePaymentArrear_ClientPortal:
      'You have one or more missed payment(s). Kindly ensure that these payments are done at the earliest to avoid any warning or annulment to your proposal.',
    AdminInfo_Proposal_ProposalStatePaymentWarning_ClientPortal:
      'You have more than two payments in arrear and this is your official warning. Please reach out to your local administrator for further details.',
    AdminInfo_Banking_PADSummaryUpdated_ClientPortal:
      'A summary of your payment status is available for your reference. Kindly reach out to your local administrator for any questions or concerns.',
    IncomeExpenseClientPortalComment_ClientPortal:
      'The following comment was added to your Income & Expense report for {DueDate}: {Comment}',
    DocumentWasMadeVisible_ClientPortal:
      'Document “{DocumentName}” is now available in the Documents page. Click here to go to the Documents page.',
    AppointmentCancelationNotification_ClientPortal: 'Your {AppointmentType} on {Date} has been cancelled.',
    AppointmentDetailUpdated_ClientPortal: 'Your {AppointmentType} has been updated. Click here to view the details.',
    NEW: 'New',
    NO_PREVIOUS_MONTH: 'No Previous Month Data',
    DATE_OF_FILING: 'Date of filing',
    IF_YOU_NEED_ASSISTANCE: 'if you need assistance.',
    OPERATION_FAILED: 'An error has occured! Please contact your administrator!',
    NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_PREVIOUS_PASSWORDS: 'New Password must be different from Previous Passwords!',
    THE_PASSWORD_IS_EASY_TO_GUESS: 'This password is easy to guess. Please try a different one!',
    DATE_OF_BANKRUPTCY: 'Date of Bankruptcy',
    DURATION_OF_BANKRUPTCY: 'Duration of Bankruptcy',
    ENTER_YOUR_LOCATION: 'Enter your location',
    BOOK_YOUR_APPOINTMENT_NOW: 'Book an appointment now, using your computer or mobile device. It’s quick and easy.',
    SEARCH: 'Search',
    SOURCE: 'Source',
    BACK_TO_REPORTS: 'Back to Reports',
    BACK_TO_REPORT_DETAILS: 'Back to Report Details',
    YOUR_APPOINTMENT_HAS_BEEN_BOOKED: 'Your appointment has been booked!',
    UNKNOWN_AGENT: 'Unknown agent',
    FILE_DETAILS: 'File Details',
    FILING_TYPE: 'Filing Type',
    ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED:
      'Only 1 of the 3 supporting documents listed for Self-Employment Income is required.',
    NO_NEW_NOTIFICATIONS: 'No new notifications',
    NEXT_PAYMENT: 'Next Payment',
    REQUEST_A_CHANGE_TO_THIS_COUNSELLING_SESSION: 'Request a Change to this Counselling Session',
    D_08_YEARS: '0-8 Years',
    EXPIRED_LINK: 'Expired Link',
    REASONS_FOR_FINANCIAL_DIFFICULTY: 'Reasons for Financial Difficulty',
    SUPPLEMENTAL_INFORMATION_ON_DEBTS: 'Supplemental Information on Debts',
    THIS_REGISTRATION_LINK_HAS_EXPIRED:
      'This registration link has expired. Please use the latest link in your email inbox to register. If you have any questions, please contact your debt relief specialist.',
    NEED_TECHNICAL_SUPPORT: 'Need technical support?',
    IF_YOU_NEED_TECHNICAL_SUPPORT_PLEASE_COMPLETE_THE_FORM_BELOW:
      "If you need technical support please complete the form below and a member of BDO's IT team will assist you.",
    CONTACT_SUPPORT_PAGE_PHONE_TITLE:
      'If you would like to speak with a member of our IT team, please call the support number below.',
    CONTACT_SUPPORT_PAGE_PHONE_HOURS_TITLE: 'Client Support Hours :',
    CONTACT_SUPPORT_PAGE_PHONE_HOURS: '8:30 AM to 8:00 PM ET',
    CONTACT_SUPPORT_PAGE_PHONE_DAYS: 'Monday – Friday',
    REFER_TO_THE_BDO_GLOBAL_PORTAL_CLIENT_QUICK_START_GUIDE:
      'You can also refer to the BDO Global Portal client quick start guide under Guides.',
    BDO_PROFESSIONAL_CONTACT: 'BDO professional contact',
    WHICH_BEST_DESCRIBES_YOUR_INQUIRY: 'Which best describes your inquiry?',
    MESSAGE_SENT_SUCCESSFULLY: 'Message Sent Successfully',
    YOUR_INQUIRY_HAS_BEEN_SUCCESSFULLY_SUBMITTED:
      'Your inquiry has been successfully submitted. Our support team will address it shortly. Thank you for reaching out.',
    SUBMISSION_ERROR: 'Submission Error',
    THERE_WAS_AN_ISSUE_PROCESSING_YOUR_REQUEST_PLEASE_TRY_AGAIN_LATER:
      'There was an issue processing your request. Please try again later. If the problem persists, please contact our support team at ',
    LOGIN_OR_ACCOUNT_ISSUES: 'Login or Account Issues',
    PERFORMANCE_AND_LOADING_PROBLEMS: 'Performance and Loading Problems',
    BROWSER_COMPATIBILITY: 'Browser Compatibility',
    ERROR_MESSAGES: 'Error Messages',
    FUNCTIONALITY_AND_FEATURES: 'Functionality and Features',
    PASSWORD_RESET_ASSISTANCE: 'Password Reset Assistance',
    REPORT_A_BUG_OR_GLITCH: 'Report a Bug or Glitch',
    CONTACT_SUPPORT: 'Contact Support',
    BUDGET_SURPLUS: 'Budget Surplus',
    BUDGET_DEFICIT: 'Budget Deficit',
    BLACK_SCREEN_WARNING:
      'If you encounter a black screen when attempting to take a photo, please ensure that your browser has permission to access your camera. To enable camera permission, go to your browser settings.',

    //new
    COMPANY_NAME: 'Company name',
    ONE_ID_REQUIRED_FOR_SPOUSE: '1 ID required for Spouse!',
    SAVING: 'Saving',
    SECTION_LOCKED_TITLE: 'Section Locked',
    SECTION_LOCKED_BODY:
      'This section has been marked as “Completed” by your debt relief specialist. Please contact your debt relief specialist if any information needs to be updated.',
    PREAUTHORIZED_PAYMENTS: 'Pre-Authorized Payments',
    NO_OPTIONS: 'No Options',
    TECHNICAL_SUPPORT: 'Technical Support',
    WHICH_INDUSTRY_DO_YOU_WORK_IN: 'Which industry do you work in',
    PLEASE_PROVIDE_YOUR_EXPLOYMENT_HISTORY_DURING_THE_LAST_2_YEARS:
      'Please provide your employment history during the last two (2) years.',
    EMPLOYED_FULLTIME: 'Employed – full-time',
    EMPLOYED_PART_TIME: 'Employed – part time',
    EMPLOYED_SEASONAL: 'Employed – Seasonal',
    SELFEMPLOYED: 'Self-employed',
    RETIRED: 'Retired',
    STUDENT: 'Student',
    DECEASED: 'Deceased',
    NOT_EMPLOYED: 'Not Employed',
    HEALTHCARE: 'Healthcare',
    EDUCATION: 'Education',
    INFORMATION_TECHNOLOGY: 'Information Technology',
    ENGINEERING: 'Engineering',
    FINANCE: 'Finance',
    SALES_AND_MARKETING: 'Sales and Marketing',
    LEGAL: 'Legal',
    ARTS_AND_MEDIA: 'Arts and Media',
    HOSPITALITY: 'Hospitality',
    CONSTRUCTION_AND_TRADES: 'Construction and Trades',
    RETAIL: 'Retail',
    GOVERNMENT: 'Government',
    SCIENCE_AND_RESEARCH: 'Science and Research',
    SOCIAL_SERVICES: 'Social Services',
    AGRICULTURE: 'Agriculture',
    HUMAN_RESOURCES: 'Human Resources',
    CUSTOMER_SERVICE: 'Customer Service',
    SPORTS_AND_RECREATION: 'Sports and Recreation',
    TO_THE_ESTATE: 'To the Estate',
    TO_PROVIDER_OF_FINANCIAL_ADVICE_OTHER_THAN_THE_LICENSED_INSOLVENCY_TRUSTEE:
      'To Provider of Financial Advice (other than the Licensed Insolvency Trustee)',
    EMPLOYMENT_INSURANCE_BENEFITS: 'Employment Insurance Benefits',
    CHILD_TAX_BENEFIT: 'Child Tax Benefit',
    GOVERNMENT_BENEFITS: 'Government Benefits',
    OTHER_BENEFITS: 'Other Benefits',
    HAS_FINANCIAL_ADVICE_REQUIRED_MESSAGE:
      'In the last six(6) months, have you received professional advice regarding your financial situation other than from the Licensed Insolvency Trustee?',
  },
  fr: {
    ADDRESS: 'Adresse',
    ADDRESS_OR_POSTAL_CODE: 'Adresse, Ville ou Code Postal',
    ADVICE: 'Conseils',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE: "Conseils reçus par d'autres consultants ou professionnels",
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_APPLICANT: 'Demandeur',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_SPOUSE: 'Conjoint(e)/Partenaire',
    TITLE_APPLICANT: 'Demandeur',
    TITLE_SPOUSE: 'Conjoint(e)/Partenaire',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE:
      'Au cours des six (6) derniers mois, avez-vous reçu des conseils professionnels sur votre situation financière par un fournisseur autre que le syndic autorisé en insolvabilité?',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE:
      'Identifiez tout fournisseur de conseils financiers professionnels:',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME: 'Nom de famille',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME: 'Prénom(s)',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME:
      'Identifiez l’entreprise ou l’organisation de la personne ayant fourni les conseils financiers professionnels',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS: 'Adresse de l’entreprise ou l’organisation',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT: 'Unité d’adresse',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE: 'Montant total versé à ce jour',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID:
      'Montant total restant à être versé',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT: '“Ajouter un fournisseur”',
    AFTERNOON: 'Après-midi',
    AMOUNT: 'Montant',
    APPLICATION_FORM: 'Formulaire de demande',
    APPOINTMENT_TYPE: 'Type de rendez-vous',
    APPOINTMENTS: 'Rendez-vous',
    APPOINTMENT_SUCCESSFULLY_CANCELLED: 'Rendez-vous annulé',
    APPOINTMENT_SUCCESSFULLY_CREATED: 'Rendez-vous confirmé',
    BACK_TO_APPOINTMENTS: 'Retourner à la page Rendez-vous',
    BACK_TO_HOMEPAGE: 'Retour à la page d’accueil',
    BANKRUPTCY: 'Faillite',
    BDO_CANADA_LIMITED_IS_AN_AFFILIATE_OF_BDO_CANADA:
      'BDO Canada Limitée est une filiale de BDO Canada s.r.l./S.E.N.C.R.L.. BDO Canada s.r.l./S.E.N.C.R.L., une société à responsabilité limitée constituée au Canada, est un cabinet membre de BDO International Limited, une société à responsabilité limitée par garantie du Royaume-Uni, et fait partie du réseau international de cabinets membres indépendants de BDO. BDO est la marque de commerce du réseau BDO et de chaque cabinet membre de BDO.',
    BOOK_APPOINTMENT: 'Prendre un rendez-vous',
    BOOK_INITIAL_CONSULTATION_TITLE: 'Demandez une consultation initiale',
    BOOK_NOW: 'Prendre un rendez-vous',
    BOOK_YOUR_APPOINTMENT: 'Prendre un rendez-vous',
    BOOK_YOUR_FREE_NOOBLIGATION_CONSULTATION_BELOW_OR_CALL:
      'Demandez une consultation gratuite et sans engagement ci-dessous ou composez le ',
    BUSINESS_DEBT_SOLUTIONS: 'Solutions à l’endettement commercial',
    BUT_YOU_CAN_ALWAYS_COME_BACK_LATER_TO_BOOK_ANOTHER_APPOINTMENT_WHEN_YOU_ARE_READY:
      'Vous pouvez revenir plus tard pour prendre un autre rendez-vous.',
    CANCEL: 'Annuler',
    CANCEL_APPOINTMENT: 'Annuler le rendez-vous',
    CHANGE_OFFICE: 'Modifier le bureau',
    CHANGE_PASSWORD: 'Modifier votre mot de passe',
    CHANGE_TIME_SLOT: 'Modifier la plage horaire',
    CONSUMER_PROPOSAL: 'Proposition de consommateur',
    CONTACT_INFORMATION: 'Coordonnées',
    CONTACT_STAFF: 'Communiquer avec notre personnel',
    CONTINUE: 'Continuer',
    DASHBOARD: 'Tableau de bord',
    DATE_AND_TIME: 'Date et heure',
    DATE_OF_BIRTH: 'Date de naissance',
    DEBT_HUB: 'Hub de dettes',
    DETAILS: 'Détails',
    DOCUMENTS: 'Documents',
    DOWNLOAD: 'Télécharger',
    EDIT: 'Modifier',
    EMAIL: 'Courriel',
    EMAIL_NOTIFICATIONS: 'Avis par courriel',
    EMAIL_NOTIFICATIONS_TEXT: 'Autoriser BDO à vous envoyer des avis par courriel',
    ESTATE_NUMBER: 'Numéro de dossier',
    EVENING: 'Soirée',
    FAQ: 'FAQ',
    FINANCIAL_WELLNESS_PROGRAM: 'Programme de bien-être financier',
    FIRST_NAME: 'Prénom',
    FRENCH: 'Français',
    GO_BACK: 'Précédent',
    GOOGLE_RECAPTCHA_TERMS_AND_PRIVACY: "Ce site est protégé par reCAPTCHA et les {01} et {02} de Google s'appliquent.",
    HOME: 'Habitation',
    HOW_CAN_WE_REACH_YOU: 'Comment pouvons-nous communiquer avec vous?',
    HOW_DID_YOU_HEAR_ABOUT_US: 'Comment avez-vous entendu parler de BDO?',
    HOW_WE_HELP: 'Notre contribution',
    INSOLVENCY_TYPE: 'Type',
    LAST_NAME: 'Nom',
    LEGAL_AND_PRIVACY: 'Service juridique et protection des renseignements personnels',
    LICENSED_INSOLVENCY_TRUSTEES: 'Syndics autorisés en insolvabilité',
    LIVING_DEBTFREE_STARTS_HERE: 'Vivez sans dette dès maintenant',
    LOCATION: 'Emplacement',
    LOCATIONS: 'Emplacements',
    MOBILE: 'Cellulaire',
    MORNING: 'Matin',
    MY_DOCUMENTS: 'Mes documents',
    MY_PROFILE: 'Mon profil',
    NEXT: 'Suivant',
    NO_UPCOMING_APPOINTMENTS: 'Aucun rendez-vous à venir',
    NOT_STARTED: 'Non commencé',
    ONLINE_APP_FORM: 'Formulaire de demande en ligne',
    OTHER: 'Autre',
    OUR_PEOPLE: 'Nos gens',
    OUTSTANDING: 'En cours',
    PHONE_NUMBER: 'Numéro de téléphone',
    PLEASE_SELECT_ANOTHER_TIME_SLOT: 'Veuillez sélectionner une autre plage horaire.',
    PRESS_AND_MEDIA: 'Communiqués de presse et médias',
    PREVIOUS: 'Précédent',
    PRIVACY_POLICY: 'politique de confidentialité',
    PROFILE: 'Mon profil',
    PROFILE_ADDRESS: 'Adresse',
    PROFILE_DATE_OF_BIRTH: 'Date de naissance',
    PROFILE_EMAIL: 'Courriel',
    PROFILE_FIRST_NAME: 'Prénom',
    PROFILE_LAST_NAME: 'Nom',
    PROFILE_NAME: 'Nom',
    PROFILE_PHONE: 'Téléphone',
    PROFILE_PHONE_TYPE: 'Type',
    PUBLIC_APPOINTMENTSCHEDULING_EMAIL_MANDATORY: "La case 'Courriel' est invalide!",
    PUBLIC_APPOINTMENTSCHEDULING_FIRSTNAME_MANDATORY: "La case 'Prénom' est obligatoire!",
    PUBLIC_APPOINTMENTSCHEDULING_LASTNAME_MANDATORY: "La case 'Nom de famille' est obligatoire!",
    PUBLIC_APPOINTMENTSCHEDULING_PHONENUMBER_MANDATORY: "La case 'Numéro de téléphone' est obligatoire!",
    REGISTER: 'Inscription',
    REQUEST_FOLLOWUP: 'Demander une consultation de suivi',
    RESCHEDULE: 'Reporter le rendez-vous',
    RESCHEDULE_YOUR_APPOINTMENT: 'Reporter votre rendez-vous',
    SAVE: 'Enregistrer',
    SEARCH_FOR_OFFICES: 'Rechercher un bureau',
    SECURITY: 'Sécurité',
    SELECT_AN_OFFICE_LOCATION: 'Choisir un bureau',
    SELECT_ANOTHER_TIME_SLOT: 'Sélectionner une autre plage horaire',
    SELECT_DATE: 'Sélectionner la date',
    SETTINGS: 'Paramètres',
    SIGN_IN: 'Se connecter',
    SIGN_OUT: 'Fermer la session',
    SMS_NOTIFICATIONS: 'Avis par message texte',
    SMS_NOTIFICATIONS_TEXT: 'Autoriser BDO à vous envoyer des avis par message texte',
    SOLUTIONS: 'Solutions',
    START_NOW: 'Commencer',
    STARTING_BALANCE: 'Solde initial',
    SUBMIT: 'Envoyer',
    TESTIMONIALS: 'Témoignages',
    THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE: 'La plage horaire sélectionnée n’est plus disponible.',
    TIME_HAS_RUN_OUT:
      'Le temps est écoulé et le rendez-vous n’a pas été pris. Les autres utilisateurs pourront réserver cette plage horaire. Toutefois, vous pourrez réserver cette plage horaire ultérieurement si elle est toujours disponible.',
    TIME_REMAINING: 'Temps restant',
    TIMER_TOOLTIP:
      'Cette minuterie indique la durée pour laquelle nous bloquons cette plage horaire pendant que vous entrez vos renseignements. Si le temps est écoulé, les autres utilisateurs pourront réserver cette plage horaire. Toutefois, vous pourrez réserver cette plage horaire ultérieurement si elle est toujours disponible.',
    TIMES_UP: 'Le temps est écoulé!',
    TOOLS: 'Outils',
    TO_SPEAK_WITH_AN_AGENT: ' pour parler à un agent.',
    TYPE: 'Type',
    UNABLE_TO_BOOK_APPOINTMENT: 'Échec de la prise de rendez-vous',
    UPCOMING: 'À venir',
    UPCOMING_APPOINTMENTS: 'Prochains rendez-vous',
    UPLOAD: 'Téléverser',
    VIEW_ALL: 'Afficher tout',
    WE_ARE_UNABLE_TO_BOOK_THE_APPOINTMENT_AT_THIS_TIME:
      'Selon les renseignements saisis, il nous est impossible de réserver cette plage horaire pour le moment. Veuillez composer le ',
    WEVE_SENT_YOU_AN_EMAIL_CONFIRMATION: 'Nous vous avons transmis une confirmation par courriel.',
    WHERE_ARE_YOU_LOCATED: 'Dans quelle ville vous trouvez-vous?',
    WHO_WE_ARE: 'Qui sommes-nous',
    YES_ALLOW_BDO_TO_CONTACT_YOU: 'Oui, j’autorise BDO à communiquer avec moi par courriel conformément à la ',
    YOU_CAN_REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST_USING_THE_BUTTON_BELOW:
      'Vous pouvez demander une consultation de suivi avec votre spécialiste en allégement de la dette en cliquant sur le bouton ci-dessous.',
    YOURE_ABOUT_TO_CANCEL_YOUR_APPOINTMENT: 'Vous vous apprêtez à annuler votre rendez-vous.',
    YOURE_APPOINTMENT_HAS_BEEN_RESCHEDULED: 'Votre rendez-vous a été reporté!',
    YOURE_BOOKED_WITH_ONE_OF_OUR_DEBT_SOLUTION_ADVISORS:
      "Vous avez pris rendez-vous avez l'un de nos conseillers des Solutions à l'endettement personnel.",
    PHONE_OR_VIDEO_MEETINGS_ARE_AVAILABLE_FOR_YOUR_CONVENIENCE:
      "Des rendez-vous par téléphone ou par vidéoconférence sont disponibles à votre convenance - il n'est pas nécessaire de se rendre à notre bureau.",
    THERE_ARE_NO_AVAILABLE_TIMESLOTS_FOR_THIS_OFFICE: "Il n'y a pas de plage horaire disponible pour ce bureau.",
    CLICK_ON_CHANGE_OFFICE_TO_SELECT_ANOTHER_NEARBY_OFFICE:
      'Cliquez sur "Changer de bureau" pour sélectionner un autre bureau à proximité. Vous pouvez également sélectionner "Téléphone/Vidéo" pour prendre un rendez-vous virtuel. Si vous avez besoin d\'aide, veuillez appeler le ',
    CLOSE: 'Fermer',
    NO_TIMESLOTS_AVAILABLE_FOR_THE_SELECTED_DATE: 'Aucune plage horaire n’est disponible pour la date sélectionnée',
    BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART1:
      'Nous ne sommes pas en mesure de prendre rendez-vous pour le moment en fonction des informations saisies. Veuillez appeler le ',
    BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART2:
      ' pour parler avec un agent. Si vous avez déjà pris rendez-vous, connectez-vous à votre compte Hub de dettes en cliquant ici',
    TO_BOOK_AN_APPOINTMENT: ' pour prendre un rendez-vous.',
    TELEVISION_RADIO: 'Télévision/Radio',
    DEBT_CREDIT_CONSULTANT: 'Conseiller en Crédit',
    BILLBOARD_FLYER_ADVERTISEMENT: "Panneaux d'affichage/Cartons Publicitaires",
    ONLINE: 'Internet',
    PREVIOUS_CLIENT: 'Client Précédent',
    NEWS: 'Nouvelles',
    PROFESSIONAL_REFERRAL: "Référence d'un Professionnel",
    EMAIL_ADDRESS_IS_INVALID: "L'adresse courriel est invalide",
    THIS_IS_A_REQUIRED_FIELD: 'Ce champs est obligatoire',
    DEBT_CONSULTANT_CREDIT_COUNSELLING: 'Conseiller en Crédit',
    PROFESSIONAL: 'Professional',
    INTERNET: 'Internet',
    WORD_OF_MOUTH: 'Bouche à Oreille',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Pension alimentaire pour enfants est obligatoire.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Pension alimentaire matrimoniale est obligatoire.',

    ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED:
      'L’un des trois documents justificatifs indiqués dans Revenu d’un travail indépendant est obligatoire.',
    DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM: 'Avez-vous des dettes découlant des éléments suivants :',
    RECOGNIZANCE_OF_BAIL_BOND: 'Engagement ou cautionnement',
    ALIMONY: 'Pension alimentaire',
    MAINTENANCE_OF_AFFILIATION_ORDER: 'Maintien d’une ordonnance de filiation',
    MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY: 'Maintien du soutien à une famille séparée',
    FRAUD: 'Fraude',
    EMBEZZLEMENT: 'Détournement de fonds',
    MISAPPROPRIATION: 'Détournement',
    DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY: 'Abus de confiance en agissant à titre de fiduciaire',
    PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD:
      'Biens ou services obtenus par des moyens fallacieux ou frauduleux',
    STUDENT_LOANS_OUTSTANDING: 'Prêts étudiants non remboursés',
    DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT:
      'Date à laquelle vous avez cessé d’être étudiant à temps plein ou temps partiel',
    ANY_COSIGNED_OR_GUARANTEED_LOANS: 'Existe-t-il des prêts cosignés ou garantis?',
    ADD_LOAN: 'Ajouter un prêt',
    LENDERS_ADDRESS: 'Adresse du prêteur',
    LENDERS_ADRS_UNIT: 'Numéro d’unité du prêteur',
    LENDERS_NAME: 'Nom du prêteur',
    BORROWERS_ADDRESS: 'Adresse des emprunteurs',
    BORROWERS_ADDRESS_UNIT: 'Numéro d’unité des emprunteurs',
    BORROWERS_NAME: 'Noms des emprunteurs',
    BY: 'Par',
    HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS:
      'Avez-vous effectué des paiements supérieurs au montant habituel à des créanciers au cours des douze derniers mois?',
    HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS:
      'Vos biens ont-ils fait l’objet d’une saisie ou d’une saisie-arrêt demandée par des créanciers au cours des 12 derniers mois?',
    HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS:
      'Au cours des cinq (5) dernières années, avez-vous vendu, disposé ou transféré des actifs, encaissé des REER ou modifié le bénéficiaire désigné d’une police d’assurance vie?',
    HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS:
      'Au cours des cinq (5) années précédant la date de votre première faillite, avez-vous donné à des proches ou d’autres personnes des cadeaux d’une valeur de plus de 1 000 $?',
    HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY:
      'Êtes-vous ou avez-vous été partie à une procédure civile dans le cadre de laquelle vous pourriez recevoir un montant ou un bien?',
    HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING:
      'Avez-vous pris des dispositions pour continuer à payer vos créanciers après le dépôt de votre bilan?',
    INSOLVENT_AT_THE_TIME: 'Insolvable actuellement?',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_INCOME_TAX_INFORMATION_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Renseignements sur l’impôt sur le revenu est obligatoire.',
    HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS:
      'Avez-vous possédé une entreprise au cours des cinq dernières années?',
    BUSINESS_NAME: 'Nom de l’entreprise',
    BUSINESS_NUMBER_OF_EMPLOYEES: 'Nombre maximal d’employés au cours des douze (12) derniers mois',
    BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED: 'Nombre maximal d’employés au cours des douze (12) derniers mois.',
    BUSINESS_ADDRESS: 'Adresse de l’entreprise',
    BUSINESS_ADDRESS_UNIT: 'Numéro d’unité de l’entreprise',
    TYPE_OF_BUSINESS: 'Type d’entreprise',
    TYPE_OF_OWNERSHIP: 'Type de propriété',
    ARE_YOU_A_DIRECTOR: 'Agissez-vous à titre d’administrateur?',
    DATE_OPERATIONS_CEASED: 'Date de fin des activités',
    IS_THE_CORPORATION_BANKRUPT: 'L’entreprise a-t-elle déclaré faillite?',
    DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS: 'L’entreprise a-t-elle des employés ou des sous-traitants?',
    DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES: 'L’entreprise doit-elle des retenues à la source?',
    DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES: 'L’entreprise doit-elle des salaires à ses employés?',
    ADD_BUSINESS: 'Ajouter une entreprise',
    OTHER_DETAILS: 'Autres détails',
    NAME_OF_PARTNERS__DIRECTORS: 'Noms des associés ou des administrateurs',
    UNSAVED_CHANGED_TITLE: 'Modifications non enregistrées',
    UNSAVED_CHANGED_MESSAGE:
      'Les modifications apportées au formulaire qui ne sont pas enregistrées seront perdues. Souhaitez-vous quitter quand même?',
    SAVE_AND_LEAVE: 'Enregistrer et quitter',
    LEAVE: 'Sortir',
    ONE_ID_REQUIRED_FOR_APPLICANT: 'Un identifiant pour le demandeur est obligatoire.',
    NAME: 'Nom',
    DEBTOR_PROFILE_IS_UPDATED: 'Le profil du débiteur a été mis à jour.',
    CONFIRMATION: 'Confirmation',
    STAY: 'Rester',
    DOCUMENT_NAME: 'Nom du document',
    ACTION: 'Action',
    SIZE: 'Taille',
    DOCUMENTS_INVALID_TYPE_MESSAGE:
      'Le format du fichier est invalide. Les formats valides sont les suivants : pdf, doc, docx, xls, xsls, csv, ppt, msg, jpg, jpeg, bmp, png, gif, heic, avif, tiff et webp. Veuillez vérifier et essayer de nouveau.',
    ACCOUNT_NAME: 'Nom du compte',
    ACCOUNT_NUMBER: 'Numéro de compte',
    BANK_NAME: 'Nom de la banque',
    BANK_ADDRESS: 'Adresse de la banque',
    TRANSIT_NUMBER: 'Numéro de transit',
    BRANCH_NUMBER: 'Numéro de succursale',
    ESTATE_ADMIN: 'Administration de la succession',
    TRUSTEE: 'Syndic',
    SERVICE_LOCATION: 'Point de service',
    ABOUT_US: 'Notre entreprise',
    ADD: 'Ajouter',
    ADD_DEPENDENT: 'Ajouter une personne à charge',
    ADD_EI_PERIOD: 'Ajouter une période d’assurance-emploi',
    ADD_EMPLOYMENT: 'Ajouter un emploi',
    ADD_INSOLVENCY: 'Ajouter une insolvabilité',
    ADD_PREVIOUS_ADDRESS: 'Ajouter l’adresse précédente',
    ADDITIONAL_INFORMATION: 'Renseignements supplémentaires',
    ADDITIONAL_NOTES: 'Notes supplémentaires',
    ADDRESS_REQUIRED: 'L’adresse est requise.',
    ADDRESS_SELECTED_REQUIRED: 'Sélectionner l’adresse dans la liste déroulante.',
    ALSO_KNOWN_AS: 'Alias',
    ANY_DEPENDANT_CHILDREN: 'Est-ce qu’il y a des enfants à charge et d’autres personnes à charge?',
    APPLICANT: 'Demandeur',
    APPLICANT_OWNERSHIP_PERCENTAGE: 'Pourcentage de propriété du demandeur',
    ASSET_OUTSIDE_CANADA: 'Est-ce que cet actif est situé à l’extérieur du Canada ?',
    ASSETS: 'Actifs',
    ASSETS_CURRENT_MARKET_VALUE: 'Valeur marchande actuelle',
    ASSETS_DEBTS: 'Biens et dettes',
    ASSETS_ESTIMATED_RESALE_VALUE: 'Valeur de revente estimée',
    ASSOCIATE_DEGREE: 'Diplôme associé',
    AT_THIS_ADDRESS_SINCE: 'Domicilié à cette adresse depuis',
    BACHELOR_DEGREE: 'Baccalauréat',
    BANKING_INFORMATION: 'Renseignements bancaires',
    BANKRUPTCY_DATE: 'Date de la faillite',
    BANKRUPTCY_DATE_REQUIRED: 'La date de la faillite est requise.',
    BANKRUPTCY_DISCHARGE_DATE: 'Date de libération de la faillite',
    BANKRUPTCY_DISCHARGE_DATE_REQUIRED: 'La date de libération de la faillite est requise.',
    BASIC: 'Renseignements généraux',
    BASIS_OF_VALUE: 'Base de l’évaluation',
    BOOK_INITIAL_CONSULTATION_TEXT:
      'Si vous êtes à la recherche du meilleur spécialiste en allégement de  dettes pour résoudre votre endettement, profitez d’une rencontre gratuite et sans engagement avec un syndic autorisé en insolvabilité de BDO en vue de retrouver la tranquillité d’esprit en matière financière. Nous avons des bureaux et des spécialistes en allégement de  dettes partout au pays, y compris dans votre communauté.',
    BUSINESS_DEBT: 'Dette d’entreprise',
    CHILD: 'Enfant',
    CITY: 'Ville',
    CLICK_ON_THE_ADD_BUTTON_ABOVE_TO_ADD_YOUR_ASSETS: 'Cliquer sur le bouton Ajouter ci-dessus pour ajouter des biens.',
    CLICK_ON_THE_ADD_BUTTON_ABOVE_TO_ADD_YOUR_DEBTS: 'Cliquer sur le bouton Ajouter ci-dessus pour ajouter des dettes.',
    CLIENT_PORTAL: 'Portail client',
    COMMENTS: 'Commentaires',
    COMMON_LAW: 'Conjoint de fait',
    COMPARE_SOLUTIONS: 'Comparer les solutions',
    COMPARE_SOLUTIONS_TEXT:
      'Vos versements mensuels sont appelés à varier considérablement, selon que vous remboursez vos dettes par vous-même ou que vous déposez une proposition de consommateur. Découvrez le montant que vous pourriez devoir payer.',
    COMPLETE: 'Terminer',
    CONSUMER_PROPOSAL_TOOLTIP:
      'Si vous n’êtes plus à même de rembourser toutes vos dettes, un syndic autorisé en insolvabilité peut faire réduire le montant total de vos dettes de 30 à 80 %. En fonction d’une réduction de 70 % de vos dettes, vos paiements mensuels ne seront plus que de 250,00 $.',
    CONTRACT: 'Contrat',
    CONTRIBUTIONS_IN_LAST_12_MONTHS: 'Cotisations au cours des 12 derniers mois',
    COSIGNOR_INSOLVENCY: 'Insolvabilité du cosignataire',
    COUNTRY: 'Pays',
    CREDIT_CARD: 'Carte de crédit',
    CREDIT_COUNSELLING: 'Consultation en crédit',
    CREDIT_COUNSELLING_TOOLTIP:
      'Si votre cote de crédit est trop basse, votre banque pourrait refuser de vous accorder un prêt de consolidation des dettes. Vous pouvez bénéficier de conseils en crédit et obtenir le gel des frais d’intérêt pendant la période de remboursement. Vos paiements mensuels sur cinq ans s’élèveront alors à',
    CREDITOR_ADDRESS: 'Adresse du créancier',
    CREDITOR_NAME: 'Nom du créancier',
    CREDITOR_UNIT: 'Numéro d’unité du créancier',
    CURRENT_EMPLOYMENT_REQUIRED: 'L’emploi actuel doit être indiqué.',
    CURRENTLY_EMPLOYED: 'Employeur actuel',
    CURRENTLY_RECIEVING_EI: 'Bénéficiaire actuel de l’assurance-emploi',
    CURRENTLY_RECIEVING_REQUIRED: 'Le bénéficiaire actuel de l’assurance-emploi doit indiquer son statut.',
    DATE_OF_BIRTH_REQUIRED: 'La date de naissance est requise.',
    DATE_OF_MARRIAGE: 'Date du mariage',
    DATE_OF_MARRIAGE_REQUIRED: 'La date du mariage est requise.',
    DEBT_CONSOLIDATION_LOAN: 'Prêt de consolidation des dettes',
    DEBT_CONSOLIDATION_LOAN_TOOLTIP:
      'Si vous avez une bonne cote de crédit et que vous êtes admissible à un prêt de consolidation des dettes auprès de votre banque, vous pourriez rembourser vos dettes en cinq ans en effectuant des paiements mensuels de',
    DEBT_TYPE: 'Type de dette',
    DEBTORS_OWNERSHIP_INTEREST: 'Participation des débiteurs',
    DEBTS: 'Dettes',
    DEPENDANT_EXISTANCE_REQUIRED: 'Les personnes à charge doivent être indiquées.',
    DEPENDANT_FIRST_NAME: 'Prénom de la personne à charge',
    DEPENDANT_LAST_NAME: 'Nom de famille de la personne à charge',
    DESCRIBE_DIFFICULTY:
      'Expliquer, dans vos mots, pourquoi vous avez besoin d’aide pour surmonter vos difficultés financières.',
    DESCRIBE_REASONS_REQUIRED: 'Une description des difficultés financières est requise.',
    DESCRIPTION: 'Description',
    DIVORCED: 'Divorcé',
    DOCTORATE_DEGREE: 'Doctorat',
    DOCUMENT: 'Document',
    DRIVERS_LICENSE_ID: 'Pièce d’identité — Permis de conduire',
    EDIT_ASSET: 'Modifier le bien',
    EDIT_DEBT: 'Modifier la dette',
    EMAIL_REQUIRED: 'L’adresse électronique est requise.',
    EMPLOYER: 'Employeur',
    EMPLOYER_ADDRESS: 'Adresse de l’employeur',
    EMPLOYER_ADDRESS_UNIT: 'Numéro d’unité de l’employeur',
    EMPLOYER_INFO_REQUIRED: 'Au moins un ancien employeur est requis.',
    EMPLOYER_NAME: 'Nom de l’employeur',
    EMPLOYER_NAME_REQUIRED: 'Le nom de l’employeur est requis.',
    EMPLOYMENT: 'Emploi',
    EMPLOYMENT_INSURANCE_REQUIRED: 'Au moins une période d’assurance-emploi est requise.',
    EMPLOYMENT_STATUS: 'Statut d’emploi',
    EMPLOYMENT_STATUS_REQUIRED: 'Le statut d’emploi est requis.',
    END_DATE: 'Date de fin',
    END_DATE_REQUIRED: 'La date de fin est requise.',
    ENGLISH: 'English',
    ESTATE_NUMBER_REQUIRED: 'Le numéro de dossier de faillite est requis.',
    ESTIMATED_TAX_ON_DISPOSITION: "Estimation de l'impôt à la cession",
    EXEMPT: 'Exempté',
    FAMILY_MEMBER: 'Membres de la famille',
    FILE_NAME_REQUIRED: 'Le nom du dossier est requis.',
    FINANCIAL_DIFFICULTY: 'Difficultés financières',
    FIRST_NAME_REQUIRED: 'Le prénom est requis.',
    FRIEND_AQUAINTANCE: 'Ami ou connaissance',
    FULL_TIME: 'Temps plein',
    GAMBLING: 'Jeux de pari',
    GO_TO_TIMESLOTS: 'Voir les plages horaires',
    GRANT_TO_BE_REPAID: 'Subvention à rembourser',
    HAS_BEEN_EMPLOYED_REQURIED: 'Les emplois antérieurs doivent être indiqués.',
    HAS_FILED_REQURIED: 'L’état antérieur du dossier doit être indiqué.',
    HAS_RECIEVED_EI_REQUIRED: 'Les prestations d’assurance-emploi antérieures doivent être indiquées.',
    HAVE_BEEN_EMPLOYED: 'Avez-vous occupé un emploi au cours des deux dernières années?',
    HAVE_FILED_INSOLVENCY: 'Avez-vous déjà déposé un bilan pour insolvabilité au Canada ou ailleurs?',
    HAVE_RECIEVED_EI: 'Avez-vous reçu des prestations d’assurance-emploi au cours des deux dernières années?',
    HEALTH_CARD_ID: 'Pièce d’identité — Carte santé',
    HIGHEST_EDUCATION_LEVEL: 'Plus haut niveau de scolarité atteint',
    HIGHEST_EDUCATION_REQUIRED: 'Le plus haut niveau de scolarité atteint est requis.',
    HOUR: 'heure',
    HOURS: 'heures',
    IF_YOU_HAVE_YOUR_DOCUMENTS_READY_CLICK_ON_THE_UPLOAD_BUTTON:
      'Si vos documents sont prêts, cliquez sur le bouton Téléverser pour téléverser un document ou pour prendre une photo. Autrement, passez à la prochaine étape et téléversez-les plus tard.',
    IN_TOTAL_YOU_WILL_PAY: 'Vous aurez à payer un montant total de',
    IN_TOTAL_YOU_WILL_SPEND: 'Vous aurez à débourser une somme totale de',
    INCOME_EXPENSE: 'Revenus et dépenses',
    INCOME_REDUCTION: 'Réduction du revenu',
    INCOME_TAX: 'Impôt sur le revenu',
    INCOMPLETE: 'Incomplet',
    INCOMPLETE_REVIEW: 'Incomplet ou à revoir',
    INCONSISTENT_EMPLOYMENT: 'Précarité d’emploi',
    INSOLVENCY_INFO_REQUIRED: 'Au moins une insolvabilité est requise.',
    INSOLVENCY_TYPE_REQUIRED: 'Le type d’insolvabilité est requis.',
    INTERNSHIP: 'Stage',
    INVALID_ALIAS: 'L’alias est invalide.',
    INVALID_BANKRUPTCY_DATE: 'La date de faillite est invalide.',
    INVALID_BANKRUPTCY_DISCHARGE_DATE: 'La date de libération de la faillite est invalide.',
    INVALID_DATE_OF_BIRTH: 'La date de naissance est invalide.',
    INVALID_DATE_OF_MARRIAGE: 'La date du mariage est invalide.',
    INVALID_EMAIL: 'Le format de l’adresse électronique est invalide.',
    INVALID_END_DATE: 'La date de fin est invalide.',
    INVALID_FIRST_NAME: 'Le prénom est invalide.',
    INVALID_LAST_NAME: 'Le nom de famille est invalide.',
    INVALID_MIDDLE_NAME: 'Le deuxième prénom est invalide.',
    INVALID_NUMBER_OF_PEOPLE: 'Le nombre de personnes est invalide.',
    INVALID_SIN_NUMBER: 'Le NAS est invalide.',
    INVALID_START_DATE: 'La date de début est invalide.',
    JOINT_FILE: 'Dossier conjoint',
    KMS: 'km',
    LAST_NAME_REQUIRED: 'Le nom de famille est requis.',
    LESS_THAN_SECONDARY: 'Diplôme d’études secondaires non obtenu',
    LINE_OF_CREDIT_PERSONAL_LOAN: 'Marge de crédit ou prêt personnel',
    LOADING: 'Chargement en cours...',
    MAKE: 'Marque',
    MARITAL_SEPERATION: 'Séparation ou rupture conjugale',
    MARITAL_STATUS: 'État civil',
    MARITAL_STATUS_REQUIRED: 'L’état civil est requis.',
    MARRIED: 'Marié',
    MASTER_DEGREE: 'Maîtrise',
    MEDICAL_RELATED_ISSUE: 'Problèmes médicaux',
    MIDDLE_NAME: 'Second prénom',
    MISMANAGEMENT_FINANCE: 'Mauvaise gestion des finances',
    MODEL: 'Modèle',
    MONTHLY_PAYMENT: 'Paiements mensuels',
    MY_APPOINTMENTS: 'Mes rendez-vous',
    NAME_OF_INSTITUTION: 'Nom de l’institution',
    INSTITUTION: 'Institution',
    NEW_ASSET: 'Nouveau bien',
    NEW_DEBT: 'Nouvelle dette',
    NO: 'Non',
    NO_DATA: 'Aucune donnée',
    NO_DOCUMENTS_FOUND: 'Aucun document trouvé',
    NO_OFFICES_FOUND:
      'Aucun bureau à proximité Essayez de nouveau en indiquant une adresse ou un code postal différent.',
    NO_TIMESLOTS_AVAILABLE_FOR_THE_SELECTED_MEETING_TYPE:
      'Aucune plage horaire n’est disponible pour ce type de rendez-vous.',
    NUMBER_OF_PEOPLE_REQUIRED: 'Le nombre de membres de l’unité familiale est requis.',
    OCCUPATION: 'Profession',
    INDUSTRY: 'Dans quel secteur d’activité travaillez-vous?',
    INDUSTRY_REQUIRED: 'Le champ Dans quel secteur d’activité travaillez-vous? est obligatoire.',
    OCCUPATION_REQUIRED: 'La profession est requise.',
    OF: 'de',
    OK2: 'Ok',
    ONLINE_APP_FORM_TEXT:
      'Les renseignements inscrits sur ce formulaire seront nécessaires lors de votre première consultation avec l’un de nos spécialistes en allégement de  dettes.',
    OTHER_ID: 'Pièce d’identité — Autre',
    OTHER_OWNERSHIP_PERCENTAGE: 'Autre pourcentage de participation',
    OTHER_SPECIFY: 'Autre — Préciser',
    OVER_EXTENSION: 'Crédit excessif',
    OWNERSHIP: 'Propriété',
    OWNERSHIP_HELD_IN_JOINT_TENANCY: 'Bien détenu en propriété conjointe',
    PAGE: 'Page',
    PAGE_NOT_FOUND: 'Page introuvable',
    PARENT: 'Parent',
    PART_TIME: 'Temps partiel',
    PASSPORT_ID: 'Pièce d’identité — Passeport',
    PAYDAY_LOAN: 'Prêt sur salaire',
    PERSONAL_DETAILS: 'Données personnelles',
    PERSONAL_INFORMATION: 'Renseignements personnels',
    PERSONS_IN_HOUSEHOLD: 'Membres de l’unité familiale (y compris le demandeur)',
    PHONE_NUMBER_REQUIRED: 'Le numéro de téléphone est obligatoire.',
    PHONE_TYPE_REQUIRED: 'Le type de numéro de téléphone est requis.',
    PLACE_FILED: 'Lieu du dépôt',
    PLACE_FILED_REQUIRED: 'Le lieu du dépôt est requis.',
    POLICY_NUMBER: 'Numéro de police',
    POSTAL_CODE: 'Code postal',
    PREVIOUS_ADDRESS: 'Adresse précédente',
    PREVIOUS_ADDRESS_UNIT: 'Numéro d’unité de l’adresse précédente',
    PRINCIPAL_RESIDENCE: 'Résidence principale',
    PROFILE_MIDDLE_NAME: 'Second prénom',
    PROPOSAL: 'Proposition',
    PROVINCE: 'Province',
    QUESTIONNAIRE: 'Questionnaire',
    READY_FOR_REVIEW: 'Prêt pour la révision',
    REASONS_FOR_DIFFICULTY: 'Raisons des difficultés financières',
    REASONS_REQUIRED: 'Les raisons des difficultés financières sont requises.',
    RELATIONSHIP: 'Relation',
    RELATIONSHIP_REQUIRED: 'La relation avec la personne à charge est requise.',
    REPAY_DEBT_ON_YOUR_OWN: 'Remboursement de vos dettes par vous-mêmes',
    REPAY_DEBT_ON_YOUR_OWN_TOOLTIP:
      'Êtes-vous en mesure de rembourser vos dettes par vous-même? Pour les rembourser en cinq ans, vous devrez effectuer des paiements mensuels de',
    REQUIRED: 'Obligatoire',
    REVIEWED: 'Révisé',
    SAME_AS_APPLICANTS_CONTACT_INFORMATION: 'Identique aux coordonnées du demandeur',
    SECONDARY_OR_EQUIVALENT: 'Diplôme d’études secondaires ou équivalent',
    SECURED_TO: 'Dette garantie',
    SELECT_ONE_HOUR_TIMESLOT: 'Sélectionner une plage horaire d’une heure',
    SIBLING: 'Frère ou sœur',
    SIN: 'NAS',
    SIN_NUMBER_REQUIRED: 'Le NAS est obligatoire.',
    SINGLE: 'Célibataire',
    SOLUTIONS_TEXT:
      'Selon les frais d’intérêt estimés, voici quatre solutions possibles à l’endettement ainsi que les paiements mensuels et coûts totaux sur cinq ans pour chacune d’elles. Veuillez prendre note que les montants varieront d’une personne à l’autre.',
    SOMETHING_WENT_WRONG: 'Quelque chose a mal tourné',
    SPECIFY: 'Préciser',
    SPECIFY_REASONS_REQUIRED: 'Les difficultés financières doivent être précisées.',
    SPOUSE: 'Époux',
    SPOUSE_OWNERSHIP_PERCENTAGE: 'Pourcentage de propriété de l’époux',
    SPOUSE_PARTNER: 'Époux ou conjoint',
    START_DATE: 'Date de début',
    START_DATE_REQUIRED: 'La date de début est requise.',
    STYLE: 'Style',
    SUPPORTING_DOCUMENTS: 'Documents justificatifs',
    SUPPORTING_DOCUMENTS_NEEDED: 'Documents justificatifs nécessaires :',
    SUPPORTING_DOCUMENTS_TOOLTIP:
      'L’un des quatre documents justificatifs demandés aux fins d’identification personnelle est obligatoire.',
    THE_SELECTED_TIMESLOT_IS_NOW_AVAILABLE_TO_OTHER_USERS:
      'La plage horaire peut maintenant être sélectionnée par d’autres utilisateurs. Vous pouvez réserver cette plage horaire si elle est disponible.',
    THE_TIME_RUN_OUT: 'Le temps est écoulé.',
    TOTAL_ASSETS: 'Total des biens',
    TOTAL_COST_OF_REPAYMENT: 'Coût total du remboursement',
    TOTAL_DEBT_AMOUNT_YOU_PAY: 'Vous devrez toutefois rembourser le montant total de vos dettes.',
    TOTAL_DEBTS: 'Total des dettes',
    TOTAL_NUMBER_OF_OWNERS: 'Nombre total de propriétaires',
    TOTAL_REPAYMENT_AMOUNT: 'Le montant total à rembourser sera de',
    TOTAL_SECURED: 'Total des créances garanties',
    TOTAL_UNSECURED: 'Total des créances ordinaires',
    TRIM: 'Gamme',
    TRUSTEE_NAME: 'Nom du syndic',
    TRUSTEE_NAME_REQUIRED: 'Le nom du syndic est requis.',
    UNDER_WHAT_NAME: 'Sous quel nom avez-vous effectué le dépôt?',
    UNIT: 'Unité',
    UPLOADED: 'Téléversé',
    VALUE: 'Valeur',
    VEHICLE_NEEDED_FOR_EMPLOYMENT: 'Véhicule nécessaire à l’emploi',
    VIN: 'NIV',
    VOLUNTEER: 'Bénévole',
    WELCOME: 'Bienvenue',
    WHAT_ARE_REASONS_FOR_DIFFICULTY:
      'Donnez les raisons de vos difficultés financières (choisissez toutes les options qui s’appliquent et donnez des détails)',
    WITH: 'avec',
    WORK: 'Travail',
    YEAR: 'Année',
    YES: 'Oui',
    YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS: 'Vous devez accepter les termes et conditions.',
    RELATIONSHIP_BREAKDOWN: 'Rupture du couple',
    LOSS_OF_INCOME: 'Diminution de revenus',
    MEDICAL_REASONS: 'Problèmes médicaux',
    TAX_LIABILITIES: 'Dettes fiscales',
    FINANCIAL_SUPPORT_OF_OTHERS: 'Aide financière à un tiers',
    STUDENT_DEBT: 'Dettes d’études',
    MOVING_RELOCATION_EXPENSES: 'Déménagement',
    FAILED_PROPOSAL: 'Échec d’une proposition',
    FINANCIAL_MISMANAGEMENT: 'Mauvaise gestion financière',
    LEGAL_MATTERS_PLEASE_SPECIFY: 'Problèmes judiciaires (veuillez préciser)',
    OTHER_PLEASE_SPECIFY: 'Autre (veuillez préciser)',
    BUSINESS_FAILURE: 'Échec de l’entreprise',
    LEGAL_MATTERS: 'Problèmes judiciaires',
    ADDICTION_OTHER_THAN_GAMBLING: 'Dépendance autre qu’au jeu',
    REDUCTION_OF_HOUSEHOLD_INCOME: 'Baisse du revenu du ménage',
    DESCRIBE_IN_YOUR_OWN_WORDS_WHY_YOU_NEED_FINANCIAL_HELP:
      'Expliquez, dans vos mots, pourquoi vous avez besoin d’aide en matière de finances :',
    SELECT_AT_LEAST_ONE_REASON: 'Sélectionnez au moins une raison.',
    PROPOSAL_DATE: 'Date de la proposition',
    DEPENDANTS: 'Personnes à charge',
    PREVIOUS_INSOLVENCIES: 'Situations d’insolvabilité antérieures',
    RESULT_OF_PROPOSAL: 'Résultat de la proposition',
    Fax: 'Télécopieur',
    Mobile: 'Cellulaire',
    Home: 'Maison',
    Work: 'Travail',
    inperson: 'En Personne',
    phone: 'Téléphone/Vidéo',
    unknown: 'Inconnu',
    Unknown: 'Inconnu',
    InitialConsultation: 'Consultation Initiale',
    FollowUpConsultation: 'Consultation de Suivi',
    Counselling_1st: '1re Consultation Budgétaire',
    Counselling_2nd: '2e Consultation Budgétaire',
    Counselling_3rd: '3e Consultation Budgétaire',
    MeetingWithCreditors: 'Assemblée des Créanciers',
    AB: 'Alberta',
    BC: 'Colombie-Britannique',
    MB: 'Manitoba',
    NB: 'Nouveau-Brunswick',
    NS: 'Nouvelle-Écosse',
    NU: 'Nunavut',
    NT: 'Territoires du Nord-Ouest',
    NL: 'Terre-Neuve-et-Labrador',
    ON: 'Ontario',
    PE: 'Île-du-Prince-Édouard',
    QC: 'Québec',
    YT: 'Yukon',
    AK: 'Alaska',
    AZ: 'Arizona',
    SK: 'Saskatchewan',
    AL: 'Alabama',
    CA: 'Californie',
    AR: 'Arkansas',
    CO: 'Colorado',
    CT: 'Connecticut',
    FL: 'Floride',
    GA: 'Géorgie',
    DE: 'Delaware',
    IN: 'Indiana',
    IL: 'Illinois',
    HI: 'Hawaï',
    ID: 'Idaho',
    KS: 'Kansas',
    KY: 'Kentucky',
    IA: 'Iowa',
    LA: 'Louisiane',
    MD: 'Maryland',
    ME: 'Maine',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MS: 'Mississippi',
    MN: 'Minnesota',
    MO: 'Missouri',
    NE: 'Nebraska',
    MT: 'Montana',
    NJ: 'New Jersey',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NM: 'Nouveau-Mexique',
    NC: 'Caroline du Nord',
    NY: 'New York',
    OK: 'Oklahoma',
    OR: 'Oregon',
    OH: 'Ohio',
    ND: 'Dakota du Nord',
    PA: 'Pennsylvanie',
    SC: 'Caroline du Sud',
    RI: 'Rhode Island',
    TX: 'Texas',
    TN: 'Tennessee',
    SD: 'Dakota du Sud',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginie',
    WA: 'Washington',
    WY: 'Wyoming',
    WI: 'Wisconsin',
    WV: 'Virginie-Occidentale',
    CANADA: 'Canada',
    UNITED_STATES: 'États-Unis',
    AFGHANISTAN: 'Afghanistan',
    ALBANIA: 'Albanie',
    ANGOLA: 'Angola',
    AMERICAN_SAMOA: 'Samoa américaines',
    ALGERIA: 'Algérie',
    ANDORRA: 'Andorre',
    ANGUILLA: 'Anguilla',
    ARGENTINA: 'Argentine',
    ARMENIA: 'Arménie',
    ANTARCTICA: 'Antarctique',
    ANTIGUA_BARBUDA: 'Antigua-et-Barbuda',
    AUSTRALIA: 'Australie',
    ARUBA: 'Aruba',
    ASCENSION_ISLAND: 'Île de l’Ascension',
    BAHRAIN: 'Bahreïn',
    AZERBAIJAN: 'Azerbaïdjan',
    AUSTRIA: 'Autriche',
    BANGLADESH: 'Bangladesh',
    BAHAMAS: 'Bahamas',
    BENIN: 'Bénin',
    BELARUS: 'Bielorussie',
    BELGIUM: 'Belgique',
    BARBADOS: 'Barbade',
    BERMUDA: 'Bermudes',
    BHUTAN: 'Bhoutan',
    BELIZE: 'Belize',
    BOSNIA_HERZEGOVINA: 'Bosnie-Herzégovine',
    BRITISH_INDIAN_OCEAN_TERRITORY: 'Territoire britannique de l’océan Indien',
    BOLIVIA: 'Bolivie',
    BRITISH_VIRGIN_ISLANDS: 'Îles Vierges britanniques',
    BRAZIL: 'Brésil',
    BRUNEI: 'Brunéi Darussalam',
    BOUVET_ISLAND: 'Île Bouvet',
    BOTSWANA: 'Botswana',
    CAMEROON: 'Cameroun',
    CAPE_VERDE: 'Cap-Vert',
    CANARY_ISLANDS: 'Îles Canaries',
    BULGARIA: 'Bulgarie',
    BURKINA_FASO: 'Burkina Faso',
    CAMBODIA: 'Cambodge',
    BURUNDI: 'Burundi',
    CHAD: 'Tchad',
    CEUTA_MELILLA: 'Ceuta et Melilla',
    CENTRAL_AFRICAN_REPUBLIC: 'République centrafricaine',
    CHINA: 'Chine',
    CARIBBEAN_NETHERLANDS: 'Pays-Bas caribéens',
    CHILE: 'Chili',
    CAYMAN_ISLANDS: 'Îles Caïmans',
    COCOS_KEELING_ISLANDS: 'Îles Cocos (Keeling)',
    CHRISTMAS_ISLAND: 'Île Christmas',
    CLIPPERTON_ISLAND: 'Îlot de Clipperton',
    COLOMBIA: 'Colombie',
    COSTA_RICA: 'Costa Rica',
    CONGO_KINSHASA: 'République démocratique du Congo',
    COMOROS: 'Comores',
    CONGO_BRAZZAVILLE: 'République du Congo',
    COOK_ISLANDS: 'Îles Cook',
    CUBA: 'Cuba',
    CZECHIA: 'Tchéquie',
    DENMARK: 'Danemark',
    DIEGO_GARCIA: 'Diego Garcia',
    CÔTE_DIVOIRE: 'Côte d’Ivoire',
    CYPRUS: 'Chypre',
    CROATIA: 'Croatie',
    CURAÇAO: 'Curaçao',
    DOMINICAN_REPUBLIC: 'République dominicaine',
    DOMINICA: 'Dominique',
    ECUADOR: 'Équateur',
    DJIBOUTI: 'Djibouti',
    EL_SALVADOR: 'El Salvador',
    EGYPT: 'Égypte',
    ESWATINI: 'Eswatini',
    EQUATORIAL_GUINEA: 'Guinée équatoriale',
    ESTONIA: 'Estonie',
    ERITREA: 'Érythrée',
    ETHIOPIA: 'Éthiopie',
    FAROE_ISLANDS: 'Îles Féroé',
    FINLAND: 'Finlande',
    FALKLAND_ISLANDS_ISLAS_MALVINAS: 'Îles Falkland (Malouines)',
    FRANCE: 'France',
    FIJI: 'Fidji',
    FRENCH_POLYNESIA: 'Polynésie française',
    FRENCH_GUIANA: 'Guyane française',
    GEORGIA: 'Géorgie',
    GAMBIA: 'Gambie',
    GABON: 'Gabon',
    FRENCH_SOUTHERN_TERRITORIES: 'Terres australes françaises',
    GERMANY: 'Allemagne',
    GREECE: 'Grèce',
    GRENADA: 'Grenade',
    GHANA: 'Ghana',
    GREENLAND: 'Groenland',
    GIBRALTAR: 'Gibraltar',
    GUAM: 'Guam',
    GUADELOUPE: 'Guadeloupe',
    GUATEMALA: 'Guatemala',
    GUERNSEY: 'Guernesey',
    GUYANA: 'Guyana',
    GUINEA: 'Guinée',
    GUINEA_BISSAU: 'Guinée-Bissau',
    HAITI: 'Haïti',
    HONDURAS: 'Honduras',
    HONG_KONG: 'Hong Kong',
    HEARD_MCDONALD_ISLANDS: 'Îles Heard et McDonald',
    HUNGARY: 'Hongrie',
    ICELAND: 'Islande',
    INDIA: 'Inde',
    IRAQ: 'Irak',
    IRAN: 'Iran',
    INDONESIA: 'Indonésie',
    ITALY: 'Italie',
    ISRAEL: 'Israël',
    ISLE_OF_MAN: 'Île de Man',
    IRELAND: 'Irlande',
    JERSEY: 'Jersey',
    JAPAN: 'Japon',
    JAMAICA: 'Jamaïque',
    JORDAN: 'Jordanie',
    KAZAKHSTAN: 'Kazakhstan',
    KIRIBATI: 'Kiribati',
    KOSOVO: 'Kosovo',
    KENYA: 'Kenya',
    KUWAIT: 'Koweït',
    LAOS: 'Laos',
    KYRGYZSTAN: 'Kirghizistan',
    LEBANON: 'Liban',
    LATVIA: 'Lettonie',
    LESOTHO: 'Lesotho',
    LIBYA: 'Libye',
    LIBERIA: 'Libéria',
    LIECHTENSTEIN: 'Liechtenstein',
    LUXEMBOURG: 'Luxembourg',
    LITHUANIA: 'Lituanie',
    MACAO: 'Macao',
    MALAWI: 'Malawi',
    MALAYSIA: 'Malaisie',
    MADAGASCAR: 'Madagascar',
    MALI: 'Mali',
    MARSHALL_ISLANDS: 'Îles Marshall',
    MALTA: 'Malte',
    MALDIVES: 'Maldives',
    MARTINIQUE: 'Martinique',
    MAURITIUS: 'Maurice',
    MAURITANIA: 'Mauritanie',
    MICRONESIA: 'Micronésie',
    MEXICO: 'Mexique',
    MAYOTTE: 'Mayotte',
    MONGOLIA: 'Mongolie',
    MOLDOVA: 'Moldavie',
    MONACO: 'Monaco',
    MONTENEGRO: 'Monténégro',
    MONTSERRAT: 'Montserrat',
    MOROCCO: 'Maroc',
    MOZAMBIQUE: 'Mozambique',
    MYANMAR_BURMA: 'Myanmar (Birmanie)',
    NAMIBIA: 'Namibie',
    NAURU: 'Nauru',
    NEPAL: 'Népal',
    NETHERLANDS: 'Pays-Bas',
    NICARAGUA: 'Nicaragua',
    NEW_ZEALAND: 'Nouvelle-Zélande',
    NEW_CALEDONIA: 'Nouvelle-Calédonie',
    NIGER: 'Niger',
    NIUE: 'Niue',
    NORFOLK_ISLAND: 'Île Norfolk',
    NIGERIA: 'Nigéria',
    NORTHERN_MARIANA_ISLANDS: 'Mariannes du Nord',
    NORTH_MACEDONIA: 'Macédoine du Nord',
    NORTH_KOREA: 'Corée du Nord',
    PAKISTAN: 'Pakistan',
    OMAN: 'Oman',
    NORWAY: 'Norvège',
    PALESTINE: 'Palestine',
    PALAU: 'Palaos',
    PANAMA: 'Panama',
    PARAGUAY: 'Paraguay',
    PAPUA_NEW_GUINEA: 'Papouasie-Nouvelle-Guinée',
    PHILIPPINES: 'Philippines',
    PERU: 'Pérou',
    POLAND: 'Pologne',
    PITCAIRN_ISLANDS: 'Îles Pitcairn',
    PUERTO_RICO: 'Porto Rico',
    PORTUGAL: 'Portugal',
    ROMANIA: 'Roumanie',
    QATAR: 'Qatar',
    RÉUNION: 'Réunion',
    RWANDA: 'Rwanda',
    RUSSIA: 'Russie',
    SAN_MARINO: 'Saint-Marin',
    SAMOA: 'Samoa',
    SERBIA: 'Serbie',
    SAUDI_ARABIA: 'Arabie saoudite',
    SENEGAL: 'Sénégal',
    SEYCHELLES: 'Seychelles',
    SIERRA_LEONE: 'Sierra Leone',
    SINT_MAARTEN: 'Saint-Martin',
    SINGAPORE: 'Singapour',
    SLOVAKIA: 'Slovaquie',
    SLOVENIA: 'Slovénie',
    SOLOMON_ISLANDS: 'Îles Salomon',
    SOMALIA: 'Somalie',
    SOUTH_AFRICA: 'Afrique du Sud',
    SOUTH_GEORGIA_SOUTH_SANDWICH_ISLANDS: 'Géorgie du Sud et îles Sandwich du Sud',
    SOUTH_KOREA: 'Corée du Sud',
    SPAIN: 'Espagne',
    SOUTH_SUDAN: 'Soudan du Sud',
    ST_HELENA: 'Sainte-Hélène',
    ST_BARTHÉLEMY: 'Saint-Barthélemy',
    SRI_LANKA: 'Sri Lanka',
    ST_VINCENT_GRENADINES: 'Saint-Vincent-et-les Grenadines',
    ST_PIERRE_MIQUELON: 'Saint-Pierre-et-Miquelon',
    ST_LUCIA: 'Sainte-Lucie',
    ST_KITTS_NEVIS: 'Saint-Kitts-et-Nevis',
    ST_MARTIN: 'Saint-Martin',
    SVALBARD_JAN_MAYEN: 'Svalbard et Jan Mayen',
    SURINAME: 'Suriname',
    SUDAN: 'Soudan',
    SWEDEN: 'Suède',
    SYRIA: 'Syrie',
    SÃO_TOMÉ_PRÍNCIPE: 'Sao Tomé-et-Principe',
    SWITZERLAND: 'Suisse',
    TAIWAN: 'Taïwan',
    TANZANIA: 'Tanzanie',
    THAILAND: 'Thaïlande',
    TAJIKISTAN: 'Tadjikistan',
    TIMOR_LESTE: 'Timor-Leste',
    TOKELAU: 'Tokelau',
    TRINIDAD_TOBAGO: 'Trinité-et-Tobago',
    TOGO: 'Togo',
    TONGA: 'Tonga',
    TRISTAN_DA_CUNHA: 'Tristan da Cunha',
    TUNISIA: 'Tunisie',
    TURKMENISTAN: 'Turkménistan',
    TURKEY: 'Turquie',
    TURKS_CAICOS_ISLANDS: 'Îles Turks et Caicos',
    TUVALU: 'Tuvalu',
    US_OUTLYING_ISLANDS: 'Îles mineures éloignées des États-Unis',
    US_VIRGIN_ISLANDS: 'Îles Vierges américaines',
    UKRAINE: 'Ukraine',
    UGANDA: 'Ouganda',
    UNITED_ARAB_EMIRATES: 'Émirats arabes unis',
    UNITED_KINGDOM: 'Royaume-Uni',
    URUGUAY: 'Uruguay',
    UZBEKISTAN: 'Ouzbékistan',
    VANUATU: 'Vanuatu',
    VATICAN_CITY: 'Cité du Vatican',
    VENEZUELA: 'Venezuela',
    WALLIS_FUTUNA: 'Wallis-et-Futuna',
    VIETNAM: 'Vietnam',
    WESTERN_SAHARA: 'Sahara occidental',
    ZAMBIA: 'Zambie',
    YEMEN: 'Yémen',
    ÅLAND_ISLANDS: 'Îles d’Åland',
    ZIMBABWE: 'Zimbabwe',
    OUT_OF_HOME: 'Affichage',
    PREVIOUS_DEBTOR: 'Previous Debtor',
    PRINT: 'Publicité imprimée',
    YELLOW_PAGES: 'Pages Jaunes',
    BROADCAST: 'Publicité télévisuelle ou radiophonique',
    MONTHLY_INCOME: 'Revenu mensuel',
    MONTHLY_EXPENSE: 'Dépense mensuelle',
    BUDGET: 'Budget',
    INCOME: 'Revenus',
    CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_INCOME: 'Cliquez sur le bouton Modifier pour indiquer votre revenu.',
    EXPENSES: 'Dépenses',
    CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_EXPENSES: 'Cliquez sur le bouton Modifier pour indiquer vos dépenses.',
    FIELD_IS_REQUIRED: 'Le champ {0} est obligatoire.',
    SPOUSE_FIRST_NAME: 'Prénom du conjoint',
    SPOUSE_LAST_NAME: 'Nom de famille du conjoint',
    SPOUSE_DATE_OF_BIRTH: 'Date de naissance du conjoint',
    SPOUSE_SIN: 'NAS du conjoint',
    GO_TO_ERROR: 'Consulter l’erreur',
    GO_TO_WARNING: 'Consulter l’avertissement',
    ONLY_1_OF_THE_4_SUPPORTING_DOCUMENTS_LISTED_FOR_PERSONAL_IDENTIFICATION_IS_REQUIRED:
      'L’un des quatre documents justificatifs demandés aux fins d’identification personnelle est obligatoire.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_BANKING_INFORMATION_IS_REQUIRED:
      'L’un des deux documents justificatifs demandés pour confirmer les renseignements bancaires est obligatoire.',
    OVERDRAFT_OWED: 'Découvert de compte dû',
    JOINT_ACCOUNT: 'Compte conjoint',
    ADD_BANKING_INFORMATION: 'Ajouter des renseignements bancaires',
    SIN_NOT_VALID: 'Le NAS n’est pas valide.',
    PHONE_NOT_VALID: 'Le numéro de téléphone est incorrect.',
    FIELD_IS_RECOMMENDED: 'Il est recommandé de remplir le champ {0}.',
    ADDRESS_UNIT: 'Numéro d’unité',
    SHOULD_BE_GREATER_THAN: 'La valeur {0} devrait être supérieure à {1}.',
    YEAR_LAST_RETURN_FILED: 'Année d’imposition de la dernière déclaration de revenus',
    REFUND_AMOUNT: 'Montant du remboursement',
    AMOUNT_OWING: 'Solde à payer',
    AT_LEAST_ONE_FIELD_MUST_HAVE_A_VALUE: 'Au moins un champ doit contenir une valeur.',
    IS_RECOMMENDED: 'est recommandé',
    THIS_FIELD_IS_RECOMMENDED: 'Il est recommandé de remplir ce champ.',
    EMAIL_NOT_VALID: 'L’adresse électronique n’est pas valide.',
    DEBT_AREAS_APPLICANT: 'Secteurs d’endettement — Demandeur',
    DEBT_AREAS_SPOUSEPARTNER: 'Secteurs d’endettement — Époux ou conjoint',
    COSIGNS_OR_GUARANTEED_LOANS: 'Prêts avec cosignataire ou garantie',
    TRANSACTIONS_APPLICANT: 'Transactions — Demandeur',
    TRANSACTIONS_SPOUSEPARTNER: 'Transactions — Époux ou conjoint',
    BUSINESS: 'Entreprise',
    CREDIT_BUREAU_CONSENT: 'Consentement du bureau de crédit',
    FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT:
      'Amende ou pénalité imposée par un tribunal (y compris pour voies de fait)',
    DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE:
      'Vous attendez-vous à recevoir des sommes d’argent qui ne sont pas liées à vos revenus habituels ou tout autre bien au cours des 12 prochains mois (y compris un héritage)?',
    ANNUITIES_BANK_STATEMENT: 'Rente — Relevé bancaire',
    ATV_INSURANCE: 'VTT – Assurance',
    ATV_LOAN_BALANCE: 'VTT —Solde du prêt',
    ATV_REGISTRATION: 'VTT – Immatriculation',
    BOAT_INSURANCE: 'Bateau — Assurance',
    BOAT_LOAN_BALANCE: 'Bateau — Solde du prêt',
    BOAT_REGISTRATION: 'Bateau — Immatriculation',
    BONDS_STATEMENT: 'Obligations — Relevé',
    BONDS_ACCOUNT_ACTIVITY: 'Obligations — Activité dans le compte',
    CAMPER_INSURANCE: 'Autocaravane — Assurance',
    CAMPER_LOAN_BALANCE: 'Autocaravane — Solde du prêt',
    CAMPER_REGISTRATION: 'Autocaravane — Immatriculation',
    CANADA_CHILD_BENEFIT_BANK_STATEMENT: 'Allocation canadienne pour enfants — Relevé bancaire',
    CHILD_SUPPORT_PAYMENT_AGREEMENT: 'Pension alimentaire pour enfant — Accord',
    CHILD_SUPPORT_PAYMENT_PROOF_OF_PAYMENT: 'Pension alimentaire pour enfant — Preuve de paiement',
    CONDO_INSURANCE: 'Copropriété — Assurance',
    CONDO_MORTGAGE_BALANCE: 'Copropriété — Solde hypothécaire',
    CONDO_OWNERSHIP: 'Copropriété — Propriété',
    CONDO_PROPERTY_VALUE: 'Copropriété — Valeur de la propriété',
    COOP_SHARES_VALUE: 'Parts dans une coopérative — Valeur',
    COOP_SHARES_STATEMENT: 'Parts dans une coopérative — Relevé',
    COTTAGE_INSURANCE: 'Chalet — Assurance',
    COTTAGE_MORTGAGE_BALANCE: 'Chalet — Solde hypothécaire',
    COTTAGE_OWNERSHIP: 'Chalet — Propriété',
    COTTAGE_PROPERTY_VALUE: 'Chalet — Valeur de la propriété',
    CREDIT_UNION_SHARES_STATEMENT: 'Parts de coopérative de crédit — Relevé',
    CREDIT_UNION_SHARES_VALUE: 'Parts de coopérative de crédit — Valeur',
    EI_BANK_STATEMENT: 'Assurance-emploi — Relevé bancaire',
    HOUSE_INSURANCE: 'Maison — Assurance',
    HOUSE_MORTGAGE_BALANCE: 'Maison — Solde hypothécaire',
    HOUSE_OWNERSHIP: 'Maison — Propriété',
    HOUSE_PROPERTY_VALUE: 'Maison — Valeur de la propriété',
    ID_DRIVERS_LICENSE: 'Pièce d’identité — Permis de conduire',
    ID_HEALTH_CARD: 'Pièce d’identité — Carte santé',
    ID_OTHER: 'Pièce d’identité — Autre',
    ID_PASSPORT: 'Pièce d’identité — Passeport',
    INVESTMENTS_ACCOUNT_ACTIVITY: 'Placements — Activité dans le compte',
    INVESTMENTS_STATEMENT: 'Placements — Relevé',
    LAND_INSURANCE: 'Terrain — Assurance',
    LAND_MORTGAGE_BALANCE: 'Terrain — Solde hypothécaire',
    LAND_OWNERSHIP: 'Terrain — Propriété',
    LAND_PROPERTY_VALUE: 'Terrain — Valeur de la propriété',
    LIFE_INSURANCE_STATEMENT: 'Assurance vie — Relevé',
    LIRA_ACCOUNT_ACTIVITY: 'CRI – Activité dans le compte',
    LIRA_STATEMENT: 'CRI – Relevé',
    MOTOR_VEHICLE_INSURANCE: 'Véhicule automobile — Assurance',
    MOTOR_VEHICLE_LOAN_BALANCE: 'Véhicule automobile — Solde du prêt',
    MOTOR_VEHICLE_REGISTRATION: 'Véhicule automobile — Immatriculation',
    MOTORCYCLE_INSURANCE: 'Motocyclette — Assurance',
    MOTORCYCLE_LOAN_BALANCE: 'Motocyclette — Solde du prêt',
    MOTORCYCLE_REGISTRATION: 'Motocyclette — Immatriculation',
    OTHER_INVESTMENT_STATEMENT: 'Autre placement — Relevé',
    OTHER_INVESTMENT_VALUE: 'Autre placement — Valeur',
    OTHER_RECREATIONAL_EQUIPMENT_INSURANCE: 'Autre véhicule récréatif — Assurance',
    OTHER_RECREATIONAL_EQUIPMENT_LOAN_BALANCE: 'Autre véhicule récréatif — Solde du prêt',
    OTHER_RECREATIONAL_EQUIPMENT_REGISTRATION: 'Autre véhicule récréatif — Immatriculation',
    PAY_STUB: 'Bordereau de paie',
    PENSION_ACCOUNT_ACTIVITY: 'Régime de retraite — Activité dans le compte',
    PENSION_BANK_STATEMENT: 'Régime de retraite — Relevé bancaire',
    PENSION_STATEMENT: 'Régime de retraite — Relevé',
    PREAUTHORIZATION_FORM: 'Formulaire d’autorisation préalable',
    RDSP_ACCOUNT_ACTIVITY: 'REEI – Activité dans le compte',
    RDSP_STATEMENT: 'REEI – Relevé',
    REAL_ESTATE_INSURANCE: 'Immeuble — Assurance',
    REAL_ESTATE_MORTGAGE_BALANCE: 'Immobilier — Solde hypothécaire',
    REAL_ESTATE_OWNERSHIP: 'Immobilier — Propriété',
    REAL_ESTATE_PROPERTY_VALUE: 'Immobilier — Valeur de la propriété',
    RENTAL_INCOME_BUSINESS_BANK_STATEMENT: 'Revenu locatif — Relevé bancaire de l’entreprise',
    RENTAL_INCOME_INVOICES: 'Revenu locatif — Factures',
    RENTAL_INCOME_T2125_YEAR_TO_DATE: 'Revenu locatif — Formulaire T2125 (depuis le début de l’année en cours)',
    RESP_ACCOUNT_ACTIVITY: 'REEE – Activité dans le compte',
    RESP_STATEMENT: 'REEE – Relevé',
    RRIF_ACCOUNT_ACTIVITY: 'FERR – Activité dans le compte',
    RRIF_STATEMENT: 'FERR – Relevé',
    RRSP_ACCOUNT_ACTIVITY: 'REER – Activité dans le compte',
    RRSP_STATEMENT: 'REER – Relevé',
    SELF_EMPLOYMENT_T2125: 'Travail indépendant — Formulaire T2125',
    SELFEMPLOYMENT_INCOME_BUSINESS_BANK_STATEMENT: 'Revenu de travail indépendant — Relevé bancaire de l’entreprise',
    SELFEMPLOYMENT_INCOME_INVOICES: 'Revenu de travail indépendant — Factures',
    SELFEMPLOYMENT_INCOME_T2125_YEAR_TO_DATE:
      'Revenu de travail indépendant — Formulaire T2125 (depuis le début de l’année en cours)',
    SHARES_ACCOUNT_ACTIVITY: 'Actions — Activité dans le compte',
    SHARES_STATEMENT: 'Actions — Relevé',
    SNOWMOBILE_INSURANCE: 'Motoneige — Assurance',
    SNOWMOBILE_LOAN_BALANCE: 'Motoneige — Solde du prêt',
    SNOWMOBILE_REGISTRATION: 'Motoneige — Immatriculation',
    SOCIAL_ASSISTANCE_BANK_STATEMENT: 'Aide sociale — Relevé bancaire',
    SPOUSAL_SUPPORT_PAYMENT_AGREEMENT: 'Pension alimentaire pour époux — Accord',
    SPOUSAL_SUPPORT_PAYMENT_PROOF_OF_PAYMENT: 'Pension alimentaire pour époux — Preuve de paiement',
    TAX_ASSESSMENT: 'Cotisation fiscale',
    TAX_RETURN: 'Déclaration de revenus',
    TFSA_ACCOUNT_ACTIVITY: 'CELI —Activité dans le compte',
    TFSA_STATEMENT: 'CELI —Relevé',
    TRAILER_INSURANCE: 'Remorque — Assurance',
    TRAILER_LOAN_BALANCE: 'Remorque — Solde du prêt',
    TRAILER_REGISTRATION: 'Remorque — Immatriculation',
    VOID_CHEQUE: 'Specimen de chèque annulé',
    RECEIPT: 'Reçu',
    CLAIM_FILE: 'Dossier de réclamation',
    SECURED_CHECKLIST: 'Liste de contrôle sécurisée',
    FORM_56_NOTICE_OF_STATUS: 'Formulaire 56 — Avis concernant l’état',
    FORM_93_NOTICE_TO_CREDITORS_OR_AUTOMATIC_REVIVAL_:
      'Formulaire 93 — Préavis aux créanciers ou rétablissement d’office',
    FORM_95_NOTICE_OF_NO_AUTOMATIC_REVIVAL: 'Formulaire 95 — Avis du non-rétablissement d’office',
    FORM_96_NOTICE_TO_CREDITORS_FOR_AUTOREVIVAL: 'Formulaire 96 — Avis aux créanciers du rétablissement d’office',
    D_45_DAY_PROPOSAL_CHECKLIST: '45 jours — Liste de contrôle de la proposition',
    COURT_REVIVAL: 'Rétablissement octroyé par le tribunal',
    PAYMENT_SUMMARY: 'Sommaire des paiements',
    D_170_CHECKLIST: 'Liste de contrôle du rapport du syndic (article 170)',
    D_170_REPORT: 'Rapport du syndic (article 170)',
    DISCHARGE_CERTIFICATE: 'Certificat de libération',
    OPPOSITION_LETTER: 'Lettre d’opposition',
    FORM_80: 'Formulaire 80',
    FORM_63: 'Formulaire 63',
    SIGNED_AGREEMENT: 'Accord signé',
    CHEQUE_REQUISITION: 'Demande de chèque',
    RECOMMENDATION_LETTER: 'Lettre de recommandation',
    CONDITIONAL_ORDER: 'Jugement conditionnel',
    CERTIFICATE_OF_FULL_PERFORMANCE: 'Certificat d’exécution intégrale',
    SRD_REPORT: 'Rapport de l’ERD',
    FORM_58_NOTICE_OF_TAXATION_OF_ADMINISTRATORS_ACCOUNTS_AND_DISCHARGE_OF_ADMINISTRATOR:
      'Formulaire 58 — Avis de taxation des comptes et de la libération de l’administrateur',
    DIVIDEND_CHECKLIST: 'Liste de contrôle des dividendes',
    SRD_CHECKLIST: 'Liste de contrôle de l’ERD',
    FORM_16_CERTIFICATE_OF_COMPLIANCE_AND_DEEMED_DISCHARGE_OF_TRUSTEE_OR_ADMINISTRATOR:
      'Formulaire 16 — Certificat de conformité et libération présumée du syndic ou de l’administrateur',
    FORM_15_NOTICE_OF_DEEMED_TAXATION_OF_TRUSTEES_ACCOUNTS_AND_DEEMED_DISCHARGE_OF_TRUSTEE:
      'Formulaire 15 — Avis de la taxation présumée des comptes et de la libération présumée du syndic',
    LETTER_TO_DEBTOR_RE_OPPOSITION: 'Lettre au débiteur concernant l’opposition',
    CONSENT_CB: 'Consentement du BC',
    BANK_STATEMENT_IF_SOURCE_OF_INCOME_OTHER_THAN_PAY_STUB:
      'Relevé bancaire pour toute autre source de revenus ne faisant pas l’objet d’un bordereau de paie',
    COPY_OF_INSURANCE: 'Copie de la police d’assurance',
    PROOF_OF_CURRENT_LOAN_BALANCE: 'Preuve du solde de prêt actuel',
    COPY_OF_REGISTRATION: 'Copie du certificat d’immatriculation',
    RECENT_STATEMENT_SHOWING_VALUE_AND_BENEFICIARY:
      'Relevé récent indiquant les renseignements sur la valeur et le bénéficiaire',
    STATEMENT_OF_ACCOUNT_ACTIVITY_FOR_PREVIOUS_12_MONTHS: 'Relevé de compte pour les 12 derniers mois',
    COPY_OF_SUPPORT_AGREEMENT: 'Copie de la convention alimentaire',
    IF_NO_WRITTEN_AGREEMENT_PROOF_OF_PAYMENT_ETRANSFER_LETTER_FROM_COPARENT_RECEIPT:
      'En l’absence d’une convention écrite, obtenir une preuve de paiement (paiement par transfert électronique, lettre d’un coparent, reçu)',
    PROOF_OF_INSURANCE: 'Preuve d’assurance',
    PROOF_OF_CURRENT_MORTGAGE_BALANCE: 'Preuve du solde hypothécaire actuel',
    PROOF_OF_OWNERSHIP_ONE_OF_EITHER_PURVIEWMUNICIPAL_SEARCHSAMALAND_TITLE_SEARCHCADO:
      'Preuve de propriété (l’un de ces documents : rapport d’arpentage, document municipal, document tiré de SAMA, recherche sur un titre de propriété, document tiré de CADO)',
    PROOF_OF_CURRENT_PROPERTY_VALUE: 'Preuve de la valeur actuelle de la propriété',
    ALL_PERTINENT_DOCUMENTS_TO_SUPPORT_VALUE: 'Tous les documents pertinents pour étayer la valeur',
    RECENT_STATEMENT_SHOWING_OWNER_BENEFICIARY_AND_CASH_SURRENDER_VALUE:
      'Relevé récent indiquant les renseignements relatifs au propriétaire, au bénéficiaire et à la valeur de rachat',
    CREDITOR_EMAIL_ADDRESS: 'Adresse électronique du créancier',
    THE_SIZE_OF_ATTACHMENTS_SHOULD_NOT_EXCEED_100MB: 'La taille totale des fichiers joints ne doit pas excéder 100 Mo.',
    PREAUTHORIZATION_FORM_REQUIRED_FOR_ACCOUNT_IN_WHICH_YOU_WILL_BE_DRAWING_MONEY_FROM:
      'Le formulaire de préautorisation est obligatoire pour le compte duquel vous retirerez des fonds.',
    CONSENT_TEXT:
      'Je consens à ce que mes renseignements personnels soient fournis au syndic aux fins de l’établissement d’une demande de dossier de crédit auprès de TransUnion et du syndic de même que de la validation et de la réalisation de mes dettes et de mes obligations. Ces renseignements personnels peuvent comprendre les éléments suivants : nom, date de naissance, état civil, numéros de téléphone, adresse, renseignements sur une ligne de crédit ou des comptes actuels, renseignements bancaires, événements liés à une faillite, à des procédures judiciaires et à des événements de recouvrement, le cas échéant.',
    I_UNDERSTAND: 'Je comprends',
    ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_FOR_RENTAL_INCOME_IS_REQUIRED:
      'L’un des trois documents justificatifs demandés pour confirmer le revenu d’un travail indépendant est requis.',
    SUPPORTING_DOCUMENTS_ARE_REQUIRED: 'Des documents justificatifs sont requis.',
    INVALID_DATE: 'La date est invalide.',
    FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE:
      'Pour les dossiers conjoints, une pièce d’identité pour le demandeur et une pièce d’identité pour le conjoint sont obligatoires.',
    DATE_CANNOT_BE_IN_THE_FUTURE: 'Date cannot be in the future',
    DATE_CANNOT_BE_IN_THE_PAST: 'Date cannot be in the past',
    PHONE: 'Téléphone',
    EDIT_PROFILE: 'Modifier le profil',
    FOR_SPOUSE: 'Pour le conjoint',
    DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'Vous n’avez pas sélectionné de préfixe pour les documents téléversés. Restez sur la page pour sélectionner les préfixes. Si vous quittez la page, les documents téléversés seront perdus.',
    QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'Vous n’avez pas sélectionné de préfixe ou d’entreprise pour les documents téléversés. Restez sur la page pour sélectionner les préfixes ou les entreprises. Si vous quittez la page, les documents téléversés seront perdus.',
    SETTINGS_ARE_UPDATED: 'Les paramètres sont mis à jour.',
    MODULE_SAVED_SUCCESSFULLY: '{0} document(s) sauvegardé(s) avec succès',
    OLD_PASSWORD: 'Ancien mot de passe',
    NEW_PASSWORD: 'Nouveau mot de passe',
    CONFIRM_NEW_PASSWORD: 'Confirmer le nouveau mot de passe',
    ONE_LOWERCASE_CHARACTER: 'Un caractère minuscule',
    ONE_UPPERCASE_CHARACTER: 'Un caractère majuscule',
    ONE_NUMBER: 'Un chiffre',
    ONE_SPECIAL_CHARACTER: 'Un caractère spécial',
    EIGHT_CHARACTERS_MINIMUM: 'Minimum de 8 caractères',
    PASSWORDS_MATCH: 'Les mots de passe correspondent.',
    CHANGE_YOUR_PASSWORD: 'Modifier votre mot de passe',
    ONLINE_APPLICATION_FORM: 'Formulaire de demande en ligne',
    THE_INFORMATION_ENTERED_ON_THIS_FORM_WILL_BE_IMPORTANT_FOR_YOUR_FIRST_CONSULTATION_MEETING:
      'Les renseignements inscrits sur ce formulaire seront nécessaires lors de votre première consultation avec l’un de nos spécialistes en allégement des dettes.',
    THE_APPLICATION_TAKES_TIME_TO_COMPLETE_AND_CAN_BE_REVISITED_ANY_TIME:
      'Il vous faudra de dix à vingt minutes pour remplir le formulaire et vous pourrez le modifier en tout temps d’ici votre consultation. Si vous ne savez pas comment répondre à l’une des questions, n’inscrivez rien. Notre personnel se fera un plaisir de vous assister.',
    N_OUT_OF_TOTAL_COMPLETE: '{0} téléversement(s) sur {1} terminé(s)',
    NO_UPLOADS_OUTSTANDING: 'Aucun téléversement en attente',
    N_UPLOADS_OUTSTANDING: '{0} téléversement(s) en attente',
    UPLOAD_DOCUMENTS: 'Téléverser des documents',
    BACK_TO_DASHBOARD: 'Retourner au tableau de bord',
    REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST:
      'Demandez une rencontre de suivi avec votre spécialiste en allégement des  dettes',
    OUR_TEAM_WILL_CONTACT_YOU_TO_CONFIRM_THE_DATE_AND_TIME:
      'Notre équipe communiquera avec vous pour confirmer l’heure et la date.',
    NEW_PASSWORD_MISSING: 'Nouveau mot de passe manquant',
    CONFIRM_PASSWORD_MISSING: 'Confirmation du mot de passe manquante',
    PASSWORD_MISMATCH: 'Les mots de passe ne correspondent pas.',
    OLD_PASSWORD_MISSING: 'Ancien mot de passe manquant',
    INVALID_OLD_PASSWORD: 'Ancien mot de passe invalide',
    PASSWORD_CHANGED_SUCCESSFULLY: 'Mot de passe modifié avec succès',
    DOCUMENT_UPLOADED_SUCCESSFULLY: 'Document téléversé avec succès',
    DOCUMENT_UPLOAD_FAILED: 'Échec du téléversement',
    PROGRESS_STATUS_INITIALSTEP_DESCRIPTION:
      'Si vous êtes à la recherche du meilleur spécialiste en allégement des dettes pour résoudre votre endettement, profitez d’une rencontre gratuite et sans engagement avec un syndic autorisé en insolvabilité de BDO en vue de retrouver la tranquillité d’esprit en matière financière. Nous avons des bureaux et des spécialistes en allégement de dettes partout au pays, y compris dans votre collectivité.',
    GATHER_DOCUMENTS: 'Obtention des documents',
    SIGN_UP: 'Inscription',
    FILE_IN_PROGRESS: 'Dossier en cours',
    COMPLETE_YOUR_APPLICATION_FORM_AND_ATTENT_YOUR_INITIAL_CONSULTATION:
      'Remplissez votre formulaire de demande et participez à votre consultation initiale.',
    COMPLETE_YOUR_APPLICATION_FORM_AND_UPLOAD_ALL_SUPPORTING_DOCUMENTS:
      'Remplissez votre formulaire de demande et téléversez les documents justificatifs.',
    COMPLETE_YOUR_SIGNUP_PROCESS:
      'Procédez à votre inscription avec le soutien d’un spécialiste en allégement de dettes.',
    YOUR_DEBT_RELIEF_SPECIALIST_WILL_CONTACT_YOU:
      'Un spécialiste en allégement de dettes communiquera avec vous au sujet des prochaines étapes à suivre.',
    YOU_HAVE_SIGNED_UP: 'Vous êtes inscrit!',
    PAYMENTS_AND_BANKING: 'Paiements et renseignements bancaires',
    REMAINING_BALANCE: 'Solde restant',
    BALANCE_PAID: 'Solde payé',
    PREAUTHORIZED_DEBIT_INFORMATION: 'Renseignements sur les débits préautorisés',
    REQUEST_CHANGE: 'Demander une modification',
    SHOW_MORE: 'Afficher plus',
    SHOW_LESS: 'Afficher moins',
    PAYMENTS: 'Paiements',
    PREAUTHORIZED_PAYMENT: 'Paiement préautorisé',
    PAST: 'Précédent',
    PAYMENT_DETAILS: 'Détails du paiement',
    PAID: 'Payé',
    RECEIPT_AVAILABLE: 'Reçu disponible',
    AMOUNT_DUE: 'Montant dû',
    REQUEST_HOLD: 'Demander une mise en suspens',
    DOWNLOAD_RECEIPT: 'Télécharger le reçu',
    HOLD_UNTIL: 'En suspens jusqu’au',
    HOLD_REASON: 'Motif de la mise en suspens',
    HOLD_REQUESTED_UNTIL: 'Mise en suspens demandée jusqu’au {0}',
    ON_HOLD_UNTIL: 'En suspens jusqu’au {0}',
    CANCEL_HOLD: 'Annuler la mise en suspens',
    HOLD_CANCELLED: 'Mise en suspens annulée',
    HOLD_REQUESTED: 'Mise en suspens demandée',
    SUBJECT: 'Objet',
    MESSAGE: 'Message',
    SUBJECT_IS_REQUIRED: 'L’objet est requis.',
    MESSAGE_IS_REQUIRED: 'Le message est requis.',
    SEND_MESSAGE_TO_DEBT_RELIEF_SPCETIALIST_INFO:
      'Envoyez un message au spécialiste en allégement de dettes actuellement chargé de votre dossier. Le délai de réponse est habituellement d’un ou deux jours ouvrables.',
    MESSAGE_SENT: 'Message envoyé!',
    YOU_WILL_RECEIVE_A_REPLY_WITHIN_2_BUSINESS_DAYS:
      'Vous recevrez une réponse dans les deux jours ouvrables à l’adresse électronique figurant à votre dossier.',
    CLAIMS: 'Créances',
    CORRESPONDENCE: 'Correspondance',
    DISCHARGE: 'Libération',
    LEAD: 'contact',
    SURPLUS_IE: 'Excédent (produits/charges)',
    BANKING: 'Renseignements bancaires',
    TAXES: 'Impôts',
    CLIENT_UPLOAD: 'Téléversement du client',
    PENDING: 'En suspens',
    COMPLETED: 'Terminé',
    CANCELLED: 'Annulé',
    UNKNOWN: 'Inconnu',
    COMMONLAW: 'Conjoint de fait',
    WIDOWED: 'Veuf',
    SEPARATED: 'Séparé',
    SOME_HIGH_SCHOOL: 'Études secondaires',
    HIGH_SCHOOL_GRADUATE: 'Diplôme d’études secondaires',
    SOME_POST_SECONDARY: 'Études postsecondaires',
    POST_SECONDARY_CERTIFICATE_OR_DIPLOMA: 'Certificat ou diplôme d’études postsecondaires',
    UNIVERSITY_DEGREE: 'Diplôme universitaire',
    CHILD_STEPCHILD_OR_FOSTER_CHILD: 'Enfant, beau-fils, belle-fille ou enfant adoptif',
    BROTHER: 'Frère',
    SISTER: 'Sœur',
    HALF_BROTHER: 'Demi-frère',
    HALF_SISTER: 'Demi-sœur',
    STEPBROTHER: 'Demi-frère',
    STEPSISTER: 'Demi-sœur',
    FATHER: 'Père',
    MOTHER: 'Mère',
    GRANDPARENT: 'Grand-parent',
    UNCLE: 'Oncle',
    AUNT: 'Tante',
    NEPHEW: 'Neveu',
    NIECE: 'Nièce',
    SONINLAW: 'Gendre',
    DAUGHTERINLAW: 'Bru',
    FATHERINLAW: 'Beau-père',
    MOTHERINLAW: 'Belle-mère',
    BROTHERINLAW: 'Beau-frère',
    SISTERINLAW: 'Belle-sœur',
    JOINT: 'Propriété conjointe',
    CASH_SAVINGS: 'Liquidités et épargne',
    FURNITURE: 'Mobilier',
    PERSONAL_EFFECTS: 'Effets personnels',
    INVESTMENTS_POLICIES: 'Placements et polices',
    REAL_ESTATE: 'Immobilier',
    MOTOR_VEHICLES: 'Véhicules à moteur',
    RECREATIONAL_EQUIPMENT: 'Véhicule récréatif',
    TAX_REFUND: 'Remboursement d’impôt',
    CASH: 'Argent comptant',
    JEWELRY: 'Bijoux',
    LIFE_INSURANCE: 'Assurance vie',
    HOUSEHOLD_FURNITURE_EFFECTS: 'Meubles et articles ménagers',
    SNOWMOBILE: 'Motoneige',
    HOUSE: 'Maison',
    AUTOMOBILE: 'Automobile',
    TOOLS_OF_TRADE: 'Outils de travail',
    GIC: 'Certificat de placement garanti',
    RRSP: 'REER',
    CONDO: 'Copropriété',
    MOTORCYCLE: 'Motocyclette',
    HUNTING_TOOLS: 'Outils de chasse',
    SAVINGS: 'Épargne',
    RRIF: 'FERR',
    LAND: 'Terrain',
    BOAT: 'Bateau',
    FISHING_TOOLS: 'Outils de pêche',
    TFSA: 'CELI',
    LIRA: 'CRI',
    COTTAGE: 'Chalet',
    TRAILER: 'Remorque',
    FARMING_TOOLS: 'Outils agricoles',
    RESP: 'REEE',
    CAMPER: 'Autocaravane',
    SHARES: 'Actions',
    ATV: 'VTT',
    BONDS: 'Obligations',
    INVESTMENTS: 'Placements',
    COOP_SHARES: 'Parts dans une coopérative',
    CREDIT_UNION_SHARES: 'Parts dans une coopérative de crédit',
    RDSP: 'REEI',
    PENSION: 'Régimes de retraite',
    ACCOUNTING_INSURANCE_AND_RELATED_BUSINESS_ADMINISTRATIVE_OCCUPATIONS:
      'Personnel en comptabilité et assurance et personnel connexe en administration des affaires',
    ADMINISTRATIVE_AND_FINANCIAL_SUPERVISORS: 'Personnel en supervision administrative et financière',
    ADMINISTRATIVE_OCCUPATIONS: 'Personnel administratif',
    AIRCRAFT_ASSEMBLERS_AND_AIRCRAFT_ASSEMBLY_INSPECTORS:
      'Monteurs/monteuses d’aéronefs et contrôleurs/contrôleuses de montage d’aéronefs',
    ASSEMBLERS_AND_INSPECTORS_IN_MANUFACTURING:
      'Monteurs/monteuses et contrôleurs/contrôleuses dans le secteur de la fabrication',
    ASSISTING_OCCUPATIONS_IN_EDUCATION: 'Personnel de soutien en éducation',
    ASSISTING_OCCUPATIONS_IN_LEGAL_AND_PUBLIC_PROTECTION: 'Personnel de soutien en protection juridique et publique',
    ASSISTING_OCCUPATIONS_IN_SUPPORT_OF_HEALTH_SERVICES: 'Personnel de soutien dans les services de santé',
    BUILDING_MAINTENANCE_INSTALLERS_SERVICERS_AND_REPAIRERS:
      'Préposés à l’entretien général, aux installations et aux réparations des immeubles',
    CENTRAL_CONTROL_AND_PROCESS_OPERATORS_IN_PROCESSING_AND_MANUFACTURING:
      'Opérateurs/opératrices de poste central de contrôle dans les procédés de transformation et de fabrication',
    FINANCIAL_INSURANCE_AND_RELATED_ADMINISTRATIVE_SUPPORT_WORKERS:
      'Personnel en finances et assurance et personnel de soutien administratif connexe',
    GENERAL_CONSTRUCTION_TRADES: 'Personnel des métiers généraux de la construction',
    HARVESTING_LANDSCAPING_AND_NATURAL_RESOURCES_LABOURERS:
      'Manœuvres à la récolte, en aménagement paysager et en ressources naturelles',
    HEALTH_TREATING_AND_CONSULTATION_SERVICES_PROFESSIONALS:
      'Professionnels des soins de santé et des services de consultation',
    HELPERS_AND_LABOURERS: 'Aides et ouvriers/ouvrières',
    HOME_CARE_PROVIDER_OCCUPATIONS: 'Prestataires de soins à domicile',
    LABOURERS_IN_PROCESSING_MANUFACTURING_AND_UTILITIES:
      'Manœuvres dans la transformation, la fabrication et les services publics',
    LEGISLATIVE_AND_SENIOR_MANAGERS: 'Membres des corps législatifs et cadres supérieurs',
    LIBRARY_CORRESPONDENCE_AND_OTHER_CLERKS: 'Commis de bibliothèque, de correspondance et autres commis',
    MAIL_AND_MESSAGE_DISTRIBUTION_OCCUPATIONS: 'Personnel en distribution du courrier et des messages',
    MANAGERS_IN_PUBLIC_ADMINISTRATION_IN_EDUCATION_AND_SOCIAL_AND_COMMUNITY_SERVICES_AND_IN_PUBLIC_PROTECTION_SERVICES:
      'Directeurs/directrices de la fonction publique, de l’enseignement et des services sociaux et communautaires et des services de protection du public',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_MANUFACTURING_AND_UTILITIES:
      'Cadres intermédiaires du secteur de la fabrication et des services d’utilité publique',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_PRODUCTION_AND_AGRICULTURE:
      'Cadres intermédiaires du secteur de la production et de l’agriculture',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_RETAIL_AND_WHOLESALE_TRADE_AND_CUSTOMER_SERVICES:
      'Cadres intermédiaires du secteur du commerce de détail, du commerce de gros et des services à la clientèle',
    MIDDLE_MANAGEMENT_OCCUPATIONS_IN_TRADES_AND_TRANSPORTATION:
      'Cadres intermédiaires du secteur du commerce et des transports',
    NURSING_AND_ALLIED_HEALTH_PROFESSIONALS: 'Professionnels des soins infirmiers et paramédicaux',
    OCCUPATIONS_IN_ART_AND_CULTURE: 'Personnel du secteur des arts et de la culture',
    OCCUPATIONS_IN_CUSTOMER_INFORMATION_AND_PROTECTIVE_SERVICES:
      'Personnel des services à la clientèle, d’information et de protection',
    OCCUPATIONS_IN_FOOD_ACCOMMODATION_AND_TOURISM:
      'Personnel des secteurs des aliments, et de l’hébergement et du tourisme',
    OCCUPATIONS_IN_FRONTLINE_PUBLIC_PROTECTION_SERVICES:
      'Personnel des services de protection du public de première ligne',
    OCCUPATIONS_IN_NATURAL_RESOURCES_AND_FISHERIES: 'Personnel du secteur des ressources naturelles et de la pêche',
    OCCUPATIONS_IN_PERSONAL_SERVICE: 'Personnel du secteur des services personnels',
    OCCUPATIONS_IN_SALES: 'Personnel du secteur des ventes',
    OCCUPATIONS_IN_SERVICES: 'Personnel du secteur des services',
    OCCUPATIONS_IN_SPORTS: 'Personnel du secteur des sports',
    OFFICE_COURT_AND_DATA_SUPPORT_OCCUPATIONS:
      'Personnel de soutien de bureau, aux affaires juridiques et à la saisie de données',
    OPERATORS_DRILLERS_AND_BLASTERS: 'Opérateurs/opératrices, foreurs/foreuses et dynamiteurs/dynamiteuses',
    OTHERS_TECHNICAL_TRADES: 'Autres métiers techniques',
    PARAPROFESSIONAL_OCCUPATIONS_IN_LEGAL_SOCIAL_COMMUNITY_AND_EDUCATION_SERVICES:
      'Personnel paraprofessionnel des services juridiques, sociaux, communautaires et de l’enseignement',
    PRACTITIONERS_OF_NATURAL_HEALING: 'Praticiens/praticiennes des médecines douces',
    PRIMARY_COMBAT_MEMBERS_OF_THE_CANADIAN_ARMED_FORCES: 'Combattants de première ligne des Forces armées canadiennes',
    PROCESSING_AND_MANUFACTURING_MACHINE_OPERATORS_AND_RELATED_PRODUCTION_WORKERS:
      'Opérateurs/opératrices de machinerie et personnel connexe du secteur de la transformation et de la fabrication',
    PROFESSIONAL_OCCUPATIONS_IN_APPLIED_SCIENCES_EXCEPT_ENGINEERING:
      'Personnel professionnel en sciences appliquées (sauf ingénierie)',
    PROFESSIONAL_OCCUPATIONS_IN_ART_AND_CULTURE: 'Personnel professionnel du secteur des arts et de la culture',
    PROFESSIONAL_OCCUPATIONS_IN_BUSINESS: 'Personnel professionnel en gestion des affaires',
    PROFESSIONAL_OCCUPATIONS_IN_EDUCATION_SERVICES: 'Personnel professionnel des services d’enseignement',
    PROFESSIONAL_OCCUPATIONS_IN_ENGINEERING: 'Personnel professionnel en ingénierie',
    PROFESSIONAL_OCCUPATIONS_IN_FINANCE: 'Personnel professionnel en finance',
    PROFESSIONAL_OCCUPATIONS_IN_GOVERNMENT_SERVICES: 'Personnel professionnel des services gouvernementaux',
    PROFESSIONAL_OCCUPATIONS_IN_LAW: 'Personnel professionnel du droit',
    PROFESSIONAL_OCCUPATIONS_IN_NATURAL_SCIENCES: 'Personnel professionnel en sciences naturelles',
    PROFESSIONAL_OCCUPATIONS_IN_SOCIAL_AND_COMMUNITY_SERVICES:
      'Personnel professionnel du secteur des services sociaux et communautaires',
    RETAIL_SALES_AND_SERVICE_SUPERVISORS: 'Personnel en supervision des ventes du secteur du commerce de détail',
    RETAIL_SALESPERSONS_AND_NONTECHNICAL_WHOLESALE_TRADE_SALES_AND_ACCOUNT_REPRESENTATIVES:
      'Personnel de vente du secteur du commerce de détail et personnel de vente et de représentation des comptes du secteur du commerce de gros (non-technique)',
    SALES_SUPPORT_OCCUPATIONS: 'Personnel de soutien aux ventes',
    SPECIALIZED_ADMINISTRATIVE_OCCUPATIONS: 'Personnel administratif spécialisé',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_ADMINISTRATIVE_SERVICES_FINANCIAL_AND_BUSINESS_SERVICES_AND_COMMUNICATION_EXCEPT_BROADCASTING:
      'Cadres intermédiaires spécialisés dans les services administratifs, les services financiers et commerciaux et la communication (sauf la radiodiffusion)',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_ART_CULTURE_RECREATION_AND_SPORT:
      'Cadres intermédiaires spécialisés des secteurs des arts, de la culture, des sports et des loisirs',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_ENGINEERING_ARCHITECTURE_SCIENCE_AND_INFORMATION_SYSTEMS:
      'Cadres intermédiaires spécialisés en génie, en architecture, en sciences et en systèmes informatiques',
    SPECIALIZED_MIDDLE_MANAGEMENT_OCCUPATIONS_IN_HEALTH_CARE:
      'Cadres intermédiaires spécialisés du secteur des soins de santé',
    SPECIALIZED_SALES_OCCUPATIONS: 'Personnel de vente spécialisé',
    SPECIALIZED_SERVICES_OCCUPATIONS: 'Personnel de services spécialisé',
    STUDENT_MONITORS_CROSSING_GUARDS_AND_RELATED_OCCUPATIONS:
      'Surveillants/surveillantes d’élèves, brigadiers/brigadières et autres professions connexes',
    SUPERVISORS_IN_NATURAL_RESOURCES_AGRICULTURE_AND_RELATED_PRODUCTION:
      'Superviseurs/superviseures en ressources naturelles, en agriculture et en production connexe',
    SUPERVISORS_PROCESSING_MANUFACTURING_ASSEMBLY_AND_FABRICATION_OCCUPATIONS:
      'Surveillants/surveillantes dans la transformation, la fabrication et le montage',
    SUPPLY_CHAIN_LOGISTICS_TRACKING_AND_SCHEDULING_COORDINATION_OCCUPATIONS:
      'Personnel en coordination des opérations logistiques, du suivi et de la planification de la chaîne d’approvisionnement',
    SUPPORT_OCCUPATIONS_IN_ART_AND_CULTURE: 'Personnel de soutien du secteur des arts et de la culture',
    SUPPORT_OCCUPATIONS_IN_CLEANING_AND_RELATED_SERVICES:
      'Personnel de soutien du secteur du nettoyage et des services connexes',
    SUPPORT_OCCUPATIONS_IN_FOOD_ACCOMMODATION_AND_TOURISM:
      'Personnel de soutien des secteurs des aliments et de l’hébergement et du tourisme',
    SUPPORT_OCCUPATIONS_IN_SPORT: 'Personnel de soutien du secteur des sports',
    TECHNICAL_CONSTRUCTION_TRADES: 'Métiers techniques en construction',
    TECHNICAL_ELECTRICAL_TRADES: 'Métiers techniques en électricité',
    TECHNICAL_EQUIPMENT_OPERATION_TRADES: 'Métiers techniques d’opération d’équipement',
    TECHNICAL_INDUSTRIAL_TRADES: 'Métiers techniques industriels',
    TECHNICAL_MAINTENANCE_TRADES: 'Métiers techniques en maintenance',
    TECHNICAL_OCCUPATIONS_IN_ART_AND_CULTURE: 'Personnel technique du secteur des arts et de la culture',
    TECHNICAL_OCCUPATIONS_IN_HEALTH_EXCEPT_PRACTITIONERS_OF_NATURAL_HEALING:
      'Personnel technique du secteur des soins de santé (sauf praticiens/praticiennes en médecines douces)',
    TECHNICAL_OCCUPATIONS_RELATED_TO_APPLIED_SCIENCES_EXCEPT_ENGINEERING:
      'Personnel technique lié aux sciences appliquées (sauf ingénierie)',
    TECHNICAL_OCCUPATIONS_RELATED_TO_ENGINEERING: 'Personnel technique lié à l’ingénierie',
    TECHNICAL_OCCUPATIONS_RELATED_TO_NATURAL_SCIENCES: 'Personnel technique lié aux sciences naturelles',
    TECHNICAL_TRADES_CONTRACTORS_AND_SUPERVISORS:
      'Entrepreneurs/entrepreneuses et contremaîtres/contremaîtresses des métiers techniques',
    THERAPY_AND_ASSESSMENT_PROFESSIONALS: 'Professionnels/professionnelles en thérapie et en évaluation',
    TRANSPORT_DRIVERS_OPERATORS_AND_LABOURERS:
      'Conducteurs/conductrices, opérateurs/opératrices et ouvriers/ouvrières de transport',
    TRANSPORT_EQUIPMENT_OPERATORS_UTILITY_MAINTENANCE_AND_RELATED_WORKERS:
      'Opérateurs/opératrices de matériel de transport, personnel d’entretien des services publics et personnel connexe',
    TRANSPORTATION_AND_PRODUCTION_LOGISTICS_COORDINATORS_AND_CUSTOMS_AND_RELATED_BROKER_OCCUPATIONS:
      'Coordonnateur/coordonnatrice des opérations logistiques du secteur de la production et du transport et personnel en en douanes et professions connexes liées au courtage',
    TRANSPORTATION_OCCUPATIONS: 'Personnel du secteur du transport',
    TRANSPORTATION_OFFICERS_AND_CONTROLLERS:
      'Officiers/officières et contrôleurs/contrôleuses des services de transport',
    UTILITIES_EQUIPMENT_OPERATORS_AND_CONTROLLERS:
      'Opérateurs/opératrices et contrôleurs/contrôleuses d’équipements et de services publics',
    WORKERS_IN_NATURAL_RESOURCES_AND_FISHERIES:
      'Travailleurs/travailleuses du secteur des ressources naturelles et de la pêche',
    AUTO_LOAN: 'Prêt auto',
    LINE_OF_CREDIT: 'Marge de crédit',
    MORTGAGE: 'Prêt hypothécaire',
    OVERDRAFT: 'Découvert',
    PERSONAL_LOAN: 'Prêt personnel',
    STUDENT_LOAN: 'Prêt étudiant',
    CREDIT_REPORT_COLLECTION: 'Dossier de crédit - Comptes de recouvrement',
    CREDIT_REPORT_TRADE: 'Dossier de crédit - Lignes d’opérations commerciales',
    CREDIT_REPORT_REGISTERED_ITEM: 'Dossier de crédit - Éléments enregistrés',
    ANNULLED_BY_COURT: 'Annulation par le tribunal',
    COURT_APPROVAL_REFUSED: 'Approbation du tribunal refusée',
    CREDITOR_ACCEPTANCE_REJECTED: 'Acceptation du créancier rejetée',
    DEEMED_ANNULLED: 'Réputée nulle',
    FULL_PERFORMANCE: 'Exécution intégrale',
    ONGOING: 'En cours',
    UNSURE: 'Incertaine',
    WITHDRAWN: 'Retirée',
    EMPLOYMENT_INCOME: 'Revenu d’emploi',
    PENSION_ANNUITIES: 'Pensions et rentes',
    CHILD_SUPPORT: 'Pension alimentaire pour enfant',
    SPOUSAL_SUPPORT: 'Pension alimentaire pour ex-conjoint',
    EMPLOYMENT_INSURANCE: 'Assurance-emploi',
    SOCIAL_ASSISTANCE: 'Aide sociale',
    SELFEMPLOYMENT_INCOME: 'Revenu de travail indépendant',
    CANADA_CHILD_BENEFIT: 'Allocation canadienne pour enfants',
    RENTAL_INCOME: 'Revenu locatif',
    NONDISCRETIONARY: 'Dépenses non discrétionnaires',
    HOUSING: 'Dépenses relatives au logement',
    LIVING: 'Dépenses relatives aux frais de subsistance',
    TRANSPORTATION: 'Dépenses de transport',
    PERSONAL: 'Dépenses personnelles',
    INSURANCE: 'Dépenses d’assurance',
    MEDICAL: 'Dépenses médicales',
    CHILD_SUPPORT_PAYMENTS: 'Pension alimentaire pour enfant',
    RENT_BOARD_MORTGAGE_PAYMENT: 'Loyer, frais de pension ou hypothèque',
    RENT_MORTGAGE_HYPOTHEC: 'Loyer / Hypothèque',
    TELEPHONE_CELL_PHONE: 'Téléphone / Cellulaire',
    CABLE_STREAMING_SERVICES: 'Câble / Services de diffusion en ligne',
    CAR_LEASE_FINANCE_PAYMENTS: 'Location ou financement d’automobile',
    FOOD_GROCERY: 'Nourriture et épicerie',
    SMOKING: 'Tabac',
    TOBACCO_VAPING_CANNABIS: 'Tabac / Vapotage / Cannabis',
    MEALS_RESTAURANTS: 'Repas / Restaurants',
    VEHICLE: 'Véhicule',
    VEHICLE_INSURANCE_REGISTRATION: 'Assurances automobile / Immatriculation',
    PROPOSAL_PAYMENTS: 'Paiements (proposition)',
    PRESCRIPTIONS: 'Ordonnances',
    SPOUSAL_SUPPORT_PAYMENTS: 'Pension alimentaire pour ex-conjoint',
    PROPERTY_TAXES_CONDO_FEES: 'Taxes foncières et frais de copropriété',
    LAUNDRY_DRY_CLEANING: 'Buanderie et nettoyage à sec',
    ALCOHOL: 'Alcool',
    REPAIR_MAINTENANCE_GAS: 'Entretien, réparations et essence',
    BANKRUPTCY_PAYMENTS: 'Paiements (faillite)',
    DENTAL: 'Soins dentaires',
    CHILD_CARE: 'Frais de garde d’enfants',
    HEAT_GAS_OIL: 'Chauffage, gaz ou huile',
    DINING_LUNCHES_RESTAURANTS: 'Repas et restaurants',
    GROOMING_TOILETRIES: 'Soins personnels',
    PUBLIC_TRANSPORTATION: 'Transport en commun',
    TO_SECURED_CREDITORS: 'À un ou des créancier(s) hypothécaire(s)',
    FURNITURE_CONTENTS: 'Ameublement',
    MEDICAL_DENTAL_PRESCRIPTION: 'Soins médicaux, dentaire et médicaments',
    TELEPHONE: 'Téléphone',
    ENTERTAINMENT_SPORTS: 'Divertissements et sports',
    CLOTHING: 'Vêtements',
    FINES_PENALTIES_IMPOSED_BY_COURT: 'Amendes et pénalités imposées par le tribunal',
    CABLE_INTERNET: 'Câble et Internet',
    GIFTS_CHARITABLE_DONATIONS: 'Cadeaux et dons de charité',
    AS_A_CONDITION_OF_EMPLOYMENT: 'À titre de condition d’emploi',
    ELECTRICITY: 'Électricité',
    ALLOWANCES: 'Allocations',
    DEBTS_WHERE_STAY_HAS_BEEN_LIFTED: 'Dettes sujettes à une levée des procédures',
    WATER: 'Eau',
    COOPERATIVE: 'Coopérative',
    CORPORATION: 'Société de capitaux',
    PARTNERSHIP: 'Société en nom collectif',
    SOLE_PROPRIETORSHIP: 'Entreprise à propriétaire unique',
    LIMITED_LIABILITY_COMPANY: 'Société à responsabilité limitée',
    ACCOMMODATION_AND_FOOD_SERVICES: 'Services d’hébergement et de restauration',
    ADMINISTRATIVE_AND_SUPPORT_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES:
      'Services administratifs, services de soutien, services de gestion des déchets et services d’assainissement',
    AGRICULTURE_FORESTRY_FISHING_AND_HUNTING: 'Agriculture, foresterie, pêche et chasse',
    ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, spectacles et loisirs',
    CONSTRUCTION: 'Construction',
    EDUCATIONAL_SERVICES: 'Services d’enseignement',
    FINANCE_AND_INSURANCE: 'Finance et assurances',
    HEALTH_CARE_AND_SOCIAL_ASSISTANCE: 'Soins de santé et assistance sociale',
    INFORMATION_AND_CULTURAL_INDUSTRIES: "Industrie de l'information et industrie culturelle",
    MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES: 'Gestion de sociétés et d’entreprises',
    MANUFACTURING: 'Fabrication',
    MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION:
      'Extraction minière, exploitation en carrière, et extraction de pétrole et de gaz',
    OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION: 'Autres services (sauf les administrations publiques)',
    PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES: 'Services professionnels, scientifiques et techniques',
    PUBLIC_ADMINISTRATION: 'Administrations publiques',
    REAL_ESTATE_AND_RENTAL_AND_LEASING: 'Services immobiliers et services de location et de location à bail',
    RETAIL_TRADE: 'Commerce de détail',
    TRANSPORTATION_AND_WAREHOUSING: 'Transport et entreposage',
    UTILITIES: 'Services publics',
    WHOLESALE_TRADE: 'Commerce de gros',
    CHANGE_PREAUTHORIZED_DEPOSIT: 'Modifier le dépôt préautorisé',
    CHANGE_REQUESTED: 'Modification demandée',
    WE_HAVE_MADE_A_REQUEST_TO_UPDATE_YOUR_PREAUTHORIZED_DEBIT_INFORMATION:
      'Nous avons soumis une demande de modification de vos renseignements sur les débits préautorisés. Un spécialiste en allégement de dettes examinera cette demande.',
    N_FILES: '{0} dossiers',
    IN_PROGRESS: 'En cours',
    CLOSED: 'Fermé',
    ANNULLED: 'Annulé',
    TRANSFERRED: 'Transféré',
    STATUS_CHANGED: 'Modification de l’état',
    WERE_IN_THE_PROCESS_OF_SETTING_UP_YOUR_ACCOUNT:
      'Nous procédons actuellement à la configuration de votre compte et à la négociation des paiements avec les créanciers. Restez à l’affût des mises à jour concernant la progression de votre dossier.',
    THANK_YOU_FOR_CHOOSING_BDO: 'Nous vous remercions d’avoir choisi BDO!',
    CONTACT_US: 'Nous joindre',
    N_PAYMENTS_OUTSTANDING: '{0} paiements en suspens',
    RECEIPTS: 'Reçus',
    YOU_HAVE_REQUESTED_A_FOLLOWUP_MEETING_YOUR_DEBT_RELIEF_PROFESSIONAL_WILL_CONTACT_YOU:
      'Vous avez demandé une consultation de suivi. Votre spécialiste en allégement de dettes communiquera avec vous.',
    REQUEST_SENT_SUCCESSFULLY: 'Demande envoyée avec succès',
    BOOK_YOUR_INITIAL_CONSULTATION: 'Demandez une consultation initiale',
    BOOK_A_FREE_NOOBLIGATION_CONSULTATION_WITH_A_BDO_LICENSED_INSOLVENCY_TRUSTEE:
      'Vous pourrez réduire votre stress financier en sachant quelles sont les options qui s’offrent à vous pour alléger votre endettement. Demandez une consultation gratuite et sans engagement avec un syndic autorisé en insolvabilité de BDO. Nous disposons de bureaux et de spécialistes en allégement de dettes au sein de votre collectivité qui peuvent vous aider à cet égard.',
    PERIOD: 'Période',
    DUE_DATE: 'Date d’échéance',
    MISSING_INFORMATION: 'Informations manquantes',
    DISCREPANCY_NOTICE: 'Avis d’écart',
    AMENDMENT_REQUIRED: 'Modification nécessaire',
    ESTIMATED_SURPLUS: 'Excédent estimé',
    ESTIMATED_DEFICIT: 'Déficit estimé',
    INCOME_AND_EXPENSE_REPORTS: 'Rapports sur les revenus et les dépenses',
    RECEIVED: 'Reçu',
    STATUS: 'État',
    INCOME_AND_EXPENSE: 'Revenus et dépenses',
    BACK: 'Retour',
    VIEW: 'Afficher',
    N_MONTHLY_REPORTS_PENDING: '{0} rapports mensuels en suspens',
    SUBMIT_REPORT: 'Soumettre le rapport',
    COPY_PREVIOUS_MONTH: 'Copier le mois précédent',
    PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT: 'Membres de l’unité familiale (y compris le demandeur)',
    INITIAL_CONSULTATION: 'Consultation Initiale',
    FOLLOWUP_CONSULTATION: 'Consultation de Suivi',
    D_1ST_COUNSELLING: '1re Consultation Budgétaire',
    D_2ND_COUNSELLING: '2e Consultation Budgétaire',
    D_3RD_COUNSELLING: '3e Consultation Budgétaire',
    MEETING_WITH_CREDITORS: 'Assemblée des Créanciers',
    SECURED: 'Consigné',
    UNSECURED: 'Non consigné',
    DEEMED_TRUST: 'Fiducie réputée',
    DEFERRED: 'Créance différée',
    CONTINGENT: 'Créance conditionnelle ou non liquidée',
    PREFERRED: 'Créance privilégiée',
    PROPERTY: 'Propriété',
    BANK_LOANS_EXCEPT_REAL_PROPERTY_MORTGAGE: 'Prêts bancaires (sauf les hypothèques immobilières)',
    CREDIT_CARDS_BANK_TRUST_COMPANIES_ISSUERS: 'Banques et sociétés de fiducie émettrices de cartes de crédit',
    CREDIT_CARD_OTHER_ISSUERS: 'Autres émetteurs de cartes de crédit',
    FINANCE_COMPANY_LOANS: 'Prêts de sociétés de financement',
    LOANS_FROM_INDIVIDUALS: 'Prêts de particuliers',
    REAL_PROPERTY_MORTGAGE: 'Hypothèque immobilière',
    STUDENT_LOANS: 'Prêts étudiants',
    TAXES_FEDERAL_PROVINCIAL_MUNICIPAL: 'Impôts fédéraux et provinciaux et taxes municipales',
    HOUSEHOLD: 'Unité familiale',
    INDIVIDUAL: 'Particulier',
    ALWAYS: 'Toujours',
    LIMITED: 'Limité',
    GST: 'TPS',
    HST: 'TVH',
    GSTQST: 'TPS/TVQ',
    ORDINARY: 'Ordinaire',
    DIVISION_II: 'Section II',
    DIVISION_I: 'Section I',
    SUMMARY: 'Sommaire',
    IN_PERSON: 'En personne',
    PHONEVIDEO: 'Téléphone/Vidéo',
    ScheduleUpdatedNotification_ClientPortal_Body:
      'Des changements ont été apportés aux revenus et aux dépenses que vous devez soumettre. Cliquez ici pour les consulter.',
    IncomeExpenseXXDaysReminder_ClientPortal_Body:
      'Votre rapport sur les revenus et les dépenses doit être soumis dans {NumberOfDays} jours, soit le {DueDate}. Cliquez ici pour le consulter.',
    IncomeExpenseDueDateReminder_ClientPortal_Body:
      'La date limite pour soumettre votre rapport sur les revenus et les dépenses est aujourd’hui, le {DueDate}. Cliquez ici pour le consulter.',
    MissedIESubmissionAlert_ClientPortal_Body:
      'Vous n’avez pas respecté la date limite de soumission de votre rapport sur les revenus et les dépenses, qui était le {DueDate}. Cliquez ici pour le consulter.',
    TaxReturnReminderNotification_ClientPortal_Body:
      'La date limite de soumission des déclarations de revenus de {Year} est le 30 avril. Cliquez ici pour les consulter.',
    TaxReturnMissedNotification_ClientPortal_Body:
      'Vous n’avez pas respecté la date limite du 30 avril pour la soumission de votre déclaration de revenus. Cliquez ici pour la consulter.',
    AdminInfo_Banking_PADInfoChangeRequest_ApprovedClientPortal:
      'La demande de modification des informations sur les paiements par prélèvements automatiques a été approuvée.',
    AdminInfo_Banking_PADInfoChangeRequest_RejectedClientPortal:
      'Les modifications aux informations sur les paiements par prélèvements automatiques demandées ont été refusées pour la raison suivante : {RejectionReason}.',
    AdminInfo_Banking_PadInformation_UpdatedClientPortal:
      'Les modifications aux informations sur les paiements par prélèvements ont été effectuées.',
    AdminInfo_Banking_PADScheduleHoldRequest_OnHoldClientPortal:
      'Votre demande d’arrêt d’un paiement par chèque ou prélèvement automatique a été approuvée.',
    AdminInfo_Banking_PADScheduleHoldRequest_RejectedClientPortal:
      'Votre demande d’arrêt d’un paiement par chèque ou prélèvement automatique a été refusée pour la raison suivante : {RejectionReason}.',
    SignupNotification_ClientPortal:
      'Votre demande de {FilingType} a été transmise aux fins de traitement. Veuillez communiquer avec votre administrateur local si vous avez des questions ou des préoccupations.',
    AdminInfo_Proposal_ProposalStateAccepted_ClientPortal:
      'Félicitations! Votre proposition a été acceptée par les créanciers. L’administrateur de votre dossier vous contactera pour vous fournir davantage d’information.',
    AdminInfo_Proposal_ProposalStateRejected_ClientPortal:
      'Votre proposition a été refusée par les créanciers. L’administrateur de votre dossier vous contactera pour vous fournir davantage d’information.',
    AdminInfo_Proposal_ProposalStateWithdrawn_ClientPortal:
      'Votre proposition a été retirée. L’administrateur de votre dossier vous contactera pour vous fournir davantage d’information.',
    AdminInfo_Proposal_ProposalStatePaymentArrear_ClientPortal:
      'Vous avez omis un ou plusieurs paiements. Veuillez vous assurer que ceux-ci sont effectués au plus tôt afin d’éviter un avertissement ou l’annulation de votre proposition.',
    AdminInfo_Proposal_ProposalStatePaymentWarning_ClientPortal:
      'Plus de deux paiements sont en retard. Ceci est un avertissement officiel. Veuillez communiquer avec votre administrateur local pour obtenir de plus amples renseignements.',
    AdminInfo_Banking_PADSummaryUpdated_ClientPortal:
      'À titre de référence, vous pouvez consulter le sommaire de l’état de vos paiements. Veuillez communiquer avec votre administrateur local si vous avez des questions ou des préoccupations.',
    IncomeExpenseClientPortalComment_ClientPortal:
      'Le commentaire suivant a été ajouté à votre rapport sur les revenus et les dépenses qui doit être soumis le {DueDate} : {Comment}',
    DocumentWasMadeVisible_ClientPortal:
      'Le document {DocumentName} est maintenant disponible à la page Documents. Cliquer ici pour accéder à la page Documents',
    AppointmentCancelationNotification_ClientPortal: 'Votre {AppointmentType} du {Date} a été annulé.',
    AppointmentDetailUpdated_ClientPortal: 'Votre {AppointmentType} a été modifié. Cliquer ici pour plus de détails.',
    NEW: 'Nouveau',
    NO_PREVIOUS_MONTH: 'Aucune donnée pour le mois précédent',
    DATE_OF_FILING: 'Date de dépôt',
    IF_YOU_NEED_ASSISTANCE: 'Besoin d’assistance',
    OPERATION_FAILED: 'Une erreur s’est produite. Veuillez communiquer avec votre administrateur.',

    THE_PASSWORD_IS_EASY_TO_GUESS: 'Ce mot de passe est simple à deviner. SVP en choisir un autre!',
    DATE_OF_BANKRUPTCY: 'Date de la faillite',
    DURATION_OF_BANKRUPTCY: 'Durée de la faillite',
    ENTER_YOUR_LOCATION: 'S’il vous plaît, veuillez indiquer votre emplacement actuel',
    BOOK_YOUR_APPOINTMENT_NOW:
      'Prenez rendez-vous dès maintenant, à l’aide de votre ordinateur ou appareil mobile. C’est rapide et facile.',
    SEARCH: 'Recherche',
    SOURCE: 'Source',
    BACK_TO_REPORTS: 'Retour aux Rapports',
    BACK_TO_REPORT_DETAILS: 'Retour aux détails du Rapport',
    YOUR_APPOINTMENT_HAS_BEEN_BOOKED: 'Vous rendez-vous est fixé!',
    UNKNOWN_AGENT: 'Agent inconnu',
    FILE_DETAILS: 'Détails du dossier',
    FILING_TYPE: 'Type de dossier',
    NO_NEW_NOTIFICATIONS: 'Aucune nouvelle notification.',
    NEXT_PAYMENT: 'Prochain Paiement',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED:
      'Un seul des deux documents listés pour établir votre Pension/Rente n’est requis.',
    REQUEST_A_CHANGE_TO_THIS_COUNSELLING_SESSION: 'Demander une modification à cette séance de consultation.',
    D_08_YEARS: '0-8 ans',
    EXPIRED_LINK: 'Ce lien est expiré',
    REASONS_FOR_FINANCIAL_DIFFICULTY: 'Raisons des difficultés financières',
    SUPPLEMENTAL_INFORMATION_ON_DEBTS: 'Informations supplémentaires sur les dettes',
    THIS_REGISTRATION_LINK_HAS_EXPIRED:
      'Veuillez utiliser le dernier lien dans votre boîte de courriel pour vous enregistrer. Si vous avez des questions, veuillez contacter votre conseiller en solution à l’endettement.',
    NEED_TECHNICAL_SUPPORT: 'Avez-vous besoin de soutien technique?',
    IF_YOU_NEED_TECHNICAL_SUPPORT_PLEASE_COMPLETE_THE_FORM_BELOW:
      'Veuillez remplir le formulaire ci-dessous et un membre de l’équipe des TI de BDO se fera un plaisir de vous aider.',
    CONTACT_SUPPORT_PAGE_PHONE_TITLE:
      'Si vous souhaitez parler à un membre de l’équipe TI, veuillez composer le numéro ci-dessous.',
    CONTACT_SUPPORT_PAGE_PHONE_HOURS_TITLE: 'Heures du soutien technique :',
    CONTACT_SUPPORT_PAGE_PHONE_HOURS: '8 h 30 - 20 h HNE',
    CONTACT_SUPPORT_PAGE_PHONE_DAYS: 'Lundi - dimanche',
    REFER_TO_THE_BDO_GLOBAL_PORTAL_CLIENT_QUICK_START_GUIDE:
      'Vous pouvez également consulter le Guide de démarrage du Portail mondial de BDO sous l’onglet Guides.',
    BDO_PROFESSIONAL_CONTACT: 'Personne-ressource de BDO',
    WHICH_BEST_DESCRIBES_YOUR_INQUIRY: 'Qu’est-ce qui décrit le mieux votre demande?',
    MESSAGE_SENT_SUCCESSFULLY: 'Message envoyé',
    YOUR_INQUIRY_HAS_BEEN_SUCCESSFULLY_SUBMITTED:
      'Votre demande a été soumise. Notre équipe de soutien la traitera sous peu. Merci pour votre message.',
    SUBMISSION_ERROR: 'Erreur de transmission',
    THERE_WAS_AN_ISSUE_PROCESSING_YOUR_REQUEST_PLEASE_TRY_AGAIN_LATER:
      'Une erreur s’est produite lors de la soumission de votre demande. Veuillez réessayer plus tard. Si le problème persiste, veuillez communiquer avec notre équipe de soutien au ',
    LOGIN_OR_ACCOUNT_ISSUES: 'Problème de connexion ou de compte',
    PERFORMANCE_AND_LOADING_PROBLEMS: 'Problème de performance et de chargement',
    BROWSER_COMPATIBILITY: 'Compatibilité avec le navigateur',
    ERROR_MESSAGES: 'Messages d’erreur',
    FUNCTIONALITY_AND_FEATURES: 'Fonctionnalités et caractéristiques',
    PASSWORD_RESET_ASSISTANCE: 'Assistance pour réinitialiser le mot de passe',
    REPORT_A_BUG_OR_GLITCH: 'Signaler un bogue',
    CONTACT_SUPPORT: 'Communiquer avec le soutien technique',
    BUDGET_SURPLUS: 'Surplus budgétaire',
    BUDGET_DEFICIT: 'Déficit budgétaire',
    COMPANY_NAME: 'Nom de l’entreprise',
    ONE_ID_REQUIRED_FOR_SPOUSE: 'Une pièce d’identité est requise pour la conjointe',
    PREAUTHORIZED_PAYMENTS: 'Paiements pré-autorisés',
    BLACK_SCREEN_WARNING:
      'Si vous obtenez un écran noir lorsque vous essayez de prendre une photo, assurez-vous que votre navigateur est autorisé à accéder à votre appareil photo. Pour activer l’autorisation de la caméra, accédez aux paramètres de votre navigateur.',
    SAVING: 'Sauvegarder',
    TECHNICAL_SUPPORT: 'Support technique',

    //new
    NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_PREVIOUS_PASSWORDS:
      'Le nouveau mot de passe doit être différent des mots de passe précédents !',
    SECTION_LOCKED_TITLE: 'Section bloquée',
    SECTION_LOCKED_BODY:
      'Cette section a été marquée comme « Complété » par votre spécialiste en allégement de dettes. Veuillez contacter votre spécialiste en allégement de la dette si des informations doivent être mises à jour.',
    NO_OPTIONS: 'Aucune option',
    Healthcare: 'Soins de la santé',
    Education: 'Éducation',
    InformationTechnology: "Technologies de l'information",
    Engineering: 'Ingénierie',
    Finance: 'Finances',
    SalesAndMarketing: 'Ventes et mise en marché',
    Legal: 'Droit',
    ArtsAndMedia: 'Arts et médias',
    Hospitality: 'Hôtelerie',
    ConstructionAndTrades: 'Métiers de la construction',
    Transportation: 'Transports',
    Manufacturing: 'Secteur manufacturier',
    Retail: 'Commerce de détail',
    Government: 'Gouvernement',
    ScienceAndResearch: 'Sciences et recherche',
    SocialServices: 'Services sociaux',
    Agriculture: 'Agriculture',
    RealEstate: 'Immobilier',
    HumanResources: 'Ressources humaines',
    CustomerService: 'Service à la clientèle',
    Security: 'Sécurité',
    SportsAndRecreation: 'Sports et loisirs',
    SelfEmployed: 'Travailleur autonome',
    Student: 'Étudiant',
    Other: 'Autres',
    WHICH_INDUSTRY_DO_YOU_WORK_IN: 'Dans quel secteur d’activité travaillez-vous',
    PLEASE_PROVIDE_YOUR_EXPLOYMENT_HISTORY_DURING_THE_LAST_2_YEARS:
      'Veuillez indiquer votre historique d’emploi au cours des deux (2) dernières années.',
    EMPLOYED_FULLTIME: 'Employé à temps plein',
    EMPLOYED_PART_TIME: 'Employé à temps partiel',
    EMPLOYED_SEASONAL: 'Employé saisonnier',
    SELFEMPLOYED: 'Travailleur autonome',
    RETIRED: 'Retraité',
    STUDENT: 'Étudiant',
    DECEASED: 'Décédé',
    NOT_EMPLOYED: 'Sans emploi',
    HEALTHCARE: 'Soins de la santé',
    EDUCATION: 'Éducation',
    INFORMATION_TECHNOLOGY: "Technologies de l'information",
    ENGINEERING: 'Ingénierie',
    FINANCE: 'Finances',
    SALES_AND_MARKETING: 'Ventes et mise en marché',
    LEGAL: 'Droit',
    ARTS_AND_MEDIA: 'Arts et médias',
    HOSPITALITY: 'Hôtelerie',
    CONSTRUCTION_AND_TRADES: 'Métiers de la construction',
    RETAIL: 'Commerce de détail',
    GOVERNMENT: 'Gouvernement',
    SCIENCE_AND_RESEARCH: 'Sciences et recherche',
    SOCIAL_SERVICES: 'Services sociaux',
    AGRICULTURE: 'Agriculture',
    HUMAN_RESOURCES: 'Ressources humaines',
    CUSTOMER_SERVICE: 'Service à la clientèle',
    SPORTS_AND_RECREATION: 'Sports et loisirs',
    TO_THE_ESTATE: 'Effectués à l’actif',
    TO_PROVIDER_OF_FINANCIAL_ADVICE_OTHER_THAN_THE_LICENSED_INSOLVENCY_TRUSTEE:
      'Effectués à un fournisseur de conseils financiers (autre que le syndic autorisé en insolvabilité)',
    EMPLOYMENT_INSURANCE_BENEFITS: 'Prestations d’assurance-emploi',
    CHILD_TAX_BENEFIT: 'Prestations fiscales pour enfants',
    GOVERNMENT_BENEFITS: 'Prestations gouvernementales',
    OTHER_BENEFITS: 'Autres prestations',
    HAS_FINANCIAL_ADVICE_REQUIRED_MESSAGE:
      'Au cours des six (6) derniers mois, avez-vous reçu des conseils professionnels sur votre situation financière par un fournisseur autre que le syndic autorisé en insolvabilité?',
  },
};
